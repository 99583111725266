import { Box, Button, Stack } from "@mui/material";
import { AttachmentParent } from "attachments/api";
import { AttachmentUploading } from "attachments/mui/AttachmentUploading";
import { PaperClipIcon } from "icons/PaperClipIcon";
import { AttachmentsGrid } from "attachments/mui/AttachmentsGrid";
import { AttachmentModel } from "@bluemarvel/iris-common/model";

interface ReleaseNotesEnhancementAttachmentsProps {
  parentType: AttachmentParent;
  parentId: number;
  readOnly?: boolean;
  attachments: AttachmentModel[];
}

export const ReleaseNotesEnhancementAttachments = (props: ReleaseNotesEnhancementAttachmentsProps) => {
  const { parentType, parentId, attachments, readOnly } = props;

  const onDeleteAttachment = async (id: number) => {};

  return (
    <Box sx={{ width: "100%" }}>
      <AttachmentUploading parentType={props.parentType} parentId={props.parentId} disabled={true}>
        {({ openUploadDialog }) => {
          return (
            <Stack spacing={3}>
              {!readOnly && (
                <Button onClick={openUploadDialog} variant="outlined">
                  <PaperClipIcon />
                  Upload Attachment
                </Button>
              )}

              <AttachmentsGrid
                isSelectMode={false}
                onMultiSelectToggle={() => {}}
                selectedAttachmentIds={new Set<number>()}
                parentType={parentType}
                parentId={parentId}
                attachments={attachments}
                onDeleteAttachment={onDeleteAttachment}
                canCopyAssemblyImage={false}
              />
            </Stack>
          );
        }}
      </AttachmentUploading>
    </Box>
  );
};
