import AutoSave from "app/mui/forms/AutoSave";
import { AttachmentModel, ReleaseNotesEnhancementModel } from "@bluemarvel/iris-common/model";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { RichTextEditor } from "app/mui/forms/RichTextEditor";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { AttachmentParent } from "attachments/api";
import { useForm } from "react-hook-form";
import { useUpdateReleaseNotesBugRequest, useUpdateReleaseNotesFeatureRequest } from "../../../api";
import { ReleaseNotesEnhancementItemActions } from "../../actions/ReleaseNotesEnhancementItemActions";
import { ReleaseNotesEnhancementAttachments } from "./ReleaseNotesEnhancementAttachments";

export interface ReleaseNotesEnhancementSectionItemProps {
  enhancementData: ReleaseNotesEnhancementModel;
  enhancementType: AttachmentParent;
  attachments: AttachmentModel[];
  count: number;
  userCanEdit: boolean;
}

export const ReleaseNotesEnhancementItem = (props: ReleaseNotesEnhancementSectionItemProps) => {
  const { enhancementData, enhancementType, userCanEdit } = props;
  const label = enhancementType === "ReleaseNoteFeature" ? "Feature" : "Bug";
  const defaultValues = makeSafeForReactHookForm(enhancementData);
  const methods = useForm({ defaultValues });
  const attachments =
    enhancementType === "ReleaseNoteFeature"
      ? props.attachments.filter((x) => x.releaseNotesFeatureId === enhancementData.id)
      : props.attachments.filter((x) => x.releaseNotesBugId === enhancementData.id);

  const updateReleaseNotesBug = useUpdateReleaseNotesBugRequest();
  const updateReleaseNotesFeature = useUpdateReleaseNotesFeatureRequest();

  const saveChanges = () => {
    return methods.handleSubmit((values) => {
      if (enhancementType === "ReleaseNoteFeature") {
        updateReleaseNotesFeature.call(values);
      }
      if (enhancementType === "ReleaseNoteBug") {
        updateReleaseNotesBug.call(values);
      }
    })();
  };

  return (
    <PermissionAwareFormProvider {...methods} require={() => userCanEdit}>
      <AutoSave defaultValues={defaultValues} onSubmit={saveChanges} />
      <Paper sx={{ borderRadius: 0, borderBottom: "1px #E5E5E5 solid" }}>
        <ExpansionPanel
          title={`${label} ${props.count}`}
          previewText={enhancementData.title}
          rightSideComponent={
            userCanEdit && (
              <ReleaseNotesEnhancementItemActions enhancementType={enhancementType} enhancement={enhancementData} />
            )
          }
        >
          <Box sx={{ pl: 3, pb: 3, pr: 3 }}>
            <Stack direction="column" spacing={2}>
              <TextFieldFormItem fieldName="title" label="Short Description" readOnly={!userCanEdit} />
              {!userCanEdit && (
                <Typography variant="caption" color="textSecondary">
                  {label} Details
                </Typography>
              )}
              <RichTextEditor fieldName="description" label={`${label} Details`} disabled={!userCanEdit} />

              <ReleaseNotesEnhancementAttachments
                parentType={enhancementType}
                parentId={enhancementData.id}
                attachments={attachments}
                readOnly={!userCanEdit}
              />
            </Stack>
          </Box>
        </ExpansionPanel>
      </Paper>
    </PermissionAwareFormProvider>
  );
};
