import { Box, Paper, Stack } from "@mui/material";
import { PageTitle } from "app/mui/common/headers/PageTitle";
import { LastModified } from "app/mui/LastModified";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import { TutorialsBadge } from "../components/TutorialsBadge";
import { TutorialsHeaderActions } from "../actions/TutorialsHeaderActions";
import { TutorialsDetailViewModel } from "@bluemarvel/iris-common/model";
import { useUserContext } from "app/contexts/UserContext";
import { PostedBy } from "app/mui/PostedBy";

interface TutorialsDetailHeaderProps {
  tutorialsDetail: TutorialsDetailViewModel;
}

export const TutorialsHeader = (props: TutorialsDetailHeaderProps) => {
  const { tutorialsDetail } = props;
  const { userPermissions } = useUserContext();
  return (
    <Paper>
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" pb={2}>
          <Stack direction="column" spacing={1}>
            <PageTitle title={tutorialsDetail.model.name} icon={<GppMaybeOutlinedIcon />} />
            <Stack direction="row" spacing={2} alignItems="center" pb={0}>
              <TutorialsBadge />
              {tutorialsDetail.model.tutorialType && <TutorialsBadge label={tutorialsDetail.model.tutorialType} />}
            </Stack>
            <Stack direction="row" flexWrap="wrap" pb={0}></Stack>
          </Stack>
          <Stack spacing={2} alignItems="flex-end">
            <Stack direction="column" alignItems="flex-end" spacing={2}>
              <Stack>
                {userPermissions.userCanEditReleaseNotes ? (
                  <TutorialsHeaderActions tutorialsDetail={tutorialsDetail} />
                ) : (
                  <Box sx={{ height: 40 }} />
                )}
              </Stack>
              <Stack>
                {tutorialsDetail.model.isPublished ? (
                  <PostedBy
                    lastModifiedBy={tutorialsDetail.lastModifiedBy}
                    lastModified={tutorialsDetail.lastModified}
                  />
                ) : (
                  <LastModified
                    lastModifiedBy={tutorialsDetail.lastModifiedBy}
                    lastModified={tutorialsDetail.lastModified}
                  />
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};
