import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useForm } from "react-hook-form";
import { Box, Divider, Grid, List, ListItem, Paper, Stack, Typography } from "@mui/material";
import { IrisTooltip, useUserContext } from "app";
import { TrafficLightFormItem } from "app/mui/forms/Select/TrafficLightFormItem";
import { IrisColors } from "app/mui/theme";
import React from "react";
import { Record as RecordModel } from "records/models";
import InfoIcon from "@mui/icons-material/Info";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import AutoSave from "app/mui/forms/AutoSave";
import { useUpdateReportCardEquipmentHealth } from "records/api";
import { EquipmentHealthReportCardModel } from "@bluemarvel/iris-common/model";

interface EquipmentHealthReportCardProps {
  record: RecordModel;
  reportCard: EquipmentHealthReportCardModel;
}

const reportCardFields: Array<{
  fieldName: string;
  label: string;
  toolTipContent: string;
}> = [
  {
    fieldName: "accuracyCalibration",
    label: "Accuracy and Calibration",
    toolTipContent:
      "Assess collective meter performance for regulatory compliance, to ensure transaction integrity in custody transfer.",
  },
  {
    fieldName: "measurementSystemIntegrity",
    label: "Measurement System Integrity",
    toolTipContent:
      "Evaluate the design and technology of the system and equipment, in support of regulatory compliance and operational efficiency.",
  },
  {
    fieldName: "reportingAuditing",
    label: "Reporting and Auditing",
    toolTipContent:
      "Evaluate meter capability for precise data reporting and compliance with regulatory audit standards.",
  },
  {
    fieldName: "dataIntegrityMaintenance",
    label: "Data Integrity and Maintenance",
    toolTipContent:
      "Assess the data integrity of collective meters, including temperature and pressure correction capabilities, and evaluate the effectiveness of maintenance in meeting regulatory standards.",
  },
];

export const EquipmentHealthReportCard = (props: EquipmentHealthReportCardProps) => {
  const { record, reportCard } = props;
  const defaultValues = makeSafeForReactHookForm(reportCard);
  const methods = useForm({ defaultValues });
  const updateReportCard = useUpdateReportCardEquipmentHealth();
  const { userPermissions } = useUserContext();

  if (!reportCard) {
    return <></>;
  }

  const userCanEdit = userPermissions.userCanEditRecord(
    record.assets.map((asset) => asset.assetType),
    record.recordType
  );

  const saveChanges = () => {
    methods.handleSubmit((values) => {
      updateReportCard.call(values);
    })();
  };

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permissions) =>
        permissions.userCanEditRecord(
          record.assets?.map((asset) => asset.assetType),
          record.recordType
        )
      }
    >
      <AutoSave onSubmit={saveChanges} />
      <PneumaticFullStrokeReportCardFields canEdit={userCanEdit} />
    </PermissionAwareFormProvider>
  );
};

const PneumaticFullStrokeReportCardFields = (props: { canEdit: boolean }) => {
  const asFoundWidth = "65px";
  const arrowWidth = "35px";
  const { canEdit: userCanEdit } = props;

  return (
    <Paper sx={{ p: 2, minHeight: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack justifyContent="space-between" direction="column" sx={{ width: "100%" }}>
        <Typography variant="h6">REPORT CARD</Typography>
        <Paper sx={{ mt: 3 }}>
          <List sx={{ width: "100%" }}>
            <ListItem alignItems="flex-start">
              <Grid container justifyContent="space-between" columnSpacing={2} alignItems="flex-end">
                <Grid item xs={6}></Grid>
                <Grid item xs={false} width={asFoundWidth}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;found</Typography>
                </Grid>
                <Grid item xs={false} width={arrowWidth} />
                <Grid item xs={true}>
                  <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;left</Typography>
                </Grid>
              </Grid>
            </ListItem>
            <Divider component="li" />
            {reportCardFields.map((field, index) => (
              <React.Fragment key={field.fieldName}>
                <ListItem>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="space-between" columnSpacing={2} alignItems="center">
                      <Grid item xs={6} sx={{ display: "flex" }}>
                        <Typography variant="caption" sx={{ fontWeight: 400, pr: 1 }}>
                          {field.label}
                        </Typography>
                        <IrisTooltip title={<>{field.toolTipContent}</>}>
                          <span>
                            <InfoIcon sx={{ color: IrisColors.gray500, fontSize: "1.1rem" }} />
                          </span>
                        </IrisTooltip>
                      </Grid>
                      <Grid item xs={false} width={asFoundWidth}>
                        <TrafficLightFormItem
                          fieldName={"asFound_" + field.fieldName}
                          readOnly={!userCanEdit}
                          syncFieldName={"asLeft_" + field.fieldName}
                        />
                      </Grid>
                      <Grid item xs={false} width={arrowWidth}>
                        <ArrowForwardIcon sx={{ fontSize: "20px", color: IrisColors.gray500 }} />
                      </Grid>
                      <Grid item xs={true}>
                        <TrafficLightFormItem fieldName={"asLeft_" + field.fieldName} readOnly={!userCanEdit} />
                      </Grid>
                    </Grid>
                  </Box>
                </ListItem>
                {index < reportCardFields.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Stack>
    </Paper>
  );
};
