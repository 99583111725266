import { Stack, Box, Typography, Paper } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { Summary, SummaryDetail, SummaryType, SummaryStatus } from "summaries/models";
import { SummaryIcon } from "icons/SummaryIcon";
import { useCallback, useEffect, useState } from "react";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import EditIcon from "@mui/icons-material/Edit";
import { formatRoutePath, Routes } from "app";
import { useDeleteSummaryRequest } from "summaries/api";
import { useNavigate } from "react-router-dom";
import { useNotifications, useSiteIdFromRoute } from "app";
import { RecordDetailView, usePostRecordsByIdQuery } from "records";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { SummaryTypeBadge } from "../SummaryTypeBadge";
import { ConfigureOutageScopeSummaryReportModal } from "../ConfigureOutageScopeSummaryReportModal";
import { ConfigureFieldInspectionSummaryReportModal } from "../ConfigureFieldInspectionSummaryReportModal";
import { ConfigureKpiSummaryReportModal } from "../ConfigureKpiSummaryReportModal";
import { ConfigureBulkValveHealthReportModal } from "../ConfigureBulkValveHealthReportModal";
import { ConfigureBulkVibrationHealthReportModal } from "../ConfigureBulkVibrationHealthReportModal";
import { ConfigureGeneralSummaryReportModal } from "../ConfigureGeneralSummaryReportModal";
import ConfirmationDialog from "common/mui/ConfirmationDialog";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { EditFieldDialog } from "app/mui/common/dialogs/EditFieldDialog";
import { ConfigurePackagedSolutionSummaryReportModal } from "../ConfigurePackagedSolutionSummaryReportModal";
import { ConfigureKpiSummaryNodeReportModal } from "../ConfigureKpiSummaryNodeReportModal";
import { FeatureFlagName, useFeatureEnabled } from "common";

interface SummaryDetailHeaderProps {
  summaryDetail: SummaryDetail;
  methods: UseFormReturn<Summary>;
  saveChanges: (onValid?: () => void) => void;
}

const pageActions = (summaryDetail: SummaryDetail) => {
  return createStaticActions((actionDispatcher, userPermissions) => {
    const userCanEditSummary = userPermissions.userCanEditSummary;
    const userCanGenerateSummaryReport = userPermissions.userCanGenerateSummaryReport;
    const isClosed = summaryDetail.model.status === SummaryStatus.Closed;

    return [
      {
        icon: <AssessmentRoundedIcon />,
        label: "Generate Report",
        action: () => actionDispatcher.dispatch("generateSummaryReport"),
        hidden: !userCanGenerateSummaryReport,
      },
      {
        icon: <EditIcon />,
        label: "Edit Title",
        action: () => actionDispatcher.dispatch("editTitle"),
        hidden: !userCanEditSummary,
      },
      {
        icon: <DeleteForeverRoundedIcon />,
        label: "Delete Summary",
        action: () => actionDispatcher.dispatch("deleteSummary"),
        hidden: !userCanEditSummary,
      },
      {
        icon: isClosed ? <LockOpenIcon /> : <LockIcon />,
        label: isClosed ? "Reopen Summary" : "Close Summary",
        action: () => actionDispatcher.dispatch(isClosed ? "reopenSummary" : "closeSummary"),
        hidden: !userCanEditSummary,
      },
    ];
  });
};

type DialogActionsType =
  | "generateSummaryReport"
  | "deleteSummary"
  | "editTitle"
  | "closeSummary"
  | "reopenSummary"
  | null;
const validDialogActions = ["generateSummaryReport", "deleteSummary", "editTitle", "closeSummary", "reopenSummary"];

export const SummaryHeader: React.FC<SummaryDetailHeaderProps> = ({ summaryDetail, methods, saveChanges }) => {
  const summary = summaryDetail.model;
  const packagedSolutionEquipments = summary.packagedSolution?.packagedSolutionEquipments;
  const [openDialog, setOpenDialog] = useState<DialogActionsType>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const isBulkVibrationHealthSummary = summary.summaryType === SummaryType.BulkVibrationHealth;
  const isBulkValveHealthSummary = summary.summaryType === SummaryType.BulkValveHealthType;
  const isGeneralSummary = summary.summaryType === SummaryType.General;
  const isKpiSummary = summary.summaryType === SummaryType.KPI;
  const isOutageScopeSummary = summary.summaryType === SummaryType.OutageScope;
  const isFieldInspectionSummary = summary.summaryType === SummaryType.FieldInspection;
  const isPackagedSolutionSummary = summary.summaryType === SummaryType.PackagedSolution;
  const [summaryRecordDetails, setSummaryRecordDetails] = useState<RecordDetailView[]>([]);
  const postRecordsByIdQuery = usePostRecordsByIdQuery();
  const notifications = useNotifications();
  const navigate = useNavigate();
  const siteId = useSiteIdFromRoute()!;
  const isNodeKPIReportEnabled = useFeatureEnabled(FeatureFlagName.UseNodeKPIReport);

  const deleteSummaryRequest = useDeleteSummaryRequest();

  const closeDialog = () => {
    setOpenDialog(null);
  };
  useEffect(() => {
    postRecordsByIdQuery.call(summary.records.map((r) => r.id)).then((records) => {
      setSummaryRecordDetails(records ?? []);
    });
  }, [summary.records]);
  const handleDeleteSummary = async () => {
    setIsDeleting(true);
    try {
      await deleteSummaryRequest.call(summaryDetail.id);
      notifications.success("Summary deleted");
      navigate(formatRoutePath(Routes.Summaries, { siteId }));
      closeDialog();
    } catch {
      notifications.error("Error while deleting summary");
    } finally {
      setIsDeleting(false);
    }
  };

  const handleChangeStatus = async (newStatus: SummaryStatus) => {
    await methods.setValue("status", newStatus);
  };

  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(summaryDetail), actionDispatcher, [summaryDetail]);

  const handleAction = useCallback((action: string) => {
    if (validDialogActions.indexOf(action) > -1) {
      if (action === "closeSummary") {
        handleChangeStatus(SummaryStatus.Closed);
      } else if (action === "reopenSummary") {
        handleChangeStatus(SummaryStatus.Open);
      } else {
        setOpenDialog(action as DialogActionsType);
      }
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  return (
    <Paper>
      <Box sx={{ p: 2 }}>
        <Stack direction="column" spacing={1}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">
              <Stack direction="row" flexWrap="wrap" spacing={1} alignItems="center">
                <SummaryIcon sx={{ fontSize: 34 }} /> <div>{summary.title}</div>
              </Stack>
            </Typography>

            <Box sx={{ marginLeft: 2 }}>
              <ActionsDropdownMenu primary actions={actions} />
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <SummaryTypeBadge summaryType={summary.summaryType} styleType="filled" />
          </Stack>
          {packagedSolutionEquipments && (
            <Stack direction="row" flexWrap="wrap" pb={0}>
              <Box sx={{ pr: 3, pt: 1 }}>Equipment Count: {packagedSolutionEquipments.length}</Box>
              <Box sx={{ pr: 3, pt: 1 }}>Asset Count: {packagedSolutionEquipments.flatMap((e) => e.assets).length}</Box>
            </Stack>
          )}
          {openDialog === "editTitle" && (
            <EditFieldDialog
              methods={methods}
              saveChanges={saveChanges}
              fieldName="title"
              dialogTitle="Change summary name"
              label="Summary Name"
              confirmButtonText="Change name"
              onClose={() => setOpenDialog(null)}
            />
          )}

          {openDialog === "deleteSummary" && (
            <ConfirmationDialog
              title="Delete summary?"
              open={openDialog === "deleteSummary"}
              cancel={() => closeDialog()}
              loading={isDeleting}
              confirmButtonText="Delete"
              confirm={handleDeleteSummary}
            >
              <Typography variant="body2">Are you sure you want to delete this summary?</Typography>
            </ConfirmationDialog>
          )}
          {openDialog === "generateSummaryReport" && (
            <>
              {isGeneralSummary && <ConfigureGeneralSummaryReportModal summary={summary} onClose={closeDialog} />}
              {isBulkValveHealthSummary && (
                <ConfigureBulkValveHealthReportModal
                  summary={summary}
                  onClose={closeDialog}
                  summaryRecordDetails={summaryRecordDetails}
                />
              )}
              {isBulkVibrationHealthSummary && (
                <ConfigureBulkVibrationHealthReportModal
                  summary={summary}
                  onClose={closeDialog}
                  summaryRecordDetails={summaryRecordDetails}
                />
              )}
              {isKpiSummary &&
                (isNodeKPIReportEnabled ? (
                  <ConfigureKpiSummaryNodeReportModal summary={summary} onClose={closeDialog} />
                ) : (
                  <ConfigureKpiSummaryReportModal summary={summary} onClose={closeDialog} />
                ))}
              {isFieldInspectionSummary && (
                <ConfigureFieldInspectionSummaryReportModal summary={summary} onClose={closeDialog} />
              )}
              {isOutageScopeSummary && (
                <ConfigureOutageScopeSummaryReportModal summary={summaryDetail} onClose={closeDialog} />
              )}
              {isPackagedSolutionSummary && (
                <ConfigurePackagedSolutionSummaryReportModal summary={summary} onClose={closeDialog} />
              )}
            </>
          )}
        </Stack>
      </Box>
    </Paper>
  );
};
