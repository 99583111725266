import { Box, Paper, Stack, styled, Typography } from "@mui/material";
import { ReleaseNotesDetailViewModel } from "@bluemarvel/iris-common/model";
import { AttachmentModel } from "@bluemarvel/iris-common/model";
import { AttachmentsGrid } from "attachments/mui/AttachmentsGrid";
import { StyledToggleButton, StyledToggleButtonGroup } from "app/mui/forms/Tabs/TabSelect";
import { useEffect, useState } from "react";
import { AttachmentParent } from "attachments/api";

interface ReleaseNotesAttachmentProps {
  releaseNotesDetail: ReleaseNotesDetailViewModel;
  featureAttachments: AttachmentModel[];
  bugAttachments: AttachmentModel[];
}

/* Custom attachments grid definition for usage in a side-bar */
const GridTile = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    p: 0.5,
    width: "100%",
    maxWidth: {
      xs: "calc(100% / 1)",
      sm: "calc(100% / 1)",
      md: "calc(100% / 1)",
      lg: "calc(100% / 2)",
      xl: "min(calc(100% / 3), 185px)",
    },
  })
);

export const ReleaseNotesAttachments = (props: ReleaseNotesAttachmentProps) => {
  const { releaseNotesDetail, featureAttachments, bugAttachments } = props;
  const handleToggleChanged = (_event: any, value: AttachmentParent) => {
    if (value) {
      setCurrentTab(value);
    }
  };

  //showing the feature attachments by default
  const [currentTab, setCurrentTab] = useState("ReleaseNoteFeature");

  useEffect(() => {
    if (featureAttachments.length === 0 && bugAttachments.length > 0) {
      //if there are no feature attachments, and some bug attachments exist, then show the bug attachments tab
      setCurrentTab("ReleaseNoteBug");
    }
  }, [featureAttachments, bugAttachments]);

  return (
    <>
      <Paper>
        <Box sx={{ p: 2 }}>
          <Stack direction="column" justifyContent="space-between">
            <Typography variant="h6">
              <Box>ATTACHMENTS</Box>
            </Typography>
            <StyledToggleButtonGroup value={currentTab} exclusive size="small" onChange={handleToggleChanged}>
              <StyledToggleButton value={"ReleaseNoteFeature"}>Features</StyledToggleButton>
              <StyledToggleButton value={"ReleaseNoteBug"}>Bugs</StyledToggleButton>
            </StyledToggleButtonGroup>

            {currentTab === "ReleaseNoteFeature" && (
              <ReleaseNotesAttachmentsGrid
                attachmentParents={releaseNotesDetail.features.map((x) => ({
                  id: x.id,
                  title: x.title,
                  attachments: featureAttachments.filter((y) => y.releaseNotesFeatureId === x.id),
                }))}
                attachmentType={currentTab}
              />
            )}

            {currentTab === "ReleaseNoteBug" && (
              <ReleaseNotesAttachmentsGrid
                attachmentParents={releaseNotesDetail.bugs.map((x) => ({
                  id: x.id,
                  title: x.title,
                  attachments: bugAttachments.filter((y) => y.releaseNotesBugId === x.id),
                }))}
                attachmentType={currentTab}
              />
            )}
          </Stack>
        </Box>
      </Paper>
    </>
  );
};

//This represents the common structure between bugs and features so we can streamline them using the same JSX element
export interface AttachmentParentModel {
  id: number;
  title: string;
  attachments: AttachmentModel[];
}

interface ReleaseNotesAttachmentsGridProps {
  attachmentParents: AttachmentParentModel[];
  attachmentType: AttachmentParent;
}

const ReleaseNotesAttachmentsGrid = (props: ReleaseNotesAttachmentsGridProps) => {
  const { attachmentType, attachmentParents } = props;
  const totalAttachments = attachmentParents.reduce((count, parent) => count + parent.attachments.length, 0);
  const attachmentTypeDescription = attachmentType === "ReleaseNoteFeature" ? "Feature" : "Bug";
  return (
    <>
      {totalAttachments === 0 ? (
        <Box padding={3}>No attachments to display</Box>
      ) : (
        <>
          {attachmentParents.map((parentItem, index) => (
            <Box paddingTop={2} key={parentItem.id}>
              {parentItem.attachments.length > 0 && (
                <>
                  <Box paddingBottom={1}>
                    <Typography variant="body1" fontWeight="bold">
                      {attachmentTypeDescription} {index + 1}
                    </Typography>
                  </Box>

                  <AttachmentsGrid
                    isSelectMode={false}
                    onMultiSelectToggle={() => {}}
                    selectedAttachmentIds={new Set<number>()}
                    parentType={attachmentType}
                    parentId={parentItem.id}
                    attachments={parentItem.attachments}
                    onDeleteAttachment={() => {}}
                    canCopyAssemblyImage={false}
                    isSideBar={true}
                  />
                </>
              )}
            </Box>
          ))}
        </>
      )}
    </>
  );
};
