import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { IrisColors } from "app/mui/theme";
import moment from "moment";
import { dateFormat } from "common";
import { useGetPackagedSolutionHistoryRequest } from "packagedSolution/api";
import { PackagedSolutionHistoryEventModel, PackagedSolutionHistoryEventTypeEnum } from "@bluemarvel/iris-common/model";

interface PackagedSolutionHistoryProps {
  packagedSolutionId: number;
}

export default function PackagedSolutionHistory(props: PackagedSolutionHistoryProps) {
  const { data: historyEvents } = useGetPackagedSolutionHistoryRequest(props.packagedSolutionId);

  const createdEvent = historyEvents?.find((h) => h.type === PackagedSolutionHistoryEventTypeEnum.Values.Created);
  const createdDate = createdEvent ? moment(createdEvent.postedDate).format(dateFormat) : "-";
  const completedEvent = historyEvents?.find((h) => h.type === PackagedSolutionHistoryEventTypeEnum.Values.Completed);
  const completedDate = completedEvent ? moment(completedEvent.postedDate).format(dateFormat) : "-";

  return (
    <Grid container spacing={4}>
      <Grid item md={6} sm={12} xs={12}>
        <Grid
          container
          sx={{
            backgroundColor: IrisColors.gray300,
            fontSize: "0.875rem",
            borderRadius: "4px",
            padding: "8px",
            maxWidth: "380px",
          }}
        >
          <Grid item xs={6} whiteSpace="nowrap" color={IrisColors.gray600} paddingX={1}>
            Created
          </Grid>
          <Grid item xs={6} whiteSpace="nowrap" color={IrisColors.gray600} paddingX={1}>
            Completed
          </Grid>
          <Grid item xs={6} whiteSpace="nowrap" paddingX={1} sx={{ fontWeight: 700 }}>
            {createdDate}
          </Grid>
          <Grid item xs={6} whiteSpace="nowrap" paddingX={1} sx={{ fontWeight: 700 }}>
            {completedDate}
          </Grid>
        </Grid>

        <List dense>
          {historyEvents ? (
            historyEvents.map((h) => (
              <ListItem key={h.id}>
                <ListItemAvatar>
                  <Avatar src={h.postedBy?.picture ?? undefined} alt={h.postedBy?.fullName ?? undefined} />
                </ListItemAvatar>
                <ListItemText primary={getHistoryEventText(h)} />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary={`No history`} />
            </ListItem>
          )}
        </List>
      </Grid>
    </Grid>
  );
}
function getHistoryEventText(event: PackagedSolutionHistoryEventModel) {
  let eventDescription = "";
  switch (event.type) {
    case PackagedSolutionHistoryEventTypeEnum.Values.Created:
      eventDescription = "created the package solution";
      break;
    case PackagedSolutionHistoryEventTypeEnum.Values.Completed:
      eventDescription = "completed the package solution";
      break;
    case PackagedSolutionHistoryEventTypeEnum.Values.Reopened:
      eventDescription = "reopened the package solution";
      break;
  }

  return (
    <Typography variant="body2" component="span" color={IrisColors.graySpartan}>
      {event.postedBy ? event.postedBy.fullName : "-"}{" "}
      <Typography variant="body2" component="span" fontStyle="italic">
        {eventDescription}
        {" on "}
        <span style={{ whiteSpace: "nowrap" }}>{moment(event.postedDate).format(dateFormat)}</span>
      </Typography>
    </Typography>
  );
}
