import { Accordion, AccordionSummary, Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormProvider, useForm } from "react-hook-form";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { RichTextEditor } from "app/mui/forms/RichTextEditor";
import AutoSave from "app/mui/forms/AutoSave";
import { TestDataSection } from "./TestDataSection";
import { useUpdateEquipmentHealthCalibrationsRequest } from "records/api";
import { AssetDigest } from "assets";
import { EquipmentHealthCalibrationModel } from "@bluemarvel/iris-common/model";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import * as yup from "yup";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import { useUserContext } from "app";
import { ControlledTextFieldFormItem } from "app/mui/forms/Input/ControlledTextFieldFormItem";
import { KpiSignOff } from "kpis/mui/KpiSignOff";

interface CalibrationProps {
  spec: EquipmentHealthCalibrationModel;
  assets: AssetDigest[];
}

export const signOffValidationSchema = yup.object({
  calibratedBy: yup.string().required("Required"),
  calibratedByCompany: yup.string().required("Required"),
  calibratedByLastModified: yup.date().required("Required"),
  customerContact: yup.string().required("Required"),
  customerContactCompany: yup.string().required("Required"),
  customerContactLastModified: yup.date().required("Required"),
});

export const Calibration = ({ spec, assets }: CalibrationProps) => {
  const { userPermissions } = useUserContext();
  const methods = useForm({ defaultValues: spec });
  const updateCalibration = useUpdateEquipmentHealthCalibrationsRequest();

  const isSignedOff = methods.watch("signedOff");

  const handleAutoSave = () => {
    methods.handleSubmit((updatedCalibration) => {
      updateCalibration.call(updatedCalibration);
    })();
  };

  const handleSignOff = async () => {
    if (isSignedOff) {
      methods.setValue("signedOff", false);
      return;
    }
    try {
      await signOffValidationSchema.validate(methods.getValues(), { abortEarly: false });
      methods.setValue("signedOff", true);
    } catch (errors: any) {
      errors.inner.forEach((currentError) => {
        methods.setError(currentError.path, currentError);
      });
    }
  };

  const assetName = assets.find((asset) => asset.id === spec.assetId)?.tag;

  return (
    <FormProvider {...methods}>
      <AutoSave onSubmit={handleAutoSave} />
      <Accordion>
        <AccordionSummary sx={{ flexDirection: "row-reverse" }} expandIcon={<ExpandMoreIcon />}>
          <Stack direction="row" width="100%" justifyContent="space-between">
            <Stack direction="row" alignItems="center" spacing={4}>
              <Typography variant="subtitle1" fontWeight="bold">
                {assetName}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {spec.calibrationType}
              </Typography>
            </Stack>
            <KpiSignOff isSignedOff={isSignedOff} />
          </Stack>
        </AccordionSummary>
        <Paper sx={{ mb: 2 }}>
          <Box sx={{ p: 4 }}>
            <Typography variant="subtitle2" fontWeight="bold" sx={{ pb: 2 }}>
              Operating Data
            </Typography>
            <Stack spacing={4} direction={{ sm: "column", md: "row" }}>
              <TextFieldFormItem fieldName="product" label="Product" />
              <TextFieldFormItem fieldName="flowRate" label="Flow Rate" />
              <TextFieldFormItem fieldName="flowTemp" label="Flow Temp" />
              <TextFieldFormItem fieldName="correctedDensity" label="Corrected Density" />
              <TextFieldFormItem fieldName="pipeDiameter" label="Pipe Diameter" />
              <TextFieldFormItem fieldName="pipeSchedule" label="Pipe Schedule" />
            </Stack>
          </Box>
        </Paper>
        <Grid container spacing={3} sx={{ pb: 4 }}>
          <TestDataSection testType="asFoundTest" threshold={spec.errorThreshold} />
          <TestDataSection testType="asLeftTest" threshold={spec.errorThreshold} />
        </Grid>
        <Grid container spacing={3} sx={{ p: 4 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
            Notes
          </Typography>
          <RichTextEditor fieldName="notes" label={undefined} />
        </Grid>
        <Box sx={{ padding: 1 }}>
          <Typography variant="subtitle1" fontWeight="bold" sx={{ pb: 2 }}>
            Sign-off
          </Typography>
          <Grid container spacing={2} sx={{ py: 1, justify: "center" }}>
            <Grid container item spacing={4}>
              <Grid item xs={12} md={2}>
                <ControlledTextFieldFormItem
                  fieldName="calibratedBy"
                  label="Calibrated By"
                  readOnly={isSignedOff}
                  alwaysShowInput
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <ControlledTextFieldFormItem
                  fieldName="calibratedByCompany"
                  label="Company"
                  readOnly={isSignedOff}
                  alwaysShowInput
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <DateInputFormItem
                  fieldName="calibratedByLastModified"
                  label="Date"
                  readOnly={isSignedOff}
                  alwaysShowInput
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <FormItemLayout label="">
                  {() =>
                    userPermissions.userCanEditCalibrationSignoff && (
                      <Button
                        variant={isSignedOff ? "contained" : "outlined"}
                        color="secondary"
                        onClick={handleSignOff}
                        startIcon={isSignedOff && <AssignmentTurnedInIcon />}
                      >
                        Sign-off
                      </Button>
                    )
                  }
                </FormItemLayout>
              </Grid>
            </Grid>
            <Grid container item spacing={4}>
              <Grid item xs={12} md={2}>
                <ControlledTextFieldFormItem
                  fieldName="customerContact"
                  label="Customer Contact"
                  readOnly={isSignedOff}
                  alwaysShowInput
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <ControlledTextFieldFormItem
                  fieldName="customerContactCompany"
                  label="Company"
                  readOnly={isSignedOff}
                  alwaysShowInput
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <DateInputFormItem
                  fieldName="customerContactLastModified"
                  label="Date"
                  readOnly={isSignedOff}
                  alwaysShowInput
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Accordion>
    </FormProvider>
  );
};
