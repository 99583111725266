import { PackagedSolutionStatus } from "@bluemarvel/iris-common/model";
import { Box, Stack, useTheme } from "@mui/material";
import { CompleteIcon } from "icons/CompleteIcon";
import { PendingIcon } from "icons/PendingIcon";

const getIconForStatus = (status: PackagedSolutionStatus) => {
  switch (status) {
    case "In progress":
      return <PendingIcon />;
    case "Completed":
      return <CompleteIcon />;
  }
};

interface Props {
  packagedSolutionStatus: PackagedSolutionStatus;
}

export const PackagedSolutionStatusBadge = (props: Props) => {
  const { packagedSolutionStatus } = props;
  const theme = useTheme();
  const fill = theme.palette.primary.main;

  return (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        border: `1px solid ${fill}`,
        padding: "0.1rem 0.5rem",
        fontSize: ".8rem",
        borderRadius: "5px",
        color: fill,
        alignItems: "center",
      }}
    >
      <Box
        flex="0"
        sx={{
          width: "16px",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          svg: { fill: fill },
        }}
      >
        {getIconForStatus(packagedSolutionStatus)}
      </Box>
      <Box flex="1">{packagedSolutionStatus}</Box>
    </Stack>
  );
};
