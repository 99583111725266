import { Box, Paper, Tab, Tabs } from "@mui/material";
import { TabPanel } from "app/mui/tabs/TabPanel";
import { TabWithCount } from "app/mui/tabs/TabWithCount";
import { AttachmentsArea } from "attachments/mui/AttachmentsArea";
import React, { useState } from "react";
import { SummaryDetail, SummaryType } from "summaries/models";
import { SummaryAssetsTab } from "summaries/mui/SummaryAssetsTab";
import { SummaryRecordsTab } from "./SummaryRecordsTab";
import { SummaryRecordsEventRecordsTab } from "./SummaryRecordsEventRecordsTab";

enum TabIndex {
  Attachments = 0,
  Assets = 1,
  Records = 2,
}
interface SummaryTabSectionsProps {
  summaryDetail: SummaryDetail;
  saveChanges: () => void;
}

export const SummaryTabSections: React.FC<SummaryTabSectionsProps> = ({ summaryDetail, saveChanges }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const summary = summaryDetail.model;
  const [recordsCount, setRecordsCount] = useState(0);
  const assetCount = summary.assets.length;
  const assetsTabHiddenForSummaryTypes: SummaryType[] = [
    SummaryType.OutageScope,
    SummaryType.BulkValveHealthType,
    SummaryType.FieldInspection,
    SummaryType.PackagedSolution,
  ];
  const recordsTabHiddenForSummaryTypes: SummaryType[] = [SummaryType.PackagedSolution];

  const handleRecordsCountChange = (newRecordsCount: number) => {
    setRecordsCount(newRecordsCount);
  };

  const handleChangeTab = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2, px: 2 }}>
        <Tabs value={tabIndex} onChange={handleChangeTab}>
          <Tab label="Attachments" sx={{ minWidth: 150 }} value={TabIndex.Attachments} />
          {!assetsTabHiddenForSummaryTypes.includes(summary.summaryType) && (
            <TabWithCount label="Assets" badgeCount={assetCount} value={TabIndex.Assets} />
          )}
          {!recordsTabHiddenForSummaryTypes.includes(summary.summaryType) && (
            <TabWithCount label={"Records"} badgeCount={recordsCount} value={TabIndex.Records} />
          )}
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={TabIndex.Attachments} sx={{ p: 2 }}>
        {!!summary.id && (
          <AttachmentsArea
            parentType="Summary"
            parentId={summary.id}
            parentTag={summary.title}
            readOnly={summary.summaryType === SummaryType.PackagedSolution}
          />
        )}
      </TabPanel>
      <TabPanel value={tabIndex} index={TabIndex.Assets} sx={{ p: 2 }}>
        <SummaryAssetsTab assets={summary.assets ?? []} />
      </TabPanel>
      <TabPanel value={tabIndex} index={TabIndex.Records} sx={{ p: 2 }}>
        {!!summaryDetail.recordsEvent ? (
          <SummaryRecordsEventRecordsTab
            summaryDetail={summaryDetail}
            saveChanges={saveChanges}
            setRecordsCount={handleRecordsCountChange}
          />
        ) : (
          <SummaryRecordsTab
            summaryDetail={summaryDetail}
            saveChanges={saveChanges}
            setRecordsCount={handleRecordsCountChange}
          />
        )}
      </TabPanel>
    </Paper>
  );
};
