import { UserModel } from "@bluemarvel/iris-common/model";
import { Box, Typography } from "@mui/material";
import { dateFormat } from "common";
import moment from "moment";

interface Props {
  lastModifiedBy?: UserModel | null;
  lastModified?: Date;
  textAlign?: "left" | "right";
}

export const LastModified = (props: Props) => {
  const { lastModifiedBy, lastModified, textAlign } = props;
  return (
    <Typography textAlign={textAlign} variant="caption">
      Last Modified
      {!!lastModifiedBy ? ` by ${lastModifiedBy.fullName} • ` : " on "}
      <Box
        sx={{
          whiteSpace: "nowrap",
          display: "inline",
        }}
      >
        {!!lastModified && moment(lastModified).format(dateFormat)}
      </Box>
    </Typography>
  );
};
