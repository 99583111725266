import * as React from "react";
import { Summary } from "summaries";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { CheckboxFormItem } from "app/mui/forms/CheckboxFormItem";
import { useBackgroundReportContext } from "app/contexts/BackgroundReportContext";

interface Props extends BaseDialogProps {
  summary: Summary;
}

interface FormValues {
  includeNotes: boolean;
  includeKpiHistory: boolean;
  includeKpiDetails: boolean;
  includePhotos: boolean;
  appendPdfAttachments: boolean;
}

export const ConfigureKpiSummaryNodeReportModal: React.FunctionComponent<Props> = (props) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      includeNotes: true,
      includeKpiHistory: true,
      includeKpiDetails: true,
      includePhotos: true,
      appendPdfAttachments: true,
    },
  });

  const { generateKpiReport: generateKpiSummaryReportNode } = useBackgroundReportContext();

  const handleSubmit = methods.handleSubmit(() => {
    const values = methods.getValues();
    generateKpiSummaryReportNode?.call({
      id: props.summary.id,
      pdf: true,
      ...values,
    });

    props.onClose();
  });

  return (
    <BaseDialog onClose={props.onClose} maxWidth="md">
      <DialogTitle variant="h6" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
        Report options
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <CheckboxFormItem fieldName="includeNotes" label="Include notes" />
            <CheckboxFormItem fieldName="includeKpiHistory" label="Include KPI history" />
            <CheckboxFormItem fieldName="includeKpiDetails" label="Include Key Performance Indicators Details" />
            <CheckboxFormItem fieldName="includePhotos" label="Include photos" />
            <CheckboxFormItem fieldName="appendPdfAttachments" label="Append PDF attachments" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton loading={false} variant="contained" color="secondary" onClick={handleSubmit}>
            Generate
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
