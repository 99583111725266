import {
  ReleaseNotesModel,
  CreateReleaseNotesModel,
  ReleaseNotesDetailViewModel,
  ReleaseNotesListViewModel,
  UpdateReleaseNotesModel,
  ReleaseNotesEnhancementModel,
  CreateReleaseNotesEnhancementModel,
  UpdateReleaseNotesEnhancementModel,
  CreateTutorialsModel,
  TutorialsDetailViewModel,
  UpdateTutorialsModel,
  TutorialsListViewModel,
} from "@bluemarvel/iris-common/model";
import {
  useBaseAxiosDeleteRequest,
  useBaseAxiosGetRequest,
  useBaseAxiosGetRequestWithAutoRefresh,
  useBaseAxiosPostRequest,
  useBaseAxiosPutRequest,
} from "app";

export const releaseNotesMutationUriMatchPatterns = [/api\/releaseNotes.*/gi];
export const tutorialsMutationUriMatchPatterns = [/api\/tutorials.*/gi];

const basePath = "api/releaseNotes";
const tutorialsBasePath = "api/tutorials";

/* GETS */
export const useGetReleaseNotesRequest = () =>
  useBaseAxiosGetRequest<ReleaseNotesListViewModel[]>(basePath, {
    useNode: true,
  });

export const useGetTutorialsRequest = () =>
  useBaseAxiosGetRequest<TutorialsListViewModel[]>(tutorialsBasePath, {
    useNode: true,
  });

export const useGetReleaseNotesDetailRequest = (id: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<ReleaseNotesDetailViewModel>(
    `${basePath}/${id}`,
    releaseNotesMutationUriMatchPatterns,
    { useNode: true }
  );

export const useGetTutorialsDetailRequest = (id: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<TutorialsDetailViewModel>(
    `${tutorialsBasePath}/${id}`,
    tutorialsMutationUriMatchPatterns,
    { useNode: true }
  );

/* POSTS */
export const useCreateReleaseNotesRequest = () =>
  useBaseAxiosPostRequest<CreateReleaseNotesModel, ReleaseNotesModel>(basePath, {
    useNode: true,
  });

export const useCreateTutorialsRequest = () =>
  useBaseAxiosPostRequest<CreateTutorialsModel, TutorialsDetailViewModel>(tutorialsBasePath, {
    useNode: true,
  });

export const useCreateReleaseNotesFeatureRequest = () =>
  useBaseAxiosPostRequest<CreateReleaseNotesEnhancementModel, ReleaseNotesEnhancementModel>(`${basePath}/feature`, {
    useNode: true,
  });

export const useCreateReleaseNotesBugRequest = () =>
  useBaseAxiosPostRequest<CreateReleaseNotesEnhancementModel, ReleaseNotesEnhancementModel>(`${basePath}/bug`, {
    useNode: true,
  });

/* DELETES */
export const useDeleteReleaseNotesRequest = () =>
  useBaseAxiosDeleteRequest((id) => `${basePath}/${id}`, { useNode: true });

export const useDeleteTutorialsRequest = () =>
  useBaseAxiosDeleteRequest((id) => `${tutorialsBasePath}/${id}`, { useNode: true });

export const useDeleteReleaseNotesFeatureRequest = () =>
  useBaseAxiosDeleteRequest((id) => `${basePath}/feature/${id}`, { useNode: true });

export const useDeleteReleaseNotesBugRequest = () =>
  useBaseAxiosDeleteRequest((id) => `${basePath}/bug/${id}`, { useNode: true });

/* PUTS */
export const useUpdateReleaseNotesRequest = () =>
  useBaseAxiosPutRequest<UpdateReleaseNotesModel, ReleaseNotesModel>(basePath, {
    useNode: true,
  });

export const useUpdateTutorialsRequest = () =>
  useBaseAxiosPutRequest<UpdateTutorialsModel, void>(tutorialsBasePath, {
    useNode: true,
  });
export const useUpdateReleaseNotesFeatureRequest = () =>
  useBaseAxiosPutRequest<UpdateReleaseNotesEnhancementModel, ReleaseNotesEnhancementModel>(`${basePath}/feature`, {
    useNode: true,
  });

export const useUpdateReleaseNotesBugRequest = () =>
  useBaseAxiosPutRequest<UpdateReleaseNotesEnhancementModel, ReleaseNotesEnhancementModel>(`${basePath}/bug`, {
    useNode: true,
  });
