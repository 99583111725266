import React, { useState } from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { IrisColors } from "app/mui/theme";
import { PackagedSolutionModel } from "@bluemarvel/iris-common/model";
import { useDeletePackageSolutionsRequest } from "packagedSolution/api";
import { useNavigate } from "react-router-dom";
import { useNotifications, useSiteContext } from "app";

interface RemovePackagedSolutionDialogProps {
  open: boolean;
  onClose: () => void;
  packageSolution: PackagedSolutionModel;
}

export const RemovePackagedSolutionDialog: React.FC<RemovePackagedSolutionDialogProps> = ({
  open,
  onClose,
  packageSolution,
}) => {
  const [confirmationText, setConfirmationText] = useState("");
  const packageSolutionName = packageSolution.name;
  const navigate = useNavigate();
  const notifications = useNotifications();
  const deletePackagedSolutionRequest = useDeletePackageSolutionsRequest();
  const currentSite = useSiteContext().currentSiteWithNavigation!;

  const isConfirmDisabled = confirmationText !== packageSolutionName;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete Packaged Solution</DialogTitle>
      <DialogContent>
        <Box display="flex" p={2} bgcolor={IrisColors.lightOrange} mb={2}>
          <ReportProblemIcon sx={{ color: "warning.main", mr: 2 }} />
          <Box>
            <Typography fontWeight="bold" color={IrisColors.brown} mb={1}>
              Warning
            </Typography>
            <Typography variant="body2" color={IrisColors.brown}>
              Deleting the packaged solution will erase all associated data, including equipment records and operating
              system details.
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" mb={2}>
          Are you sure you want to delete this packaged solution?
        </Typography>
        <Typography variant="subtitle2" mb={1}>
          Type "{packageSolutionName}" to confirm
        </Typography>
        <TextField
          fullWidth
          value={confirmationText}
          onChange={(e) => setConfirmationText(e.target.value)}
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={() => {
            deletePackagedSolutionRequest.call(packageSolution.id).then(() => {
              notifications.success(`Deleted ${packageSolution.name}`);
              navigate(currentSite.routeTo.PackageSolutions());
            });
          }}
          disabled={isConfirmDisabled}
          variant="contained"
          color="error"
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
