import { useAuth0 } from "@auth0/auth0-react";
import { clearCache } from "axios-hooks";
import _ from "lodash";
import { FunctionComponent, ReactNode, createContext, useContext, useEffect } from "react";
import { RecordType } from "records";
import { Permissions, UserPermissions, UserWithPermissions } from "users";
import { useBaseAxiosGetRequest } from "../hooks";
import { appInsightsCustomTelemetry } from "./AppInsightsContextProvider";
import { AssetType } from "assets/models/assetType";
import { getBusinessAreaForAssetType, BusinessArea } from "assets/models/businessArea";

const getUserPermissions = (userHasPermission: (permission: Permissions) => boolean): UserPermissions => {
  return {
    userCanViewAsset: userHasPermission(Permissions.ViewAsset),
    userCanAddAssets:
      userHasPermission(Permissions.AddAsset) ||
      userHasPermission(Permissions.AddInstrumentationAsset) ||
      userHasPermission(Permissions.AddMachineryAsset),
    userCanAddAsset: (assetType: AssetType) => {
      if (userHasPermission(Permissions.AddAsset)) return true;
      const ba = getBusinessAreaForAssetType(assetType);
      switch (ba) {
        case BusinessArea.Instrumentation:
          return userHasPermission(Permissions.AddInstrumentationAsset);
        case BusinessArea.Machinery:
          return userHasPermission(Permissions.AddMachineryAsset);
        default:
          return false;
      }
    },
    userCanAddAnyAsset: userHasPermission(Permissions.AddAsset),
    userCanAddMachineryAsset: userHasPermission(Permissions.AddMachineryAsset),
    userCanAddInstrumentationAsset: userHasPermission(Permissions.AddInstrumentationAsset),
    userCanGenerateAssetReport: userHasPermission(Permissions.GenerateAssetReport),
    //** edit any asset type */
    userCanEditAssets:
      userHasPermission(Permissions.EditAsset) ||
      userHasPermission(Permissions.EditInstrumentationAsset) ||
      userHasPermission(Permissions.EditMachineryAsset),
    userCanEditAsset: (assetType: AssetType) => {
      if (userHasPermission(Permissions.EditAsset)) return true;
      const ba = getBusinessAreaForAssetType(assetType);
      switch (ba) {
        case BusinessArea.Instrumentation:
          return userHasPermission(Permissions.EditInstrumentationAsset);
        case BusinessArea.Machinery:
          return userHasPermission(Permissions.EditMachineryAsset);
        default:
          return false;
      }
    },
    userCanViewRecord: userHasPermission(Permissions.ViewRecord),
    //** edit any record type except valve diagnostic records */
    userCanEditRecords:
      userHasPermission(Permissions.EditRecord) ||
      userHasPermission(Permissions.EditValveDiagnosticRecord) ||
      userHasPermission(Permissions.EditInstrumentationRecord) ||
      userHasPermission(Permissions.EditMachineryRecord),
    userCanEditRecord: (assetTypes: AssetType[], recordType: RecordType) => {
      if (recordType === RecordType.ValveDiagnostic && !userHasPermission(Permissions.EditValveDiagnosticRecord))
        return false;

      if (userHasPermission(Permissions.EditRecord)) return true;

      if (
        assetTypes.length === 0 &&
        (userHasPermission(Permissions.EditInstrumentationRecord) || userHasPermission(Permissions.EditMachineryRecord))
      )
        return true;

      var businessAreas = _.uniq(assetTypes.map((at) => getBusinessAreaForAssetType(at)));
      for (const ba in businessAreas) {
        switch (ba) {
          case BusinessArea.Instrumentation:
            if (userHasPermission(Permissions.EditInstrumentationRecord)) return true;
            break;
          case BusinessArea.Machinery:
            if (userHasPermission(Permissions.EditMachineryRecord)) return true;
            break;
        }
      }
      return false;
    },
    userCanEditRecordsEvents: userHasPermission(Permissions.EditRecord),
    userCanReopenRecord: userHasPermission(Permissions.ReopenRecord),
    userCanAddComments: userHasPermission(Permissions.AddComments),
    userCanViewInternalHistoryEvents: userHasPermission(Permissions.ViewInternalHistoryEvents),
    userCanGenerateRecordReport: userHasPermission(Permissions.GenerateRecordReport),
    userCanEditRecordFlags: userHasPermission(Permissions.EditRecordFlags),
    userCanViewKpi: userHasPermission(Permissions.ViewKpi),
    userCanEditKpi: userHasPermission(Permissions.EditKpi),
    userCanViewKpiTemplate: userHasPermission(Permissions.ViewKpiTemplate),
    userCanEditKpiTemplate: userHasPermission(Permissions.EditKpiTemplate),
    userCanViewAssetList: userHasPermission(Permissions.ViewAssetList),
    userCanExportAssetList: userHasPermission(Permissions.ExportAssetList),
    userCanExportAssetAttachment: userHasPermission(Permissions.ExportAssetAttachment),
    userCanViewRecordList: userHasPermission(Permissions.ViewRecordList),
    userCanExportRecordList: userHasPermission(Permissions.ExportRecordList),
    userCanViewOtherSites: userHasPermission(Permissions.ViewOtherSites),
    userCanEditOtherSites: userHasPermission(Permissions.EditOtherSites),
    userCanViewApplication: userHasPermission(Permissions.ViewApplication),
    userCanEditApplication: userHasPermission(Permissions.EditApplication),
    userCanGenerateApplicationReport: userHasPermission(Permissions.GenerateApplicationReport),
    userCanSearchAnything: userHasPermission(Permissions.SearchAnything),
    userCanViewSiteDashboard: userHasPermission(Permissions.ViewSiteDashboard),
    userCanViewSiteDashboardReports: userHasPermission(Permissions.ViewSiteDashboardReports),
    userCanManageCustomersAndSites: userHasPermission(Permissions.ManageCustomersAndSites),
    userCanManageAreas: userHasPermission(Permissions.ManageAreas),
    userCanManageKeywords: userHasPermission(Permissions.ManageKeywords),
    userCanAssignUsersToSites: userHasPermission(Permissions.AssignUsersToSites),
    userCanImportAssetsAndRecords: userHasPermission(Permissions.ImportAssetsAndRecords),
    userCanUnlockKpi: userHasPermission(Permissions.UnlockKpi),
    userCanViewSummary: userHasPermission(Permissions.ViewSummary),
    userCanEditSummary: userHasPermission(Permissions.EditSummary),
    userCanGenerateSummaryReport: userHasPermission(Permissions.GenerateSummaryReport),
    userCanViewPackagedSolution: userHasPermission(Permissions.ViewPackagedSolution),
    userCanEditPackagedSolution: userHasPermission(Permissions.EditPackagedSolution),
    userCanEditCalibrationSignoff: userHasPermission(Permissions.EditCalibrationSignoff),
    userCanEditReleaseNotes: userHasPermission(Permissions.EditReleaseNotes),
  };
};

export interface UserContextProps {
  currentUser: UserWithPermissions | null;
  currentUserLoading: boolean;
  userPermissions: UserPermissions;
  signout: () => any;
}

export const UserContext = createContext<UserContextProps>({
  currentUser: null,
  currentUserLoading: true,
  userPermissions: getUserPermissions(() => false),
  signout: () => null,
});

export const UserContextProvider: FunctionComponent<{
  children?: ReactNode;
}> = (props) => {
  const { logout, user: auth0User } = useAuth0();

  const signout = () => {
    clearCache();
    logout({ localOnly: false, returnTo: window.location.origin });
  };

  useEffect(() => {
    appInsightsCustomTelemetry.currentUserId = auth0User?.sub ?? null;
  }, [auth0User?.sub]);

  const { data: currentUser, loading: currentUserLoading } = useBaseAxiosGetRequest<UserWithPermissions | null>(
    "/api/users/currentUser",
    { axios: { useCache: true }, useNode: true }
  );

  const userHasPermission = (permission: Permissions) => currentUser?.permissions.includes(permission) ?? false;
  const userPermissions = getUserPermissions(userHasPermission);

  return (
    <UserContext.Provider value={{ currentUser, currentUserLoading, userPermissions, signout }}>
      {props.children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
