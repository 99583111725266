import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FormProvider, useForm, UseFormReturn } from "react-hook-form";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { Asset } from "assets/models/asset";
import { getEquipmentTypeOptions, getSubtypeOptions } from "assets/utilities";

interface EditSubtypeDialogProps extends BaseDialogProps {
  asset: Asset;
  methods: UseFormReturn<Asset, any>;
  saveChanges: () => void;
}

const validationSchema = yup.object({
  generalSubtype: yup.string().nullable().required("Subtype is required"),
  subtypeDescription: yup
    .string()
    .nullable()
    .when("generalSubtype", {
      is: "Other",
      then: yup.string().nullable().required("Subtype Description is required"),
      otherwise: yup.string().nullable(),
    }),
  equipmentType: yup
    .string()
    .nullable()
    .when("generalSubtype", {
      is: (subtype: string) => subtype !== "Other",
      then: yup.string().nullable().required("Equipment Type is required"),
      otherwise: yup.string().nullable(),
    }),
  equipmentTypeDescription: yup
    .string()
    .nullable()
    .when(["generalSubtype", "equipmentType"], {
      is: (generalSubtype, equipmentType) => generalSubtype !== "Other" && equipmentType === "Other",
      then: yup.string().nullable().required("Equipment Type Description is required"),
      otherwise: yup.string().nullable(),
    }),
});

const validationKeys = ["generalSubtype", "equipmentType", "subtypeDescription", "equipmentTypeDescription"] as const;

export const EditSubtypeDialog = (props: EditSubtypeDialogProps) => {
  const { asset, onClose } = props;

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      generalSubtype: asset.generalSubtype,
      subtypeDescription: asset.subtypeDescription,
      equipmentType: asset.equipmentType,
      equipmentTypeDescription: asset.equipmentTypeDescription,
    },
  });

  const { watch, setValue, handleSubmit } = methods;

  const generalSubtype = watch("generalSubtype");
  const watchedEquipmentType = watch("equipmentType");

  const isSubtypeOther = generalSubtype === "Other";
  const isEquipmentOther = watchedEquipmentType === "Other";

  useEffect(() => {
    if (generalSubtype !== asset.generalSubtype) {
      setValue("equipmentType", null);
      setValue("subtypeDescription", null);
      setValue("equipmentTypeDescription", null);
    }
  }, [generalSubtype, asset.generalSubtype, setValue]);

  const onSubmit = () => {
    handleSubmit((values) => {
      let hasChanges = false;
      for (const key of validationKeys) {
        const value = values[key];
        const initialValue = asset[key];
        if (value !== initialValue) {
          props.methods.setValue(key, value);
          hasChanges = true;
        }
      }
      if (hasChanges) {
        props.saveChanges();
      }
      onClose();
    })();
  };

  return (
    <BaseDialog onClose={onClose}>
      <DialogTitle>Edit Subtype</DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "300px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <AutocompleteFormItem
              label="Subtype"
              fieldName="generalSubtype"
              options={getSubtypeOptions()}
              autoDefault={false}
            />
            {isSubtypeOther && <TextFieldFormItem label="Subtype Description" fieldName="subtypeDescription" />}
            {!isSubtypeOther && generalSubtype && (
              <>
                <AutocompleteFormItem
                  label="Equipment Type"
                  fieldName="equipmentType"
                  options={getEquipmentTypeOptions(generalSubtype)}
                  autoDefault={false}
                />
                {isEquipmentOther && (
                  <TextFieldFormItem label="Equipment Type Description" fieldName="equipmentTypeDescription" />
                )}
              </>
            )}
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={onClose}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            DONE
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
