import * as React from "react";
import { Box, ButtonBase, Collapse, Fade, Stack, SxProps, Theme, Typography } from "@mui/material";
import { useState } from "react";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

interface ExpansionPanelProps {
  title: string;
  titleVariant?: "subtitle1" | "h6"; //h6 is the default if not provided. Consider style standards before adding more options to this.
  defaultExpanded?: boolean;
  children: React.ReactNode;
  rightSideComponent?: React.ReactNode;
  sx?: SxProps<Theme>;
  lockToDefault?: boolean;
  previewText?: string;
}

export const ExpansionPanel = (props: ExpansionPanelProps) => {
  const [open, setOpen] = useState<boolean>(props.defaultExpanded ?? false);

  const handleToggleExpand = () => {
    if (props.lockToDefault) return;
    setOpen(!open);
  };

  return (
    <Box sx={props.sx}>
      <Stack direction="row" sx={{ width: "100%" }}>
        <ButtonBase onClick={handleToggleExpand} sx={{ width: "100%", justifyContent: "flex-start" }}>
          <Box sx={{ p: 2 }}>
            <Stack spacing={1} direction="row" alignItems="center" width="100%" justify-content="flex-start">
              <KeyboardArrowDown sx={{ transform: open ? "rotate(0deg)" : "rotate(180deg)", transition: "all 0.3s" }} />
              <Typography variant={props.titleVariant ?? "h6"} fontWeight="bold" textAlign="left">
                {props.title}
              </Typography>
              {!open && props.previewText && (
                <Box sx={{ pl: 5 }}>
                  <Typography variant="body1" textAlign="left">
                    {props.previewText}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>
        </ButtonBase>
        {Boolean(props.rightSideComponent) && (
          <Fade in={open}>
            <Box sx={{ p: 2 }}>{props.rightSideComponent}</Box>
          </Fade>
        )}
      </Stack>
      <Collapse in={open}>{props.children}</Collapse>
    </Box>
  );
};
