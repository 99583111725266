import { Box, Stack, TextField, TextFieldProps, useFormControl } from "@mui/material";
import React from "react";
import NumberFormat, { NumberFormatProps } from "react-number-format";

interface CustomProps {
  onChange?: (value: string | null) => void;
}

const NumericInputWrapper = React.forwardRef<NumberFormatProps, NumberFormatProps & CustomProps>(
  function NumericInputWrapper(props, ref) {
    const { onChange, ...rest } = props;
    return (
      <NumberFormat
        {...rest}
        getInputRef={ref}
        onValueChange={({ value }) => onChange?.(value || null)}
        isNumericString
      />
    );
  }
);

export interface NumberInputProps extends Omit<TextFieldProps, "InputProps"> {
  numberFormatProps?: NumberFormatProps & CustomProps;
  beforeElement?: React.ReactNode;
  thousandSeparator?: boolean;
}

export const NumberFormatInput = (props: NumberInputProps) => {
  const control = useFormControl();
  const {
    numberFormatProps: { decimalScale = 2, allowNegative = false, ...otherNumberFormatProps } = {},
    thousandSeparator = true,
    beforeElement,
    size = "small",
    ...textFieldProps
  } = props;

  return (
    <Stack direction="row" alignItems="center">
      {!!beforeElement && <Box>{beforeElement}</Box>}
      <TextField
        {...textFieldProps}
        error={control?.error}
        fullWidth
        size={size}
        InputProps={{
          inputComponent: NumericInputWrapper as any,
          inputProps: {
            ...otherNumberFormatProps,
            decimalScale: decimalScale,
            allowNegative: allowNegative,
            thousandSeparator,
            disabled: control?.disabled || false,
          },
        }}
      />
    </Stack>
  );
};
