import React from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import ConfirmationDialog from "common/mui/ConfirmationDialog";
import { Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { AttachmentParent } from "attachments/api";
import { useUserContext } from "app/contexts/UserContext";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { ReleaseNotesEnhancementModel } from "@bluemarvel/iris-common/model";
import { useDeleteReleaseNotesBugRequest, useDeleteReleaseNotesFeatureRequest } from "../../api";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";

interface IReleaseNotesEnhancementItemActionsProps {
  enhancement: ReleaseNotesEnhancementModel;
  enhancementType: AttachmentParent;
}

const pageActions = (enhancementType: AttachmentParent) =>
  createStaticActions((actionDispatcher, userPermissions) => [
    {
      icon: <DeleteOutlineOutlinedIcon />,
      label: "Delete Feature",
      action: () => actionDispatcher.dispatch(DialogAction.DeleteReleaseNotesFeature),
      hidden: enhancementType !== "ReleaseNoteFeature" || !userPermissions.userCanEditReleaseNotes,
    },
    {
      icon: <DeleteOutlineOutlinedIcon />,
      label: "Delete Bug",
      action: () => actionDispatcher.dispatch(DialogAction.DeleteReleaseNotesBug),
      hidden: enhancementType !== "ReleaseNoteBug" || !userPermissions.userCanEditReleaseNotes,
    },
  ]);

export const ReleaseNotesEnhancementItemActions = (props: IReleaseNotesEnhancementItemActionsProps) => {
  const { enhancement, enhancementType } = props;
  const { userPermissions } = useUserContext();
  const showActions = userPermissions.userCanEditReleaseNotes;
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(enhancementType), actionDispatcher);
  const [isDeleting, setIsDeleting] = useState(false);
  const deleteReleaseNoteFeature = useDeleteReleaseNotesFeatureRequest();
  const deleteReleaseNoteBug = useDeleteReleaseNotesBugRequest();

  React.useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const [openDialog, setOpenDialog] = React.useState<DialogAction | null>(null);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const handleAction = useCallback((action: string) => {
    switch (action) {
      case DialogAction.DeleteReleaseNotesFeature:
        setOpenDialog(DialogAction.DeleteReleaseNotesFeature);
        break;
      case DialogAction.DeleteReleaseNotesBug:
        setOpenDialog(DialogAction.DeleteReleaseNotesBug);
        break;
    }
  }, []);

  const deleteFeature = async () => {
    setIsDeleting(true);
    await deleteReleaseNoteFeature
      .call(enhancement.id)
      .then((res) => {
        if (res !== undefined) {
          closeDialog();
        }
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  const deleteBug = async () => {
    setIsDeleting(true);
    await deleteReleaseNoteBug
      .call(enhancement.id)
      .then((res) => {
        if (res !== undefined) {
          closeDialog();
        }
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <>
      {showActions && <ActionsDropdownMenu actions={actions} rowActionMenu={true} />}

      {openDialog === DialogAction.DeleteReleaseNotesFeature && (
        <ConfirmationDialog
          title="Delete feature?"
          open={openDialog === DialogAction.DeleteReleaseNotesFeature}
          cancel={() => closeDialog()}
          loading={isDeleting}
          confirmButtonText="Delete"
          confirm={deleteFeature}
        >
          <Typography variant="body2">Are you sure you want to delete this feature?</Typography>
        </ConfirmationDialog>
      )}
      {openDialog === DialogAction.DeleteReleaseNotesBug && (
        <ConfirmationDialog
          title="Delete bug?"
          open={openDialog === DialogAction.DeleteReleaseNotesBug}
          cancel={() => closeDialog()}
          loading={isDeleting}
          confirmButtonText="Delete"
          confirm={deleteBug}
        >
          <Typography variant="body2">Are you sure you want to delete this bug?</Typography>
        </ConfirmationDialog>
      )}
    </>
  );
};
