import { AssetDigest } from "assets";
import { RecordDetailView, RecordDigest } from "records";
import { User } from "../users";
import { KpiValueDigest } from "kpis";
import { RecordsEventDigest } from "recordsEvents";
import { PackagedSolutionEquipmentModel, ReportCardPackagedSolutionModel } from "@bluemarvel/iris-common/model";

export interface SummaryDigest {
  id: number;
  siteId: number;
  summaryType: SummaryType;
  title: string;
  status: SummaryStatus;
}

export interface PackagedSolutionSummary {
  packagedSolutionId: number;
  packagedSolutionReportCard: ReportCardPackagedSolutionModel;
  packagedSolutionEquipments: PackagedSolutionEquipmentModel[];
  equipmentHealthRecords: RecordDetailView[];
}

export interface Summary {
  id: number;
  siteId: number;
  summaryType: SummaryType;
  title: string;
  status: SummaryStatus;
  summaryDate: Date;
  closed: Date | null;
  customerContact: string;
  refWO: string;
  highlights: string;
  challenges: string;
  generalRecommendations: string;
  pathForward: string;
  recordsEventId: number | null;
  highlightsLabel: string;
  challengesLabel: string;
  generalRecommendationLabel: string;
  pathForwardLabel: string;

  assets: AssetDigest[];
  kpiValues: KpiValueDigest[];
  records: RecordDigest[];
  reportBy: User[];
  packagedSolution: PackagedSolutionSummary | null;
}

export interface SummaryDetail {
  id: number;
  siteId: number;
  model: Summary;
  attachmentCount: number;
  closed: Date | null;
  closedBy: User;
  created: Date;
  createdBy: User;
  recordsEvent: RecordsEventDigest | null;
}

export enum SummaryType {
  General = "General",
  BulkValveHealthType = "Bulk valve health",
  KPI = "KPI",
  OutageScope = "Outage scope",
  FieldInspection = "Field inspection",
  PackagedSolution = "Packaged solution",
  BulkVibrationHealth = "Bulk vibration health",
}

export enum SummaryStatus {
  Open = "Open",
  Closed = "Closed",
}
