import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { BaseDialog } from "app/mui/common/dialogs/BaseDialog";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { useNavigate } from "react-router-dom";
import { formatIdRoutePath, Routes } from "app";
import { useCreateTutorialsRequest } from "../../api";

interface CreateTutorialsDialogProps {
  onClose: () => void;
}

const validationSchema = yup.object({
  name: yup.string().required("required"),
});

export const CreateTutorialsDialog = ({ onClose }: CreateTutorialsDialogProps) => {
  const navigate = useNavigate();

  const createTutorialsRequest = useCreateTutorialsRequest();
  const methods = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      createTutorialsRequest
        .call({
          name: values.name,
        })
        .then((Tutorial) => {
          navigate(formatIdRoutePath(Routes.Tutorial, Tutorial.id));
        });
    })();
  };

  const handleClose = () => {
    methods.reset();
    onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Create Tutorial
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <TextFieldFormItem label="Name" fieldName="name" />
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={onSubmit} type="submit" variant="contained" color="secondary">
            Create and Open
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
