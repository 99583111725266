import ConfirmationDialog from "common/mui/ConfirmationDialog";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { useCallback, useEffect, useState } from "react";
import { useUserContext } from "app";
import { Typography } from "@mui/material";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";
import { useDeleteRecordAssetSpecificationRequest } from "records/api";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";

const pageActions = () =>
  createStaticActions((actionDispatcher, userPermissions) => [
    {
      icon: <DeleteOutlineOutlinedIcon />,
      label: "Delete Specification",
      action: () => actionDispatcher.dispatch(DialogAction.DeleteSpecification),
    },
  ]);

export interface DeleteSpecificationProps {
  recordId: number;
  assetId: number;
}

export const DeleteSpecification = ({ recordId, assetId }: DeleteSpecificationProps) => {
  const { userPermissions } = useUserContext();
  const showActions = userPermissions.userCanEditRecords;
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(), actionDispatcher);
  const deleteSpecification = useDeleteRecordAssetSpecificationRequest(recordId);
  const [openDialog, setOpenDialog] = useState<DialogAction | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const handleAction = useCallback((action: string) => {
    if (action === DialogAction.DeleteSpecification) {
      setOpenDialog(DialogAction.DeleteSpecification);
    }
  }, []);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const handleOnDelete = () => {
    setIsDeleting(true);
    deleteSpecification.call(assetId).then(() => {
      setIsDeleting(false);
      closeDialog();
    });
  };

  return (
    <>
      {showActions && <ActionsDropdownMenu actions={actions} rowActionMenu={true} />}

      {openDialog === DialogAction.DeleteSpecification && (
        <ConfirmationDialog
          title="Delete specification?"
          open={openDialog === DialogAction.DeleteSpecification}
          cancel={() => closeDialog()}
          loading={isDeleting}
          confirmButtonText="Delete"
          confirm={handleOnDelete}
        >
          <Typography variant="body2">Are you sure you want to delete this specification?</Typography>
        </ConfirmationDialog>
      )}
    </>
  );
};
