import { Grid, Stack } from "@mui/material";
import { Routes, useUserContext } from "app";
import { EntityDetailsContainerSkeleton } from "app/mui/common/skeletons/EntityDetailsContainerSkeleton";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMatch } from "react-router-dom";
import { useGetSummaryDetailRequest, useUpdateSummaryRequest } from "summaries/api";
import { SummaryDetail } from "summaries/models";
import { SummaryHeader } from "./sections/SummaryHeader";
import { SummaryDetails } from "./sections/SummaryDetails";
import { SummaryNotes } from "./sections/SummaryNotes";
import { SummaryTabSections } from "./sections/SummaryTabSections";
import { PackagedSolutionOverallHealth } from "packagedSolution/mui/sections/PackagedSolutionOverallHealth";
import { PackagedSolutionReportCard } from "packagedSolution/mui/sections/PackagedSolutionReportCard";

export const SummaryPage = () => {
  const routeMatch = useMatch(Routes.Summary);
  const [summaryDetail, setSummaryDetail] = useState<SummaryDetail | null>(null);

  const { data, loading } = useGetSummaryDetailRequest(Number(routeMatch?.params?.id));

  useEffect(() => {
    setSummaryDetail(data);
  }, [data]);

  if (loading && !summaryDetail) {
    return <EntityDetailsContainerSkeleton />;
  } else if (!summaryDetail) {
    return null;
  }
  return <SummaryDetailPageWithForm summaryDetail={summaryDetail} />;
};

interface SummaryDetailPageWithFormProps {
  summaryDetail: SummaryDetail;
}

const SummaryDetailPageWithForm = (props: SummaryDetailPageWithFormProps) => {
  const { summaryDetail } = props;
  const summary = summaryDetail.model;
  const defaultValues = makeSafeForReactHookForm(summary);
  const methods = useForm({ defaultValues });
  const updateSummary = useUpdateSummaryRequest();
  const userCanEdit = useUserContext().userPermissions.userCanEditSummary;
  const { packagedSolution } = summary;

  const saveChanges = () => {
    return methods.handleSubmit((values) => {
      updateSummary.call(values);
    })();
  };

  return (
    <PermissionAwareFormProvider {...methods} require={() => userCanEdit}>
      <AutoSave defaultValues={defaultValues} onSubmit={saveChanges} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} xl={9}>
          <Stack spacing={2}>
            <SummaryHeader summaryDetail={summaryDetail} methods={methods} saveChanges={saveChanges} />
            <SummaryDetails summaryDetail={summaryDetail} methods={methods} saveChanges={saveChanges} />
            <SummaryNotes summaryDetail={summaryDetail} methods={methods} saveChanges={saveChanges} />
            <SummaryTabSections summaryDetail={summaryDetail} saveChanges={saveChanges} />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <Stack spacing={2} direction={{ xs: "column", md: "row", lg: "column" }}>
            {packagedSolution && (
              <>
                <PackagedSolutionOverallHealth
                  reportCard={packagedSolution.packagedSolutionReportCard}
                  equipmentHealth={packagedSolution.equipmentHealthRecords}
                />
                <PackagedSolutionReportCard
                  packagedSolutionId={packagedSolution.packagedSolutionId}
                  reportCard={packagedSolution.packagedSolutionReportCard}
                  equipments={packagedSolution.packagedSolutionEquipments}
                  equipmentHealth={packagedSolution.equipmentHealthRecords}
                  userCanEdit={false}
                />
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </PermissionAwareFormProvider>
  );
};
