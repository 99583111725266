import React from "react";
import { Box, LinearProgress, Stack } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { MetadataColumnProvider } from "app/mui/tables/metadata";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { ToolbarWithTabViews } from "app/mui/tables/ToolbarWithTabViews";
import { AssetDigest } from "assets";

export const SummaryAssetsTab = (props: { assets: AssetDigest[] }) => {
  const apiRef = useGridApiRef();
  return (
    <Box sx={{ height: "500px" }}>
      <Stack spacing={2} height="100%">
        <SummaryAssetsTable apiRef={apiRef} data={props.assets} />
      </Stack>
    </Box>
  );
};

interface SummaryAssetsTableProps {
  apiRef: React.MutableRefObject<GridApiPro> | undefined;
  data: AssetDigest[];
}

const SummaryAssetsTable = (props: SummaryAssetsTableProps) => {
  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <MetadataColumnProvider type="AssetTableDigestViewModel">
        {(columns) => (
          <StripedDataGrid
            apiRef={props.apiRef}
            density="compact"
            components={GridComponents}
            columns={columns}
            rows={props.data}
          />
        )}
      </MetadataColumnProvider>
    </Box>
  );
};

const GridComponents = {
  LoadingOverlay: LinearProgress,
  Toolbar: ToolbarWithTabViews("Summary-Assets"),
};
