import { Box, Link, Paper, Stack } from "@mui/material";
import { GridToolbarFilterButton, useGridApiRef } from "@mui/x-data-grid-pro";
import { Routes, formatRoutePath, useSiteContext } from "app";
import { ActionDispatcherProvider, useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import {
  createStaticActions,
  createStaticColumns,
  useColumns,
  useStaticActions,
} from "app/mui/tables/utils/createStaticColumns";
import { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { PageTitle } from "app/mui/common/headers/PageTitle";
import { ApplicationIcon } from "icons/ApplicationIcon";
import { ApplicationTabSelector } from "./ApplicationTabSelector";
import { useGetPackagedSolutionsRequest } from "packagedSolution/api";
import { PackagedSolutionModel } from "@bluemarvel/iris-common/model";
import { CreatePackagedSolutionDialog } from "./dialogs/CreatePackagedSolutionDialog";
import { PackagedSolutionIcon } from "icons/PackagedSolutionIcon";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";

const pageActions = createStaticActions((actionDispatcher, userPermissions) => [
  {
    icon: <PackagedSolutionIcon />,
    label: "Create Packaged Solution",
    action: () => actionDispatcher.dispatch(DialogAction.CreatePackagedSolution),
    hidden: !userPermissions.userCanEditPackagedSolution,
  },
]);

export const PackagedSolutionList = () => {
  const site = useSiteContext().currentSite!;
  const siteId = site.id;

  const packagedSolutionsRequest = useGetPackagedSolutionsRequest(siteId);

  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions, actionDispatcher);

  const [openDialog, setOpenDialog] = useState<DialogAction | null>(null);

  const handleAction = useCallback(
    (action: string) => {
      switch (action) {
        case DialogAction.CreatePackagedSolution:
          setOpenDialog(action);
          break;
      }
    },
    [packagedSolutionsRequest]
  );

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, [handleAction, actionDispatcher]);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const columnDef = createStaticColumns((actionDispatcher) => [
    // Title
    {
      field: "title",
      headerName: "Title",
      valueGetter: (params) => params.row.model.name,
      renderCell: (params) => {
        return (
          <Link
            color="secondary"
            component={RouterLink}
            to={formatRoutePath(Routes.PackagedSolution, params.row.model as PackagedSolutionModel)}
            sx={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
            state={{ returnTo: window.location.pathname }}
          >
            {params.row.model.name}
          </Link>
        );
      },
      flex: 2,
    },
    // Type
    {
      field: "type",
      headerName: "Type",
      type: "string",
      valueGetter: (params) => params.row.model.packagedSolutionType,
      width: 200,
    },
    // Status
    {
      field: "status",
      headerName: "Status",
      type: "string",
      valueGetter: (params) => params.row.model.packagedSolutionStatus,
      width: 200,
    },
    // Created Date
    {
      field: "created",
      headerName: "Created Date",
      type: "date",
      valueGetter: (params) => params.row.created,
      width: 200,
    },
    // Completed Date
    {
      field: "completed",
      headerName: "Completed Date",
      type: "date",
      valueGetter: (params) => params.row.completed,
      width: 200,
    },
    // Created By
    {
      field: "created by",
      headerName: "Created By",
      type: "string",
      valueGetter: (params) => params.row.createdBy.fullName,
      flex: 1,
    },
    // Completed By
    {
      field: "completed by",
      headerName: "Completed By",
      type: "string",
      valueGetter: (params) => params.row.completedBy?.fullName,
      flex: 1,
    },
  ]);

  const columns = useColumns(columnDef, actionDispatcher);
  const apiRef = useGridApiRef();

  return (
    <ActionDispatcherProvider actionDispatcher={actionDispatcher}>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <PageTitle title="Applications" icon={<ApplicationIcon />} />
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <ApplicationTabSelector />
            <ActionsDropdownMenu primary actions={actions} />
          </Stack>
          <Box sx={{ height: "700px", width: "100%" }}>
            <StripedDataGrid
              apiRef={apiRef}
              density="compact"
              loading={packagedSolutionsRequest.loading}
              columns={columns}
              components={{
                Toolbar: GridToolbarFilterButton,
              }}
              rows={packagedSolutionsRequest.data || []}
            />
          </Box>
        </Stack>
      </Paper>
      {openDialog === DialogAction.CreatePackagedSolution && <CreatePackagedSolutionDialog onClose={closeDialog} />}
    </ActionDispatcherProvider>
  );
};
