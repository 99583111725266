import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Paper, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { useGetRecordAssetSpecificationsRequest } from "records/api";
import { ValveSpecificationCard } from "assets/mui/sections/specifications/Valve";
import { Record } from "records/models";
import { useGetAssetsByIdsRequest } from "assets";
import { AccessoriesSpecificationCard } from "assets/mui/sections/specifications/Accessories";
import { ActuatorSpecificationCard } from "assets/mui/sections/specifications/Actuator";
import { ElementSpecificationCard } from "assets/mui/sections/specifications/Element";
import { MeasurementSpecificationCard } from "assets/mui/sections/specifications/Measurement";
import { PilotSpecificationCard } from "assets/mui/sections/specifications/Pilot";
import { RegulatorSpecificationCard } from "assets/mui/sections/specifications/Regulator";
import { SteamTrapSpecificationCard } from "assets/mui/sections/specifications/SteamTrap";
import { TankSpecificationCard } from "assets/mui/sections/specifications/Tank";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { useCallback, useEffect, useState } from "react";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";
import { AddSpecificationDialog } from "../dialogs/AddSpecificationDialog";
import { DeleteSpecification } from "../dialogs/DeleteSpecificationDialog";

const sectionActions = createStaticActions((actionDispatcher) => [
  {
    icon: <AddBoxOutlinedIcon />,
    label: "Add Specification",
    action: () => {
      actionDispatcher.dispatch(DialogAction.AddSpecification);
    },
  },
]);

interface RecordAssetSpecificationsProps {
  record: Record;
}

export const RecordAssetSpecifications = (props: RecordAssetSpecificationsProps) => {
  const assetIds = props.record.assets.map((a) => a.id);
  const { data: assets } = useGetAssetsByIdsRequest(assetIds);
  const { data: recordAssetSpecifications } = useGetRecordAssetSpecificationsRequest(props.record.id);

  const [addSpecificationDialog, setAddSpecificationDialog] = useState(false);
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(sectionActions, actionDispatcher);

  const handleAction = useCallback((action: string) => {
    if (action === DialogAction.AddSpecification) {
      setAddSpecificationDialog(true);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  return (
    <Paper>
      <ExpansionPanel title="SPECIFICATIONS" rightSideComponent={<ActionsDropdownMenu actions={actions} />}>
        <Box sx={{ p: 2 }}>
          {recordAssetSpecifications?.map((spec) => {
            const asset = assets?.find((a) => spec.assetId === a.id);
            return (
              asset && (
                <ExpansionPanel
                  title={asset.model.tag}
                  titleVariant="subtitle1"
                  key={asset.id}
                  rightSideComponent={<DeleteSpecification recordId={spec.recordId} assetId={spec.assetId} />}
                >
                  <Grid container spacing={2}>
                    {spec.valve && (
                      <ValveSpecificationCard
                        assetType={asset.model.assetType}
                        valve={spec.valve}
                        assetSpec={asset.valveSpecification}
                        recordId={props.record.id}
                      />
                    )}

                    {spec.actuator && (
                      <ActuatorSpecificationCard
                        assetType={asset.model.assetType}
                        actuator={spec.actuator}
                        assetSpec={asset.actuatorSpecification}
                        recordId={props.record.id}
                      />
                    )}
                    {spec.accessory && (
                      <AccessoriesSpecificationCard
                        assetType={asset.model.assetType}
                        accessory={spec.accessory}
                        assetSpec={asset.accessorySpecification}
                        recordId={props.record.id}
                      />
                    )}

                    {spec.regulator && (
                      <RegulatorSpecificationCard
                        assetType={asset.model.assetType}
                        regulator={spec.regulator}
                        assetSpec={asset.regulatorSpecification}
                        recordId={props.record.id}
                      />
                    )}
                    {spec.pilot && (
                      <PilotSpecificationCard
                        assetType={asset.model.assetType}
                        pilot={spec.pilot}
                        assetSpec={asset.pilotSpecification}
                        recordId={props.record.id}
                      />
                    )}
                    {spec.measurement && (
                      <MeasurementSpecificationCard
                        assetType={asset.model.assetType}
                        measurement={spec.measurement}
                        assetSpec={asset.measurementSpecification}
                        recordId={props.record.id}
                      />
                    )}
                    {spec.element && (
                      <ElementSpecificationCard
                        assetType={asset.model.assetType}
                        element={spec.element}
                        assetSpec={asset.elementSpecification}
                        recordId={props.record.id}
                      />
                    )}
                    {spec.steamTrap && (
                      <SteamTrapSpecificationCard
                        assetType={asset.model.assetType}
                        steamTrap={spec.steamTrap}
                        assetSpec={asset.steamTrapSpecification}
                        recordId={props.record.id}
                      />
                    )}
                    {spec.tank && (
                      <TankSpecificationCard
                        assetType={asset.model.assetType}
                        tank={spec.tank}
                        assetSpec={asset.tankSpecification}
                        recordId={props.record.id}
                      />
                    )}
                  </Grid>
                </ExpansionPanel>
              )
            );
          })}
        </Box>
      </ExpansionPanel>
      {addSpecificationDialog && (
        <AddSpecificationDialog record={props.record} onClose={() => setAddSpecificationDialog(false)} />
      )}
    </Paper>
  );
};
