import { FC, useCallback, useEffect, useState } from "react";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { EditFieldDialog } from "../../app/mui/common/dialogs/EditFieldDialog";
import { useForm } from "react-hook-form";
import { useUserContext } from "app/contexts/UserContext";
import { PackagedSolutionDetailViewModel } from "@bluemarvel/iris-common/model";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import EditIcon from "@mui/icons-material/Edit";
import {
  usePutCompletePackagedSolutionRequest,
  usePutReopenPackagedSolutionRequest,
  useUpdatePackageSolutionsRequest,
} from "packagedSolution/api";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { RemovePackagedSolutionDialog } from "./dialogs/RemovePackagedSolutionDialog";
import { CreatePackagedSolutionSummaryDialog } from "./dialogs/CreatePackagedSolutionSummaryDialog";
import { SummaryIcon } from "icons/SummaryIcon";
import { CompletePackagedSolutionIcon } from "icons/CompletePackagedSolutionIcon";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";

interface IPackagedSolutionActionsProps {
  packagedSolutionDetail: PackagedSolutionDetailViewModel;
}

const pageActions = (packagedSolution: PackagedSolutionDetailViewModel) =>
  createStaticActions((actionDispatcher, userPermissions) => [
    {
      icon: <EditIcon />,
      label: "Edit name",
      action: () => actionDispatcher.dispatch(DialogAction.EditPackagedSolutionName),
      hidden:
        !userPermissions.userCanEditPackagedSolution || packagedSolution.model.packagedSolutionStatus !== "In progress",
    },
    {
      icon: <CompletePackagedSolutionIcon />,
      label: "Complete Packaged Solution",
      action: () => actionDispatcher.dispatch("completePackagedSolution"),
      hidden:
        !userPermissions.userCanEditPackagedSolution || packagedSolution.model.packagedSolutionStatus !== "In progress",
    },
    {
      icon: <TaskOutlinedIcon />,
      label: "Reopen Packaged Solution",
      action: () => actionDispatcher.dispatch("reopenPackagedSolution"),
      hidden:
        !userPermissions.userCanEditPackagedSolution || packagedSolution.model.packagedSolutionStatus !== "Completed",
    },
    {
      icon: <SummaryIcon />,
      label: "Create Summary",
      action: () => actionDispatcher.dispatch(DialogAction.CreateSummary),
      hidden:
        !userPermissions.userCanEditPackagedSolution || packagedSolution.model.packagedSolutionStatus !== "Completed",
    },
    {
      icon: <DeleteForeverRoundedIcon />,
      label: "Delete",
      action: () => actionDispatcher.dispatch(DialogAction.DeletePackagedSolutionName),
      hidden:
        !userPermissions.userCanEditPackagedSolution || packagedSolution.model.packagedSolutionStatus !== "In progress",
    },
  ]);

export const PackagedSolutionActions: FC<IPackagedSolutionActionsProps> = (props) => {
  const { packagedSolutionDetail } = props;
  const packagedSolution = packagedSolutionDetail.model;
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(packagedSolutionDetail), actionDispatcher, [
    packagedSolution.packagedSolutionStatus,
  ]);
  const { userPermissions } = useUserContext();
  const showActions = userPermissions.userCanEditRecords;
  const defaultValues = makeSafeForReactHookForm(packagedSolution);

  const updatePackagedSolutionName = useUpdatePackageSolutionsRequest();
  const putCompletePackagedSolutionRequest = usePutCompletePackagedSolutionRequest();
  const putReopenPackagedSolutionRequest = usePutReopenPackagedSolutionRequest();
  const methods = useForm({ defaultValues });
  const updateName = () => {
    methods.handleSubmit((values) => {
      updatePackagedSolutionName.call({ ...packagedSolution, name: values.name }).then(() => {
        closeDialog();
      });
    })();
  };

  const handleAction = useCallback((action: string) => {
    switch (action) {
      case DialogAction.EditPackagedSolutionName:
        setOpenDialog(action);
        break;
      case DialogAction.CreateSummary:
        setOpenDialog(action);
        break;
      case DialogAction.DeletePackagedSolutionName:
        setOpenDialog(action);
        break;
      case "completePackagedSolution":
        putCompletePackagedSolutionRequest.call({ packagedSolutionId: packagedSolution.id });
        break;
      case "reopenPackagedSolution":
        putReopenPackagedSolutionRequest.call({ packagedSolutionId: packagedSolution.id });
        break;
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const [openDialog, setOpenDialog] = useState<DialogAction | null>(null);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  return (
    <>
      {showActions && <ActionsDropdownMenu primary actions={actions} />}

      {openDialog === DialogAction.EditPackagedSolutionName && (
        <EditFieldDialog
          methods={methods}
          saveChanges={updateName}
          fieldName="name"
          dialogTitle="Change Packaged Solution name"
          label="Packaged Solution Name"
          confirmButtonText="Change name"
          onClose={closeDialog}
        />
      )}
      {openDialog === DialogAction.CreateSummary && (
        <CreatePackagedSolutionSummaryDialog onClose={closeDialog} packageSolution={packagedSolution} />
      )}
      {openDialog === DialogAction.DeletePackagedSolutionName && (
        <RemovePackagedSolutionDialog open={true} onClose={closeDialog} packageSolution={packagedSolution} />
      )}
    </>
  );
};
