import { Stack } from "@mui/material";
import { useGetReleaseNotesRequest } from "../api";
import { ReleaseNotesListItem } from "./sections/components/ReleaseNotesListItem";
import { useEffect } from "react";

export interface ReleaseNotesListProps {
  updateCurrentVersion: (string) => void;
}

export const ReleaseNotesList = (props: ReleaseNotesListProps) => {
  const releaseNotesRequest = useGetReleaseNotesRequest();
  const releaseNotes = releaseNotesRequest.data || [];

  useEffect(() => {
    if (releaseNotes.length > 0) {
      const latest = releaseNotes.find((releaseNote) => releaseNote.isLatest);
      if (latest) {
        // Remember the largest version number for use in create release note dialog
        props.updateCurrentVersion(latest.versionNumber);
      }
    }
  }, [releaseNotes]);

  return (
    <Stack spacing={2}>
      {releaseNotes.map((releaseNote) => (
        <ReleaseNotesListItem key={releaseNote.id} releaseNotesListViewModel={releaseNote}></ReleaseNotesListItem>
      ))}
    </Stack>
  );
};
