import { GeneralSubtype, GeneralSubtypeEnum, generalSubtypesWithEquipmentTypes } from "@bluemarvel/iris-common/model";
import { AssetType } from "./models/assetType";

const valveTypes = [
  AssetType.ControlValve,
  AssetType.IsolationValve,
  AssetType.ManualValve,
  AssetType.ReliefValve,
  AssetType.MotorOperatedValve,
];
const advancedValveTypes = [
  AssetType.ControlValve,
  AssetType.IsolationValve,
  AssetType.ManualValve,
  AssetType.MotorOperatedValve,
];
const automatedValveTypes = [AssetType.ControlValve, AssetType.IsolationValve, AssetType.MotorOperatedValve];

export function assetTypeIsValve(assetType: AssetType | null | undefined) {
  return assetType && valveTypes.includes(assetType);
}

export function assetTypeIsAdvancedValve(assetType: AssetType | null | undefined) {
  return assetType && advancedValveTypes.includes(assetType);
}

export function assetTypeIsAutomatedValve(assetType: AssetType | null | undefined) {
  return assetType && automatedValveTypes.includes(assetType);
}

export function checkAssetType(assetType: AssetType | null | undefined) {
  return {
    isValve: assetTypeIsValve(assetType),
    isAdvancedValve: assetTypeIsAdvancedValve(assetType),
    isAutomatedValve: assetTypeIsAutomatedValve(assetType),
    isControlValve: assetType === AssetType.ControlValve,
    isIsolationValve: assetType === AssetType.IsolationValve,
    isManualValve: assetType === AssetType.ManualValve,
    isReliefValve: assetType === AssetType.ReliefValve,
    isRegulator: assetType === AssetType.Regulator,
    isMeasurement: assetType === AssetType.Measurement,
    isMachinery: assetType === AssetType.Machinery,
    isSteamTrap: assetType === AssetType.SteamTrap,
    isTank: assetType === AssetType.Tank,
    isGeneral: assetType === AssetType.General,
    isMotorOperatedValve: assetType === AssetType.MotorOperatedValve,
  };
}

export const getSubtypeOptions = () =>
  GeneralSubtypeEnum.options.map((generalSubtype) => ({ id: generalSubtype, label: generalSubtype }));

export const getEquipmentTypeOptions = (generalSubtype: GeneralSubtype) => {
  const types = generalSubtypesWithEquipmentTypes[generalSubtype];
  return types.map((type) => ({ id: type, label: type }));
};
