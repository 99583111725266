export enum DialogAction {
  AddRecommendation = "addRecommendation",
  AddSpecification = "addSpecification",
  AddEquipment = "AddEquipment",
  EditEquipment = "EditEquipment",
  RemoveEquipment = "RemoveEquipment",
  AssignKpi = "assignKpi",
  CreateRecord = "createRecord",
  CloseRecord = "closeRecord",
  CreateRecordSummary = "createRecordSummary",
  CreateBulkValveHealthSummary = "createBulkValveHealthSummary",
  CreateBulkVibrationHealthSummary = "createBulkVibrationHealthSummary",
  CreateFieldInspectionSummary = "createFieldInspectionSummary",
  GenerateReport = "generateReport",
  DuplicateRecord = "duplicateRecord",
  DownloadCSV = "downloadCSV",
  ExportCurrentViewCSV = "exportCurrentViewCSV",
  BulkAttachmentExport = "bulkAttachmentExport",
  AddCalibration = "addCalibration",
  CreateReleaseNotes = "createReleaseNotes",
  CreateTutorial = "createTutorial",
  CreateReleaseNotesFeature = "createReleaseNotesFeature",
  CreateReleaseNotesBug = "createReleaseNotesBug",
  DeleteReleaseNotesFeature = "deleteReleaseNotesFeature",
  DeleteReleaseNotesBug = "deleteReleaseNotesBug",
  TogglePublishReleaseNotes = "togglePublishReleaseNotes",
  RenameReleaseNotes = "renameReleaseNotes",
  DeleteReleaseNotes = "deleteReleaseNotes",
  DeleteSpecification = "deleteSpecification",
  DeleteTutorials = "deleteTutorials",
  TogglePublishTutorials = "togglePublishTutorials",
  RenameTutorials = "renameTutorials",
  CreatePackagedSolution = "createPackagedSolution",
  EditPackagedSolutionName = "editPackagedSolutionName",
  DeletePackagedSolutionName = "deletePackagedSolutionName",
  CreateSummary = "CreateSummary",
}
