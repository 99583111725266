import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { useNavigate } from "react-router-dom";
import { formatIdRoutePath, Routes } from "app";
import { useCreateReleaseNotesRequest } from "../../api";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";

interface CreateReleaseNotesDialogProps extends BaseDialogProps {
  currentVersion: string | null;
}

const validationSchema = yup.object({
  title: yup.string().required("required"),
  versionNumber: yup.string().required("required"),
  releaseDate: yup.date().required("required"),
});

export const CreateReleaseNotesDialog = (props: CreateReleaseNotesDialogProps) => {
  const navigate = useNavigate();

  const createReleaseNotesRequest = useCreateReleaseNotesRequest();
  const methods = useForm({ resolver: yupResolver(validationSchema) });

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      createReleaseNotesRequest
        .call({
          title: values.title,
          summary: "",
          versionNumber: values.versionNumber,
          releaseDate: values.releaseDate,
        })
        .then((ReleaseNotes) => {
          navigate(formatIdRoutePath(Routes.ReleaseNote, ReleaseNotes.id));
        });
    })();
  };

  const handleClose = () => {
    methods.reset();
    props.onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Create Release Note
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "250px" }}>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <Stack spacing={1}>
              <TextFieldFormItem label="Title" fieldName="title" />
              <TextFieldFormItem
                label="Version Number"
                rightSideLabel={`Lastest Version Saved: ${props.currentVersion ?? "None"}`}
                fieldName="versionNumber"
              />
              <DateInputFormItem label="Release Date" fieldName="releaseDate"></DateInputFormItem>
            </Stack>
          </form>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button
            onClick={onSubmit}
            type="submit"
            variant="contained"
            color="secondary"
            disabled={createReleaseNotesRequest.loading}
          >
            Create and Open
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
