import { TextField, TextFieldProps, useFormControl } from "@mui/material";
import { FormItemLayout } from "app/mui/forms/FormItemLayout";
import * as React from "react";
import { RegisterOptions } from "react-hook-form";
import { BaseFormItemProps } from "../Base/BaseFormItemProps";
import { ReadonlyFormItem } from "../ReadonlyFormItem";
import { withEditableToggle } from "../withEditableToggle";
import { Property } from "csstype";

export interface TextFieldFormItemProps extends BaseFormItemProps {
  textFieldProps?: TextFieldProps;
  formFieldProps?: RegisterOptions<any, any>;
  children?: React.ReactNode;
  backgroundColor?: Property.BackgroundColor;
}

export const TextFieldFormItem = (props: TextFieldFormItemProps) => {
  const control = useFormControl();
  return props.readOnly ? (
    <ReadonlyFormItem label={props.label || ""} fieldName={props.fieldName} />
  ) : (
    <FormItemLayout
      fieldName={props.fieldName}
      label={props.label}
      rightSideLabel={props.rightSideLabel}
      hint={props.hint}
      formFieldProps={props.formFieldProps}
    >
      {(inputProps) => (
        <>
          <TextField
            {...props.textFieldProps}
            {...inputProps}
            error={control?.error}
            sx={{ backgroundColor: props.backgroundColor || "transparent" }}
          />
          {props.children}
        </>
      )}
    </FormItemLayout>
  );
};

export const EditableTextFieldFormItem = withEditableToggle(TextFieldFormItem);
