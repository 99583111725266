import { Box, FormControl, FormControlProps, Stack, StackProps, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { IrisColors } from "../theme";
import { useFormEditPermission } from "./PermissionAwareFormProvider";
import FormItemLabel from "./FormItemLabel";

export type FormItemLayoutVariant = "unstyled" | "noError" | "default";

export interface FormItemLayoutProps {
  label?: string | null;
  rightSideLabel?: string | null;
  hint?: string;
  tip?: React.ReactNode | null;
  error?: string;
  fieldName?: string;
  formFieldProps?: RegisterOptions<any, any>;
  children: (props) => React.ReactNode;
  controlled?: boolean;
  variant?: FormItemLayoutVariant;
  formControlProps?: Omit<FormControlProps, "fullWidth"> & { placeholder?: string };
  fullWidth?: boolean;
}

const getStyleProps = (variant: FormItemLayoutVariant) => {
  const defaultBorderAndColorOverrides = {
    backgroundColor: IrisColors.gray100,
    "& fieldset": {
      borderColor: IrisColors.gray300,
    },
    "&:hover fieldset": {
      borderColor: IrisColors.gray500,
    },
    "&.Mui-focused fieldset": {
      borderColor: IrisColors.blueSpartan,
    },
  };
  const disabledBorderAndColorOverrides = {
    color: IrisColors.gray600,
    backgroundColor: IrisColors.gray300,
    "& fieldset": {
      borderColor: IrisColors.gray300,
    },
  };
  const errorBorderAndColorOverrides = {
    "& fieldset": {
      borderColor: IrisColors.red,
    },
  };

  if (variant === "unstyled") {
    return undefined;
  }
  return {
    // this applies to text fields + autocomplete fields
    "&.MuiTextField-root .MuiOutlinedInput-root": defaultBorderAndColorOverrides,
    "&.MuiTextField-root .MuiOutlinedInput-root.Mui-error":
      variant === "noError" ? defaultBorderAndColorOverrides : errorBorderAndColorOverrides,
    "&.MuiTextField-root .MuiOutlinedInput-root.Mui-disabled": disabledBorderAndColorOverrides,
    // this applies to basic select fields
    "&.MuiOutlinedInput-root": defaultBorderAndColorOverrides,
    "&.MuiOutlinedInput-root.Mui-error":
      variant === "noError" ? defaultBorderAndColorOverrides : errorBorderAndColorOverrides,
    "&.MuiOutlinedInput-root.Mui-disabled": disabledBorderAndColorOverrides,
  };
};

export const formItemLayoutStackProps: StackProps = {
  spacing: 0.5,
  width: "100%",
  justifyContent: "flex-start",
  display: "flex",
};

/**
 * This component creates the layout of a single form field item.
 * It renders a label, hint, and error message, and an arbitrary input
 * supplied via the child render function.
 *
 * Label can be kept undefined to remove it, or passed as null or empty string
 * to ensure consistent padding when placed next to other controls.
 *
 * Similarly to label, tip can be provided as null to enforce consistent padding,
 * or undefined to remove it.
 *
 * It is designed to be used with mui components, and react-hook-form.
 *
 * It should only be used inside a form provider context.
 */
export const FormItemLayout = (props: FormItemLayoutProps) => {
  const {
    controlled = false,
    label,
    rightSideLabel,
    hint,
    tip,
    error,
    fieldName,
    formFieldProps,
    children,
    variant = "default",
    formControlProps,
    fullWidth = true,
  } = props;

  const { register, formState } = useFormContext();
  const { hasPermissionToEdit } = useFormEditPermission();

  // if controlled flag is set, we don't need to register the field with react-hook-form
  // as that will happen automatically.
  const shouldRegisterField = fieldName && !controlled;
  const connectedFieldProps = shouldRegisterField ? register(fieldName!, formFieldProps) : {};

  const isDisabled = useMemo(() => formControlProps?.disabled || !hasPermissionToEdit, [formControlProps?.disabled]);

  const styles = useMemo(() => getStyleProps(variant), [variant, formControlProps?.disabled]);

  const formItemError = error || (fieldName ? formState.errors[fieldName]?.message?.toString() : undefined);

  const childrenProps = React.useMemo(() => {
    return {
      ...connectedFieldProps,
      disabled: isDisabled,
      size: "small",
      margin: "none",
      sx: styles,
    };
  }, [fieldName, formFieldProps, isDisabled, styles, formItemError]);

  return (
    <Stack {...formItemLayoutStackProps}>
      <Stack direction="row" justifyContent={"space-between"} sx={{ width: "100%" }}>
        <FormItemLabel label={label} />
        <FormItemLabel label={rightSideLabel} />
      </Stack>
      <Stack direction="row" alignItems="center" spacing={2} width="100%" justifyContent="flex-start">
        <FormControl {...formControlProps} fullWidth={fullWidth} error={!!formItemError}>
          {children(childrenProps)}
        </FormControl>
        {tip !== undefined && <Box style={{ minWidth: "24px" }}>{tip}</Box>}
      </Stack>
      {hint && (
        <Typography variant="caption" sx={{ color: IrisColors.legacyGray600 }}>
          {hint}
        </Typography>
      )}
      {formItemError && (
        <Typography variant="caption" sx={{ color: "error.main" }}>
          {formItemError}
        </Typography>
      )}
    </Stack>
  );
};
