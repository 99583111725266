import DesignServicesIcon from "@mui/icons-material/DesignServices";
import { Box, Paper, Stack } from "@mui/material";
import { ReleaseNotesDetailViewModel } from "@bluemarvel/iris-common/model";
import { PageTitle } from "app/mui/common/headers/PageTitle";
import { ReleaseNotesIcon } from "icons/ReleaseNotesIcon";
import { ReleaseNotesHeaderActions } from "../actions/ReleaseNotesHeaderActions";
import { LastModified } from "app/mui/LastModified";
import { Badge } from "app/mui/Badge";
import { ReleaseNotesBadge } from "./components/ReleaseNotesBadge";
import { useUpdateReleaseNotesRequest } from "../../api";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import { User } from "users";

interface ReleaseNotesDetailHeaderProps {
  releaseNotesDetail: ReleaseNotesDetailViewModel;
}

export const ReleaseNotesHeader = (props: ReleaseNotesDetailHeaderProps) => {
  const { releaseNotesDetail } = props;
  const releaseNotesModel = releaseNotesDetail.model;
  const updateReleaseNote = useUpdateReleaseNotesRequest();
  const defaultValues = makeSafeForReactHookForm(releaseNotesModel);
  const methods = useForm({ defaultValues });

  const updateTitle = (onValid?: () => void) => {
    methods.handleSubmit((values) => {
      updateReleaseNote.call({ ...releaseNotesModel, title: values.title }).then(() => onValid?.());
    })();
  };

  const togglePublish = async (closeDialog: () => void) => {
    updateReleaseNote
      .call({ ...releaseNotesModel, isPublished: !releaseNotesModel.isPublished })
      .finally(() => closeDialog());
  };

  return (
    <Paper>
      <Box sx={{ p: 2 }}>
        <Stack direction="row" justifyContent="space-between" pb={2}>
          <Stack direction="column" spacing={1}>
            <PageTitle title={releaseNotesDetail.title} icon={<ReleaseNotesIcon />} />
            <Stack direction="row" spacing={2} alignItems="center" pb={0}>
              <ReleaseNotesBadge />
              {!releaseNotesDetail.isPublished && <Badge text="Draft" icon={<DesignServicesIcon />}></Badge>}
            </Stack>
            <Stack direction="row" flexWrap="wrap" pb={0}></Stack>
          </Stack>
          <Stack spacing={2} alignItems="flex-end">
            <Stack direction="column" alignItems="flex-end" spacing={2}>
              <Stack>
                <ReleaseNotesHeaderActions
                  releaseNotesDetail={releaseNotesDetail}
                  togglePublish={togglePublish}
                  updateTitle={updateTitle}
                  methods={methods}
                />
              </Stack>

              <Stack>
                <LastModified
                  lastModifiedBy={releaseNotesDetail.lastModifiedBy as User}
                  lastModified={releaseNotesDetail.lastModified}
                />
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </Paper>
  );
};
