import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Grid, IconButton, Stack } from "@mui/material";
import { Routes, useUserContext } from "app";
import { useMatch, useNavigate } from "react-router-dom";
import { useGetTutorialsDetailRequest } from "../api";
import { EntityDetailsContainerSkeleton } from "app/mui/common/skeletons/EntityDetailsContainerSkeleton";
import { TutorialsHeader } from "./sections/TutorialsHeader";
import { TutorialsDetail } from "./sections/TutorialsDetail";
import { useGetAttachmentsRequest } from "attachments/api";
import { AttachmentModel, TutorialsDetailViewModel } from "@bluemarvel/iris-common/model";
import { TutorialsAttachments } from "./components/TutorialsAttachments";
import { TutorialsReadOnlyView } from "./sections/TutorialsReadOnlyView";

export const TutorialsDetailsPage = () => {
  const routeMatch = useMatch(Routes.Tutorial);
  const tutorialId = Number(routeMatch?.params?.id);
  const { data: tutorialsDetail, loading: loadingTutorials } = useGetTutorialsDetailRequest(tutorialId);
  const { data: tutorialAttachments } = useGetAttachmentsRequest("Tutorial", tutorialId);

  return loadingTutorials || !tutorialsDetail ? (
    <EntityDetailsContainerSkeleton />
  ) : (
    <TutorialsDetailsPageWithForm tutorialsDetail={tutorialsDetail} tutorialAttachments={tutorialAttachments || []} />
  );
};

interface TutorialsDetailsPageWithFormProps {
  tutorialsDetail: TutorialsDetailViewModel;
  tutorialAttachments: AttachmentModel[];
}

export const TutorialsDetailsPageWithForm = (props: TutorialsDetailsPageWithFormProps) => {
  const { tutorialsDetail, tutorialAttachments } = props;
  const { userPermissions } = useUserContext();
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(Routes.Help);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={1} lg={1} xl={1} alignContent={"right"}>
        <div style={{ width: "100%", textAlign: "right", paddingTop: "0px", position: "relative", right: "-10px" }}>
          <IconButton size="small" onClick={() => handleNavigation()} color="info">
            <ArrowBackIosNewIcon></ArrowBackIosNewIcon>&nbsp;Help
          </IconButton>
        </div>
      </Grid>

      <Grid item xs={7} lg={7} xl={7}>
        <Stack direction="column" spacing={2}>
          <TutorialsHeader tutorialsDetail={tutorialsDetail} />
          {userPermissions.userCanEditReleaseNotes ? (
            <TutorialsDetail tutorialsDetail={tutorialsDetail} tutorialAttachments={tutorialAttachments} />
          ) : (
            <TutorialsReadOnlyView tutorialsDetail={tutorialsDetail} />
          )}
        </Stack>
      </Grid>
      <Grid item xs={3} lg={3} xl={3}>
        <TutorialsAttachments tutorialsDetail={tutorialsDetail} tutorialAttachments={tutorialAttachments} />
      </Grid>
    </Grid>
  );
};
