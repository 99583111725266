import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, Typography } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { useCreateAssetRequest } from "assets/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import SplitButton from "app/mui/SplitButton";
import { useNavigate } from "react-router-dom";
import { formatRoutePath, Routes, useSiteContext, useUserContext } from "app";
import { AssetType, toAssetTypeDisplay } from "assets/models/assetType";
import { GeneralSubtypeEnum } from "@bluemarvel/iris-common/model";
import { useEffect } from "react";
import { FeatureFlagName, useFeatureEnabled } from "common/models";
import { getEquipmentTypeOptions, getSubtypeOptions } from "assets/utilities";

interface CreateAssetDialogProps extends BaseDialogProps {}

const validationSchema = yup.object({
  assetType: yup.string().nullable().required("required"),
  tag: yup.string().nullable().required("required"),
  generalSubtype: yup
    .string()
    .nullable()
    .when("assetType", {
      is: AssetType.General,
      then: yup.string().required("required"),
      otherwise: yup.string().notRequired(),
    }),

  subtypeDescription: yup
    .string()
    .nullable()
    .when("generalSubtype", {
      is: "Other",
      then: yup.string().required("required"),
      otherwise: yup.string().notRequired(),
    }),
  equipmentType: yup
    .string()
    .nullable()
    .when("assetType", {
      is: AssetType.General,
      then: yup
        .string()
        .nullable()
        .when("generalSubtype", {
          is: "Other",
          then: yup.string().nullable(),
          otherwise: yup.string().required("required"),
        }),
      otherwise: yup.string().nullable(),
    }),
  equipmentTypeDescription: yup
    .string()
    .nullable()
    .when(["generalSubtype", "equipmentType"], {
      is: (generalSubtype, equipmentType) => generalSubtype !== "Other" && equipmentType === "Other",
      then: yup.string().required("required"),
      otherwise: yup.string().notRequired(),
    }),
});

export const CreateAssetDialog = (props: CreateAssetDialogProps) => {
  const navigate = useNavigate();
  const { currentSiteWithNavigation } = useSiteContext();
  const { userPermissions } = useUserContext();

  const createAssetRequest = useCreateAssetRequest();

  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const assetType = methods.watch("assetType");
  const generalSubtype = methods.watch("generalSubtype");
  const equipmentType = methods.watch("equipmentType");

  const isGeneral = assetType === AssetType.General;
  const isSubtypeOther = generalSubtype === "Other";
  const isEquipmentOther = equipmentType === "Other";
  const isGeneralAssetEnabled = useFeatureEnabled(FeatureFlagName.UseGeneralAssets);

  const getAssetTypes = () => {
    const assetTypes = [] as AssetType[];
    const instrumentationAssetTypes = [
      AssetType.ControlValve,
      AssetType.IsolationValve,
      AssetType.ManualValve,
      AssetType.Measurement,
      AssetType.ReliefValve,
      AssetType.Regulator,
      AssetType.SteamTrap,
      AssetType.Tank,
      AssetType.MotorOperatedValve,
    ];

    if (isGeneralAssetEnabled) {
      instrumentationAssetTypes.push(AssetType.General);
    }

    if (userPermissions.userCanAddAnyAsset) {
      assetTypes.push(...instrumentationAssetTypes, AssetType.Machinery);
    } else {
      if (userPermissions.userCanAddMachineryAsset) {
        assetTypes.push(AssetType.Machinery);
      }

      if (userPermissions.userCanAddInstrumentationAsset) {
        assetTypes.push(...instrumentationAssetTypes);
      }
    }

    return assetTypes.sort().map((a) => ({ id: a, label: toAssetTypeDisplay(a) }));
  };

  const onSubmit = (options?: { navigate?: boolean }) => {
    if (!currentSiteWithNavigation) return;

    methods.handleSubmit((values) => {
      createAssetRequest
        .call({
          siteId: currentSiteWithNavigation.id,
          assetType: values.assetType,
          tag: values.tag,
          serviceDescription: values.serviceDescription,
          generalSubtype: isGeneral ? values.generalSubtype : null,
          subtypeDescription:
            isGeneral && values.generalSubtype === GeneralSubtypeEnum.enum.Other ? values.subtypeDescription : null,
          equipmentType: isGeneral && !isSubtypeOther ? values.equipmentType : null,
          equipmentTypeDescription:
            isGeneral && !isSubtypeOther && isEquipmentOther ? values.equipmentTypeDescription : null,
        })
        .then((newAsset) => {
          if (options?.navigate) {
            navigate(formatRoutePath(Routes.Asset, newAsset));
          } else {
            props.onClose();
            window.location.reload();
          }
        });
    })();
  };

  useEffect(() => {
    if (generalSubtype) {
      methods.setValue("equipmentType", null);
    }
  }, [generalSubtype]);

  const handleClose = () => {
    methods.reset();
    props.onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        <Typography variant="h6">Create asset</Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "300px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <AutocompleteFormItem
              autocompleteProps={{ disablePortal: false }}
              label="Asset type"
              fieldName="assetType"
              options={getAssetTypes()}
            />
            {isGeneral && (
              <AutocompleteFormItem
                autocompleteProps={{ disablePortal: false }}
                label="Subtype"
                fieldName="generalSubtype"
                options={getSubtypeOptions()}
                autoDefault={false}
              />
            )}
            {isGeneral && isSubtypeOther && (
              <TextFieldFormItem label="Subtype Description" fieldName="subtypeDescription" />
            )}
            {isGeneral && !isSubtypeOther && generalSubtype && (
              <>
                <AutocompleteFormItem
                  label="Equipment Type"
                  fieldName="equipmentType"
                  options={getEquipmentTypeOptions(generalSubtype)}
                  autoDefault={false}
                />
                {isEquipmentOther && (
                  <TextFieldFormItem label="Equipment Type Description" fieldName="equipmentTypeDescription" />
                )}
              </>
            )}
            <TextFieldFormItem label="Tag" fieldName="tag" />
            <TextFieldFormItem
              label="Service description"
              fieldName="serviceDescription"
              textFieldProps={{ rows: 3 }}
            />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <SplitButton
            buttonGroupProps={{ variant: "contained" }}
            primaryButton={<Button onClick={() => onSubmit({ navigate: true })}>Create And Open</Button>}
            additionalActions={[<MenuItem onClick={() => onSubmit()}>Create</MenuItem>]}
          />
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
