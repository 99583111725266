import { Badge } from "app/mui/Badge";
import GppMaybeOutlinedIcon from "@mui/icons-material/GppMaybeOutlined";
import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import ImportContactsRoundedIcon from "@mui/icons-material/ImportContactsRounded";

interface TutorialsBadgeProps {
  label?: string;
}

export const TutorialsBadge = ({ label = "Tutorial", ...badgeProps }: TutorialsBadgeProps) => {
  const renderIcon = () => {
    switch (label) {
      case "Best Practices":
        return <WorkspacePremiumOutlinedIcon color="secondary" sx={{ height: 20 }} />;
      case "How-To":
        return <ImportContactsRoundedIcon color="secondary" sx={{ height: 20 }} />;
      default:
        return <GppMaybeOutlinedIcon color="secondary" sx={{ height: 20 }} />;
    }
  };

  return <Badge text={label} icon={renderIcon()} color="#3b75a7" {...badgeProps} />;
};
