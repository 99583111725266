import { useCallback, useEffect, useState } from "react";
import { Box, LinearProgress, Stack } from "@mui/material";
import { useGetRecordDetailsByFilterRequest } from "records/api";
import { useSiteContext } from "app";
import { gridFilteredSortedRowEntriesSelector, GridValidRowModel } from "@mui/x-data-grid";
import { gridFilteredSortedRowIdsSelector, useGridApiRef } from "@mui/x-data-grid-pro";
import { RecordDetailView, RecordFilters, RecordType } from "records/models";
import { MetadataColumnProvider } from "app/mui/tables/metadata";
import DownloadIcon from "@mui/icons-material/Download";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import AddchartIcon from "@mui/icons-material/Addchart";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import {
  createStaticActions,
  createStaticRowActions,
  useStaticActions,
  useStaticRowActions,
} from "app/mui/tables/utils/createStaticColumns";
import { ActionDispatcherProvider, useActionDispatcher, useActionDispatcherContext } from "app/mui/ActionDispatcher";
import { useDownloadCsvExportRequest } from "importCsv/api";
import { ToolbarWithTabViews } from "app/mui/tables/ToolbarWithTabViews";
import { CreateRecordDialog } from "assets/mui/dialogs/CreateRecord";
import { BusinessAreaContextProvider, useBusinessArea } from "app/mui/BusinessAreaContext";
import { SummaryIcon } from "icons/SummaryIcon";
import { CreateRecordSummaryDialog } from "records/mui/dialogs/CreateRecordSummary";
import { CreateBulkHealthSummaryDialog } from "records/mui/dialogs/CreateBulkHealthSummary";
import { GenerateReportDialog } from "records/mui/dialogs/GenerateReport";
import { CloseReopenRecordDialog } from "records/mui/dialogs/CloseRecord";
import { DuplicateRecordDialog } from "records/mui/dialogs/DuplicateRecord";
import { AssignKpiTemplateDialog } from "records/mui/dialogs/AssignKpiTemplate";
import { RecordsListDataViz } from "./visualizations/RecordsListDataViz";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import moment from "moment";
import { Site } from "sites/models";
import { CreateFieldInspectionSummaryDialog } from "records/mui/dialogs/CreateFieldInspectionSummary";
import { dateFormat, FeatureFlagName, useFeatureEnabled } from "common";
import { useExportRecordAttachmentsRequest } from "attachments/api";
import { SummaryType } from "summaries";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";

const pageActions = createStaticActions((actionDispatcher, userPermissions) => {
  const useFieldInspectionSummary = useFeatureEnabled(FeatureFlagName.UseFieldInspectionSummary);
  const useBulkVibrationSummary = useFeatureEnabled(FeatureFlagName.UseBulkVibrationSummary);
  return [
    {
      icon: <CreateNewFolderRoundedIcon />,
      label: "Create record",
      action: () => actionDispatcher.dispatch(DialogAction.CreateRecord),
      hidden: !userPermissions.userCanEditRecords,
    },
    {
      icon: <SummaryIcon />,
      label: "Create general summary",
      action: () => actionDispatcher.dispatch(DialogAction.CreateRecordSummary),
      hidden: !userPermissions.userCanEditSummary,
    },
    {
      icon: <SummaryIcon />,
      label: "Create bulk valve health summary",
      action: () => actionDispatcher.dispatch(DialogAction.CreateBulkValveHealthSummary),
      hidden: !userPermissions.userCanEditSummary,
    },
    {
      icon: <SummaryIcon />,
      label: "Create bulk vibration health summary",
      action: () => actionDispatcher.dispatch(DialogAction.CreateBulkVibrationHealthSummary),
      hidden: !useBulkVibrationSummary || !userPermissions.userCanEditSummary,
    },
    {
      icon: <SummaryIcon />,
      label: "Create field inspection summary",
      action: () => actionDispatcher.dispatch(DialogAction.CreateFieldInspectionSummary),
      hidden: !useFieldInspectionSummary || !userPermissions.userCanEditSummary,
    },
    {
      icon: <DownloadIcon />,
      label: "Download editable CSV",
      action: () => actionDispatcher.dispatch(DialogAction.DownloadCSV),
      hidden: !userPermissions.userCanExportAssetList, // probably not the correct permission...
    },
    {
      icon: <DownloadIcon />,
      label: "Export current view",
      action: () => actionDispatcher.dispatch(DialogAction.ExportCurrentViewCSV),
      hidden: !userPermissions.userCanExportAssetList,
    },
    {
      icon: <DownloadIcon />,
      label: "Bulk attachment export",
      action: () => actionDispatcher.dispatch(DialogAction.BulkAttachmentExport),
      hidden: !userPermissions.userCanExportAssetAttachment,
    },
  ];
});

export const RecordsList = () => {
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions, actionDispatcher);

  const apiRef = useGridApiRef();

  const { businessArea } = useBusinessArea();
  const site = useSiteContext().currentSite!;
  const siteId = site.id;
  const recordsRequest = useGetRecordDetailsByFilterRequest(siteId);

  useEffect(() => {
    const filters = new RecordFilters();
    filters.businessArea = businessArea?.toString() ?? null;
    recordsRequest.call({
      filters,
    });
  }, [businessArea]);

  return (
    <ActionDispatcherProvider actionDispatcher={actionDispatcher}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <RecordsListDataViz apiRef={apiRef} loading={recordsRequest.loading} />
          <ActionsDropdownMenu primary actions={actions} />
        </Stack>

        <BusinessAreaContextProvider>
          <FilteredRecordsTable
            apiRef={apiRef}
            loading={recordsRequest.loading}
            records={recordsRequest.data ?? []}
            site={site}
          />
        </BusinessAreaContextProvider>
      </Stack>
    </ActionDispatcherProvider>
  );
};

const rowActions = createStaticRowActions((actionDispatcher, permissions) => (params) => [
  {
    icon: <AssessmentRoundedIcon />,
    onClick: () => {
      actionDispatcher.dispatch(DialogAction.GenerateReport, params.row);
    },
    label: "Generate report",
    showInMenu: true,
    hidden: !permissions.userCanEditRecords,
  },
  {
    icon: <TaskOutlinedIcon />,
    onClick: () => {
      actionDispatcher.dispatch(DialogAction.CloseRecord, params.row);
    },
    label: params.row.model.closed ? "Reopen Record" : "Close Record",
    showInMenu: true,
    hidden: !permissions.userCanEditRecords,
  },
  {
    icon: <ContentCopyRoundedIcon />,
    onClick: () => {
      actionDispatcher.dispatch(DialogAction.DuplicateRecord, params.row);
    },
    label: "Duplicate",
    showInMenu: true,
    hidden: !permissions.userCanEditRecords,
  },
  {
    icon: <AddchartIcon />,
    onClick: () => {
      actionDispatcher.dispatch(DialogAction.AssignKpi, params.row);
    },
    label: "Assign KPI",
    showInMenu: true,
    hidden: !permissions.userCanEditRecords,
  },
]);

interface FilteredRecordsTableProps {
  apiRef: React.MutableRefObject<GridApiPro>;
  loading: boolean;
  records: RecordDetailView[];
  site: Site;
}

const GridComponents = {
  LoadingOverlay: LinearProgress,
  Toolbar: ToolbarWithTabViews("Records", { includeUserDefinedTabs: true, includeBusinessAreaFilter: true }),
};

const FilteredRecordsTable = (props: FilteredRecordsTableProps) => {
  const { businessArea } = useBusinessArea();

  const actionDispatcher = useActionDispatcherContext();

  const downloadCsvExportRequest = useDownloadCsvExportRequest(props.site, "Records");
  const exportRecordAttachmentsRequest = useExportRecordAttachmentsRequest(props.site);

  const [openDialog, setOpenDialog] = useState<DialogAction | null>(null);

  const [recordsForSummary, setRecordsForSummary] = useState<RecordDetailView[]>([]);
  const [recordForDialog, setRecordForDialog] = useState<RecordDetailView>();

  const site = useSiteContext().currentSite!;
  const siteId = site.id;
  const recordsRequest = useGetRecordDetailsByFilterRequest(siteId);
  const recordDetails = recordsRequest.data?.filter((r) => r.model.recordType !== RecordType.EquipmentHealth);

  const getTableRowActions = useStaticRowActions(rowActions, actionDispatcher);

  const handleAction = useCallback(
    (action: string, params: any) => {
      if (action === DialogAction.DownloadCSV) {
        const ids = gridFilteredSortedRowIdsSelector(props.apiRef);
        downloadCsvExportRequest.call(ids.map((id) => Number(id)));
      }
      if (action === DialogAction.ExportCurrentViewCSV) {
        props.apiRef.current.exportDataAsCsv({
          fileName: `${props.site.name} Record CurrentView ${moment().format(dateFormat)}`,
          utf8WithBom: true,
        });
      }
      if (action === DialogAction.BulkAttachmentExport) {
        const ids = gridFilteredSortedRowIdsSelector(props.apiRef);
        exportRecordAttachmentsRequest.call(ids.map((id) => Number(id)));
      }
      if (action === DialogAction.CreateRecord) {
        setOpenDialog(action);
      }
      if (action === DialogAction.CreateRecordSummary) {
        const rows = gridFilteredSortedRowEntriesSelector(props.apiRef);
        setRecordsForSummary(rows.map((r) => r.model) as RecordDetailView[]);
        setOpenDialog(action);
      }
      if (action === DialogAction.CreateBulkValveHealthSummary) {
        const rows = gridFilteredSortedRowEntriesSelector(props.apiRef);
        setRecordsForSummary(rows.map((r) => r.model) as RecordDetailView[]);
        setOpenDialog(action);
      }
      if (action === DialogAction.CreateBulkVibrationHealthSummary) {
        const rows = gridFilteredSortedRowEntriesSelector(props.apiRef);
        setRecordsForSummary(rows.map((r) => r.model) as RecordDetailView[]);
        setOpenDialog(action);
      }
      if (action === DialogAction.CreateFieldInspectionSummary) {
        const rows = gridFilteredSortedRowEntriesSelector(props.apiRef);
        setRecordsForSummary(rows.map((r) => r.model) as RecordDetailView[]);
        setOpenDialog(action);
      }
      if (action === DialogAction.GenerateReport) {
        setRecordForDialog(params);
        setOpenDialog(action);
      }
      if (action === DialogAction.DuplicateRecord) {
        setRecordForDialog(params);
        setOpenDialog(action);
      }
      if (action === DialogAction.CloseRecord) {
        setRecordForDialog(params);
        setOpenDialog(action);
      }
      if (action === DialogAction.AssignKpi) {
        setRecordForDialog(params);
        setOpenDialog(action);
      }
    },
    [downloadCsvExportRequest]
  );

  useEffect(() => {
    actionDispatcher.subscribe(handleAction);
    return () => actionDispatcher.unsubscribe(handleAction);
  }, []);

  useEffect(() => {
    const filters = new RecordFilters();
    filters.businessArea = businessArea?.toString() ?? null;
    recordsRequest.call({
      filters,
    });
  }, [businessArea]);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const reloadRowInTable = (newRecord: RecordDetailView) => {
    if (props.apiRef.current.getRow(newRecord.id)) {
      props.apiRef.current.updateRows([newRecord]);
    } else {
      const oldRows: GridValidRowModel[] = [];
      props.apiRef.current.getRowModels().forEach((value) => {
        oldRows.push(value);
      });
      props.apiRef.current.setRows([...oldRows, newRecord]);
    }
  };

  return (
    <>
      <Box sx={{ height: "700px", width: "100%" }}>
        <MetadataColumnProvider type="RecordDetailViewModel" getActions={getTableRowActions}>
          {(columns) => (
            <StripedDataGrid
              apiRef={props.apiRef}
              density="compact"
              loading={props.loading}
              components={GridComponents}
              columns={columns}
              rows={recordDetails ?? []}
            />
          )}
        </MetadataColumnProvider>
      </Box>

      {openDialog === DialogAction.CreateRecord && <CreateRecordDialog onClose={closeDialog} />}
      {openDialog === DialogAction.CreateRecordSummary && (
        <CreateRecordSummaryDialog onClose={closeDialog} records={recordsForSummary} />
      )}
      {openDialog === DialogAction.CreateBulkValveHealthSummary && (
        <CreateBulkHealthSummaryDialog
          onClose={closeDialog}
          records={recordsForSummary}
          summaryType={SummaryType.BulkValveHealthType}
        />
      )}
      {openDialog === DialogAction.CreateBulkVibrationHealthSummary && (
        <CreateBulkHealthSummaryDialog
          onClose={closeDialog}
          records={recordsForSummary}
          summaryType={SummaryType.BulkVibrationHealth}
        />
      )}
      {openDialog === DialogAction.CreateFieldInspectionSummary && (
        <CreateFieldInspectionSummaryDialog onClose={closeDialog} records={recordsForSummary} />
      )}
      {openDialog === DialogAction.GenerateReport && (
        <GenerateReportDialog onClose={closeDialog} record={recordForDialog!} />
      )}
      {openDialog === DialogAction.DuplicateRecord && (
        <DuplicateRecordDialog onClose={closeDialog} record={recordForDialog!} onComplete={reloadRowInTable} />
      )}
      {openDialog === DialogAction.CloseRecord && (
        <CloseReopenRecordDialog onClose={closeDialog} record={recordForDialog!} onComplete={reloadRowInTable} />
      )}
      {openDialog === DialogAction.AssignKpi && (
        <AssignKpiTemplateDialog onClose={closeDialog} record={recordForDialog!} onComplete={reloadRowInTable} />
      )}
    </>
  );
};
