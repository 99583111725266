import { Box, Container, Paper, Stack, Tab, Tabs } from "@mui/material";
import { PageTitle } from "app/mui/common/headers/PageTitle";
import Help from "@mui/icons-material/Help";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { useUserContext } from "app";
import { TabPanel } from "app/mui/tabs/TabPanel";
import { ReleaseNotesList } from "./releaseNotes/ReleaseNotesList";
import { AddIcon } from "icons/AddIcon";
import { useCallback, useEffect, useState } from "react";
import { ActionDispatcherProvider, useActionDispatcher } from "app/mui/ActionDispatcher";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { CreateReleaseNotesDialog } from "./releaseNotes/dialogs/CreateReleaseNotesDialog";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";
import { CreateTutorialsDialog } from "./tutorials/dialogs/CreateTutorialsDialog";
import { TutorialsList } from "./tutorials/TutorialsList";

enum TabIndex {
  ReleaseNotes = 0,
  Tutorials = 1,
}

const pageActions = (tabIndex) =>
  createStaticActions((actionDispatcher, userPermissions) => [
    {
      icon: <AddIcon />,
      label: "Create Release Note",
      action: () => actionDispatcher.dispatch(DialogAction.CreateReleaseNotes),
      hidden: tabIndex !== TabIndex.ReleaseNotes || !userPermissions.userCanEditReleaseNotes,
    },
    {
      icon: <AddIcon />,
      label: "Create Tutorial",
      action: () => actionDispatcher.dispatch(DialogAction.CreateTutorial),
      hidden: tabIndex !== TabIndex.Tutorials || !userPermissions.userCanEditReleaseNotes,
    },
  ]);

export const HelpPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeTab = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };
  const { userPermissions } = useUserContext();

  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(tabIndex), actionDispatcher, [tabIndex]);

  const handleAction = useCallback((action: string, params: any) => {
    if (action === DialogAction.CreateReleaseNotes) {
      setOpenDialog(DialogAction.CreateReleaseNotes);
    }
    if (action === DialogAction.CreateTutorial) {
      setOpenDialog(DialogAction.CreateTutorial);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const [openDialog, setOpenDialog] = useState<DialogAction | null>(null);

  const [currentVersion, setCurrentVersion] = useState<string | null>(null);

  const handleCurrentVersionUpdate = (currentVersion) => {
    setCurrentVersion(currentVersion);
  };

  return (
    <ActionDispatcherProvider actionDispatcher={actionDispatcher}>
      <Container>
        <Paper sx={{ p: 3 }}>
          <Stack direction="column" spacing={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <PageTitle title="HELP" icon={<Help />} />
              <Box sx={{ marginLeft: 2 }}>
                {userPermissions.userCanEditReleaseNotes && <ActionsDropdownMenu primary actions={actions} />}
              </Box>
            </Stack>
            <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2, px: 2 }}>
              <Tabs value={tabIndex} onChange={handleChangeTab}>
                <Tab label="Release Notes" value={TabIndex.ReleaseNotes} />
                <Tab label="Tutorials" value={TabIndex.Tutorials} />
              </Tabs>
            </Box>
            <TabPanel value={tabIndex} index={TabIndex.ReleaseNotes} sx={{ p: 2 }}>
              <ReleaseNotesList updateCurrentVersion={handleCurrentVersionUpdate} />
            </TabPanel>
            <TabPanel value={tabIndex} index={TabIndex.Tutorials} sx={{ p: 2 }}>
              <TutorialsList />
            </TabPanel>
          </Stack>
        </Paper>
      </Container>
      {openDialog === DialogAction.CreateReleaseNotes && (
        <CreateReleaseNotesDialog currentVersion={currentVersion} onClose={closeDialog} />
      )}
      {openDialog === DialogAction.CreateTutorial && <CreateTutorialsDialog onClose={closeDialog} />}
    </ActionDispatcherProvider>
  );
};
