import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { Record } from "records/models";
import { useCreateRecordAssetSpecificationsRequest } from "records/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AssetDigest } from "assets";

interface AddSpecificationDialogProps extends BaseDialogProps {
  record: Record;
}

const validationSchema = yup.object({
  assets: yup.array().test("is-asset-empty", "required", (value) => (value?.length ?? 0) > 0),
});

export const AddSpecificationDialog = (props: AddSpecificationDialogProps) => {
  const createRecordAssetSpecificationsRequest = useCreateRecordAssetSpecificationsRequest();

  const methods = useForm<{ assets: AssetDigest[] }>({
    defaultValues: {
      assets: [],
    },
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      createRecordAssetSpecificationsRequest
        .call({
          recordId: props.record.id,
          assetIds: values.assets.map((a) => a.id),
        })
        .then(() => {
          handleClose();
        });
    })();
  };

  const handleClose = () => {
    methods.reset();
    props.onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Add Specification
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "200px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <AutocompleteFormItem
              multiple
              fieldName="assets"
              label="Asset(s)"
              options={props.record.assets}
              getOptionLabel={(o) => o.tag}
              autocompleteProps={{
                isOptionEqualToValue: (option, value) => option.id === value.id,
              }}
            />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={onSubmit} variant="contained" color="secondary">
            Add
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
