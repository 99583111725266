import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useAppSettingsContext } from "app/contexts/AppSettingsContext";
import { useBackgroundReportContext } from "app/contexts/BackgroundReportContext";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { CheckboxFormItem } from "app/mui/forms/CheckboxFormItem";
import { BulkVibrationHealthSummaryNodeReportParams } from "common/reports";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RecordDetailView } from "records";
import { Criticality, SiteArea } from "sites";
import { Summary } from "summaries";

interface ConfigureBulkVibrationHealthReportModalProps extends BaseDialogProps {
  summary: Summary;
  summaryRecordDetails: RecordDetailView[];
}

export const ConfigureBulkVibrationHealthReportModal: React.FunctionComponent<
  ConfigureBulkVibrationHealthReportModalProps
> = (props) => {
  const methods = useForm<
    Omit<BulkVibrationHealthSummaryNodeReportParams, "areaIds" | "criticalityIds"> & {
      areas: SiteArea[];
      criticalities: Criticality[];
    }
  >({
    defaultValues: {
      id: props.summary.id,
      pdf: true,
      includeHealthBreakdown: true,
      includeBadActors: true,
      includeVibrationDashboard: true,
      includeNotes: true,
      includeTestResults: true,
      includeVibrationHealthLegend: true,
      includePhotos: true,
      appendPdfAttachments: true,
    },
  });
  const { appSettings } = useAppSettingsContext();
  const [isLoading, setIsLoading] = useState(false);
  const { generateBulkVibrationReport: generateBulkVibrationHealthSummaryReportNode } = useBackgroundReportContext();

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    generateBulkVibrationHealthSummaryReportNode
      ?.call({
        id: props.summary.id,
        pdf: true,
        includeHealthBreakdown: true,
        includeBadActors: true,
        includeVibrationDashboard: true,
        includeNotes: true,
        includeTestResults: true,
        includeVibrationHealthLegend: true,
        includePhotos: true,
        appendPdfAttachments: true,
      })
      .then(() => {
        setIsLoading(false);
        props.onClose();
      });
  }, []);

  return (
    <BaseDialog onClose={props.onClose} maxWidth="md">
      <DialogTitle variant="h6" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
        Generate report
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={0}>
            <CheckboxFormItem fieldName="includeHealthBreakdown" label="Include health break-down" />
            <CheckboxFormItem fieldName="includeBadActors" label="Include top 10 bad actors" />
            <CheckboxFormItem fieldName="includeVibrationDashboard" label="Include vibration dashboard legend" />
            <CheckboxFormItem fieldName="includeNotes" label="Include notes" />
            <CheckboxFormItem fieldName="includeTestResults" label="Include in test results" />
            <CheckboxFormItem fieldName="includeVibrationHealthLegend" label="Include vibration health legend" />
            <CheckboxFormItem fieldName="includePhotos" label="Include photos" />
            <CheckboxFormItem fieldName="appendPdfAttachments" label="Append PDF Attachments" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="secondary"
            disabled={!appSettings}
            onClick={handleSubmit}
          >
            Generate <OpenInNewIcon sx={{ pl: 1, width: "1rem" }} />
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
