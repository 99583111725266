import { Box, Paper, Stack, Typography } from "@mui/material";
import { AttachmentModel, TutorialsDetailViewModel } from "@bluemarvel/iris-common/model";
import { AttachmentsGrid } from "attachments/mui/AttachmentsGrid";
import { AttachmentParentModel } from "help/releaseNotes/sections/ReleaseNotesAttachments";

interface TutorialsAttachmentsProps {
  tutorialsDetail: TutorialsDetailViewModel;
  tutorialAttachments: AttachmentModel[];
}

export const TutorialsAttachments = (props: TutorialsAttachmentsProps) => {
  const { tutorialsDetail, tutorialAttachments } = props;

  return (
    <Paper>
      <Box sx={{ p: 2 }}>
        <Stack direction="column" justifyContent="space-between">
          <Typography variant="h6">
            <Box>ADDITIONAL RESOURCES</Box>
          </Typography>

          <TutorialsAttachmentsGrid
            attachmentParents={[
              {
                id: tutorialsDetail.model.id,
                title: tutorialsDetail.model.name,
                attachments: tutorialAttachments,
              },
            ]}
          />
        </Stack>
      </Box>
    </Paper>
  );
};

interface TutorialsAttachmentsGridProps {
  attachmentParents: AttachmentParentModel[];
}

const TutorialsAttachmentsGrid = (props: TutorialsAttachmentsGridProps) => {
  const { attachmentParents } = props;
  const totalAttachments = attachmentParents.reduce((count, parent) => count + parent.attachments.length, 0);

  return (
    <>
      {totalAttachments === 0 ? (
        <Box padding={3} display="flex" justifyContent="center">
          No attachments added
        </Box>
      ) : (
        <>
          {attachmentParents.map((parentItem) => (
            <Box paddingTop={2} key={parentItem.id}>
              {parentItem.attachments.length > 0 && (
                <AttachmentsGrid
                  isSelectMode={false}
                  onMultiSelectToggle={() => {}}
                  selectedAttachmentIds={new Set<number>()}
                  parentType="Tutorial"
                  parentId={parentItem.id}
                  attachments={parentItem.attachments}
                  onDeleteAttachment={() => {}}
                  canCopyAssemblyImage={false}
                  isSideBar={true}
                />
              )}
            </Box>
          ))}
        </>
      )}
    </>
  );
};
