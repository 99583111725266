import AutoSave from "app/mui/forms/AutoSave";
import { Box, Grid, Paper, Typography } from "@mui/material";
import { ReleaseNotesDetailViewModel } from "@bluemarvel/iris-common/model";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { useForm } from "react-hook-form";
import { useUserContext } from "app";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { RichTextEditor } from "app/mui/forms/RichTextEditor";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { useUpdateReleaseNotesRequest } from "../../api";

interface ReleaseNotesDetailSectionProps {
  releaseNotesDetail: ReleaseNotesDetailViewModel;
}

export const ReleaseNotesDetail = (props: ReleaseNotesDetailSectionProps) => {
  const { releaseNotesDetail } = props;
  const { userPermissions } = useUserContext();

  const userCanEdit = userPermissions.userCanEditReleaseNotes && !releaseNotesDetail.isPublished;

  const updateReleaseNotes = useUpdateReleaseNotesRequest();
  const defaultValues = makeSafeForReactHookForm(releaseNotesDetail.model);
  const methods = useForm({ defaultValues });

  const saveChanges = () => {
    return methods.handleSubmit((values) => {
      updateReleaseNotes.call(values);
    })();
  };

  return (
    <PermissionAwareFormProvider {...methods} require={() => userCanEdit}>
      <AutoSave defaultValues={defaultValues} onSubmit={saveChanges} />
      <Paper>
        <ExpansionPanel title="DETAILS" defaultExpanded={true}>
          <Box sx={{ p: 2 }}>
            <Grid container spacing={3}>
              {/* Version Number */}
              <Grid item md={12} lg={3} xl={2}>
                <TextFieldFormItem fieldName="versionNumber" label="Version Number" readOnly={!userCanEdit} />
              </Grid>

              {/* Release Date */}
              <Grid item md={12} lg={3}>
                <DateInputFormItem
                  label="Release Date"
                  fieldName="releaseDate"
                  readOnly={!userCanEdit}
                  readOnlyValue={releaseNotesDetail.releaseDate}
                ></DateInputFormItem>
              </Grid>

              {/* Created on */}
              <Grid item md={12} lg={3}>
                <Box>
                  <DateInputFormItem
                    label="Created"
                    fieldName="created"
                    readOnly={true} //always readonly -- using form item for consistent look with other editable form items.
                    readOnlyValue={releaseNotesDetail.created}
                  ></DateInputFormItem>
                </Box>
              </Grid>

              <Grid item xs={12}>
                {!userCanEdit && (
                  <Typography variant="caption" color="textSecondary">
                    Release Summary
                  </Typography>
                )}
                <Box display="flex" alignItems="center" sx={{ pt: 1 }}>
                  <RichTextEditor fieldName="summary" label="Release Summary" disabled={!userCanEdit} />
                </Box>
              </Grid>
            </Grid>
          </Box>
        </ExpansionPanel>
      </Paper>
    </PermissionAwareFormProvider>
  );
};
