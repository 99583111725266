import {
  BulkValveHealthSummaryNodeReportParams,
  BulkVibrationHealthSummaryNodeReportParams,
  FieldInspectionSummaryReportParams,
  KpiSummaryNodeReportParams,
} from "common/reports";
import { useAxiosGenerateReport } from "app";
import { createContext, FunctionComponent, ReactNode, useContext } from "react";
import { useNotifications } from "./NotificationContext";
import { SnackbarKey } from "notistack";

export interface BackgroundReportContextProps {
  generateFieldReport: ReturnType<typeof useAxiosGenerateReport<FieldInspectionSummaryReportParams>> | null;
  generateBulkValveReport: ReturnType<typeof useAxiosGenerateReport<BulkValveHealthSummaryNodeReportParams>> | null;
  generateKpiReport: ReturnType<typeof useAxiosGenerateReport<KpiSummaryNodeReportParams>> | null;
  generateBulkVibrationReport: ReturnType<
    typeof useAxiosGenerateReport<BulkVibrationHealthSummaryNodeReportParams>
  > | null;
}

export const BackgroundReportContext = createContext<BackgroundReportContextProps>({
  generateFieldReport: null,
  generateBulkValveReport: null,
  generateKpiReport: null,
  generateBulkVibrationReport: null,
});

export const BackgroundReportContextProvider: FunctionComponent<{
  children?: ReactNode;
}> = (props) => {
  const notify = useNotifications();
  let fieldInspectionKey: SnackbarKey | null = null;
  let bulkValveKey: SnackbarKey | null = null;
  let bulkVibrationKey: SnackbarKey | null = null;
  let kpiReportKey: SnackbarKey | null = null;

  const generateFieldReport = useAxiosGenerateReport<FieldInspectionSummaryReportParams>(
    "api/reports/summary/fieldInspection",
    {
      onStartCallback: () => {
        fieldInspectionKey = notify.info("Generating Field Inspection Summary Report", true);
      },
      onSuccessCallback: () => {
        fieldInspectionKey && notify.scheduleDismiss(fieldInspectionKey);
        fieldInspectionKey = null;
      },
      onFailCallback: () => {
        fieldInspectionKey && notify.scheduleDismiss(fieldInspectionKey);
        fieldInspectionKey = null;
        notify.error("Failed to generate report");
      },
    }
  );

  const generateBulkValveReport = useAxiosGenerateReport<BulkValveHealthSummaryNodeReportParams>(
    "api/reports/summary/bulkValveHealth",
    {
      onStartCallback: () => {
        bulkValveKey = notify.info("Generating Bulk Valve Summary Report", true);
      },
      onSuccessCallback: () => {
        bulkValveKey && notify.scheduleDismiss(bulkValveKey);
        bulkValveKey = null;
      },
      onFailCallback: () => {
        bulkValveKey && notify.scheduleDismiss(bulkValveKey);
        bulkValveKey = null;
        notify.error("Failed to generate report");
      },
    }
  );

  const generateBulkVibrationReport = useAxiosGenerateReport<BulkVibrationHealthSummaryNodeReportParams>(
    "api/reports/summary/bulkVibrationHealth",
    {
      onStartCallback: () => {
        bulkVibrationKey = notify.info("Generating Bulk Vibration Summary Report", true);
      },
      onSuccessCallback: () => {
        bulkVibrationKey && notify.scheduleDismiss(bulkVibrationKey);
        bulkVibrationKey = null;
      },
      onFailCallback: () => {
        bulkVibrationKey && notify.scheduleDismiss(bulkVibrationKey);
        bulkVibrationKey = null;
        notify.error("Failed to generate report");
      },
    }
  );

  const generateKpiReport = useAxiosGenerateReport<KpiSummaryNodeReportParams>("api/reports/summary/kpi", {
    onStartCallback: () => {
      kpiReportKey = notify.info("Generating KPI Summary Report", true);
    },
    onSuccessCallback: () => {
      kpiReportKey && notify.scheduleDismiss(kpiReportKey);
      kpiReportKey = null;
    },
    onFailCallback: () => {
      kpiReportKey && notify.scheduleDismiss(kpiReportKey);
      kpiReportKey = null;
      notify.error("Failed to generate KPI report");
    },
  });
  return (
    <BackgroundReportContext.Provider
      value={{ generateFieldReport, generateBulkValveReport, generateKpiReport, generateBulkVibrationReport }}
    >
      {props.children}
    </BackgroundReportContext.Provider>
  );
};

export const useBackgroundReportContext = () => useContext(BackgroundReportContext);
