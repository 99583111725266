import { useEffect, useMemo } from "react";
import { Box, Link, Paper, Stack, Typography, useTheme } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { generateVibrationHealthHistoryDataFromRecords, getStartAndEndDates, VibrationHealthChart } from "../health/charts";
import { formatRoutePath, Routes, useSiteIdFromRoute } from "app/routes";
import { RecordDetailView, RecordFilters, RecordType, useGetRecordDetailsByFilterRequest } from "records";
import { IrisColors } from "app/mui/theme";
import { IrisTooltip } from "app/mui/IrisTooltip";
import InfoIcon from "@mui/icons-material/Info";
import { findIndex, inRange, lowerFirst, orderBy } from "lodash";

interface VibrationHealthHistoryProps {
  assetId: number;
  recordId: number;
}

export const VibrationHealthHistory = (props: VibrationHealthHistoryProps) => {
  const { assetId, recordId } = props;
  const siteId = useSiteIdFromRoute()!;
  const theme = useTheme();

  const getAssetRecordsRequest = useGetRecordDetailsByFilterRequest(siteId);
  useEffect(() => {
    const filters = new RecordFilters();
    filters.siteId = siteId;
    filters.assetIds = [assetId];
    filters.recordTypes = [RecordType.VibrationAnalysis];
    getAssetRecordsRequest.call({ filters });
  }, []);

  const sortedRecords = orderBy(getAssetRecordsRequest.data ?? [], (r) => r.model.eventDate, "desc");
  const recordIndex = findIndex(sortedRecords, (r) => r.id === recordId);
  const lastVibrationAnalysis: RecordDetailView | null = inRange(recordIndex, 0, sortedRecords.length - 1) ?
    sortedRecords[recordIndex + 1] : null;

  const data = useMemo(() => generateVibrationHealthHistoryDataFromRecords(sortedRecords, theme), [sortedRecords]);
  const [start, end] = useMemo(() => getStartAndEndDates(data), [data]);

  if (!sortedRecords[0]?.model.health?.overallCategory) {
    return null;
  }

  return (
    <Paper sx={{ p: 2, height: "240px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" alignItems={"center"}>
        <Typography variant="h6">VIBRATION HEALTH HISTORY</Typography>
        <IrisTooltip
          title={
            <p>
              This trend represents the vibration health score over time, thus illustrating potential asset
              degradation and/or work execution that results in an improved health.
            </p>
          }
        >
          <span>
            <InfoIcon sx={{ color: IrisColors.gray500, verticalAlign: "middle", fontSize: "1.7rem" }} />
          </span>
        </IrisTooltip>
      </Stack>

      {!!lastVibrationAnalysis && (
        <Typography component="span" variant="body2" sx={{ pt: 1, textAlign: "right" }}>
          <Link
            component={RouterLink}
            to={formatRoutePath(Routes.Record, {
              siteId,
              id: lastVibrationAnalysis.model.id,
            })}
            color="secondary"
            sx={{ fontWeight: "bold" }}
          >
            Previous&nbsp;{lowerFirst(lastVibrationAnalysis.model.description)}
          </Link>
        </Typography>
      )}

      <Stack
        justifyContent="space-between"
        direction="row"
        height="100%"
        alignItems="center"
        spacing={2}
        sx={{
          minHeight: "0",
          minWidth: "0",
          width: "100%",
          height: "100%",
        }}
      >
        <Box width="100%" height="100%">
          {data.length < 2 ? (
            <Typography
              variant="body2"
              textAlign="center"
              sx={{
                color: "primary.dark",
                height: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              Not enough data to show trend
            </Typography>
          ) : (
            <AutoSizer>
              {({ height, width }) => {
                return (
                  <div style={{ width, height }}>
                    <VibrationHealthChart
                      startDate={start}
                      endDate={end}
                      data={[
                        {
                          id: "main",
                          data: data ?? [],
                        },
                      ]}
                    />
                  </div>
                );
              }}
            </AutoSizer>
          )}
        </Box>
      </Stack>
    </Paper>
  );
};
