import moment from "moment";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import { ReleaseNotesListViewModel } from "@bluemarvel/iris-common/model";
import { Paper, Grid, Box, Typography, Button, Stack } from "@mui/material";
import { formatIdRoutePath, Routes } from "app/routes";
import { dateFormat } from "common/formats";
import { Badge } from "app/mui/Badge";
import { useNavigate } from "react-router-dom";
import { ReleaseNotesVersionBadge } from "./ReleaseNotesVersionBadge";
import { IrisColors } from "app/mui/theme";

interface ReleaseNotesListItemProps {
  releaseNotesListViewModel: ReleaseNotesListViewModel;
}

export const ReleaseNotesListItem = ({ releaseNotesListViewModel: viewModel }: ReleaseNotesListItemProps) => {
  const navigate = useNavigate();
  const handleNavigation = (id: any) => {
    navigate(formatIdRoutePath(Routes.ReleaseNote, id));
  };

  const leftColorBar = viewModel.isPublished
    ? viewModel.isLatest
      ? IrisColors.accentBlue
      : "white"
    : IrisColors.blueSpartan;

  return (
    <Paper
      onClick={() => handleNavigation(viewModel.id)}
      sx={{ cursor: "pointer", borderLeft: "10px solid " + leftColorBar }}
    >
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={2} xl={2}>
            <Stack direction="column" spacing={2}>
              <Typography variant="subtitle1">{moment(viewModel.releaseDate).format(dateFormat)}</Typography>
              <ReleaseNotesVersionBadge versionNumber={viewModel.versionNumber} />
              {!viewModel.isPublished && (
                <Badge text="Draft" icon={<DesignServicesIcon sx={{ height: "20px" }} />}></Badge>
              )}
            </Stack>
          </Grid>
          <Grid item xs={4} lg={4} xl={4}>
            <Typography variant="h6">{viewModel.title}</Typography>
            <Typography variant="body2">
              Posted by {viewModel.lastModifiedBy ? viewModel.lastModifiedBy : viewModel.createdBy}
            </Typography>
          </Grid>
          <Grid item xs={5} lg={5} xl={5}>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
              dangerouslySetInnerHTML={{ __html: viewModel.summary }}
            ></Typography>
          </Grid>
          <Grid item xs={1} lg={1} xl={1}>
            <Button variant="text">VIEW</Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
