import moment from "moment";
import { Paper, Grid, Box, Typography, Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatIdRoutePath, Routes } from "app/routes";
import { dateFormat } from "common/formats";
import { IrisColors } from "app/mui/theme";
import { Badge } from "app/mui/Badge";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import { TutorialsBadge } from "./components/TutorialsBadge";
import { TutorialsListViewModel } from "@bluemarvel/iris-common/model";

interface TutorialsListItemProps {
  tutorial: TutorialsListViewModel;
  isFirstItem: boolean;
}

export const TutorialsListItem = ({ tutorial, isFirstItem }: TutorialsListItemProps) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(formatIdRoutePath(Routes.Tutorial, tutorial.id));
  };

  const leftColorBar = tutorial.isPublished ? (isFirstItem ? IrisColors.accentBlue : "white") : IrisColors.blueSpartan;

  return (
    <Paper onClick={handleNavigation} sx={{ cursor: "pointer", borderLeft: "10px solid " + leftColorBar }}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={2} xl={2}>
            <Stack direction="column" spacing={2}>
              <Typography variant="subtitle1">{moment(tutorial.created).format(dateFormat)}</Typography>
              {!tutorial.isPublished && <Badge text="Draft" icon={<DesignServicesIcon />} color="#3b75a7" />}
              {tutorial.tutorialType && <TutorialsBadge label={tutorial.tutorialType} />}
            </Stack>
          </Grid>
          <Grid item xs={4} lg={4} xl={4}>
            <Typography variant="h6">{tutorial.name}</Typography>
            <Typography variant="body2">Created by {tutorial.createdBy}</Typography>
          </Grid>
          <Grid item xs={5} lg={5} xl={5}>
            {tutorial.description && (
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "3",
                  WebkitBoxOrient: "vertical",
                }}
                dangerouslySetInnerHTML={{ __html: tutorial.description }}
              ></Typography>
            )}
          </Grid>
          <Grid item xs={1} lg={1} xl={1}>
            <Button variant="text">VIEW</Button>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
