import { NotEnoughData } from "app/mui/common/placeholders/NotEnoughDataYetMessage";
import { YearlyAssetCountByHealthCategory } from "dashboard/models";
import _ from "lodash";
import { FunctionComponent, useMemo } from "react";
import { AssetHealthBarChart, AssetHealthBarChartData } from "./AssetHealthBarChart";

interface AssetHealthWidgetProps {
  vibration: YearlyAssetCountByHealthCategory[];
  valve: YearlyAssetCountByHealthCategory[];
}

type AssetHealthBarChartBar = Omit<AssetHealthBarChartData, "year">;

const getData = (yearlyRecordsCounts: YearlyAssetCountByHealthCategory[]): AssetHealthBarChartData[] => {
  const yearlyCountsDict: { [year: number]: AssetHealthBarChartBar } = {};

  _.orderBy(_.uniq(yearlyRecordsCounts.map((d) => d.year)), (x) => x).forEach((year) => {
    yearlyCountsDict[year] = {} as AssetHealthBarChartBar;
  });

  yearlyRecordsCounts.forEach(({ year, healthCategory, value }) => {
    yearlyCountsDict[year][healthCategory] = (yearlyCountsDict[year][healthCategory] ?? 0) + value;
  });

  return Object.keys(yearlyCountsDict).map((key) => {
    return {
      year: key,
      ...yearlyCountsDict[key],
    };
  });
};

export const AssetHealthWidget: FunctionComponent<AssetHealthWidgetProps> = (props) => {
  const valveData = useMemo(() => getData(props.valve), [props.valve]);
  const vibrationData = useMemo(() => getData(props.vibration), [props.vibration]);
  const totalData = useMemo(() => getData([...props.vibration, ...props.valve]), [props.vibration, props.valve]);

  if (valveData.length === 0 && vibrationData.length === 0) {
    return <NotEnoughData />;
  }

  return <AssetHealthBarChart totalData={totalData} valveData={valveData} vibrationData={vibrationData} />;
};
