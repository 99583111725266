import React from "react";
import { Box, Divider, Grid, List, ListItem, ListItemText, Paper, Stack, Typography } from "@mui/material";
import {
  PackagedSolutionOperatingSystem,
  PackagedSolutionOperatingSystemFieldLabel,
} from "../packagedSolutionOperatingSystem";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { ReportCardPackagedSolution } from "../reportCardPackageSolution";
import { useForm } from "react-hook-form";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { TrafficLightFormItem } from "app/mui/forms/Select/TrafficLightFormItem";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { IrisColors } from "app/mui/theme";
import { useUpdateReportCardPackagedSolution } from "packagedSolution/api";
import AutoSave from "app/mui/forms/AutoSave";
import { PackagedSolutionEquipmentModel } from "@bluemarvel/iris-common/model";
import TrafficLightCircle from "records/mui/TrafficLightCircle";
import { RecordDetailView } from "records";

const operatingSystemFields: Array<{
  fieldName: keyof PackagedSolutionOperatingSystem;
  label: PackagedSolutionOperatingSystemFieldLabel;
}> = [
  {
    fieldName: "hardware",
    label: PackagedSolutionOperatingSystemFieldLabel.Hardware,
  },
  {
    fieldName: "software",
    label: PackagedSolutionOperatingSystemFieldLabel.Software,
  },
  {
    fieldName: "performance",
    label: PackagedSolutionOperatingSystemFieldLabel.Performance,
  },
  {
    fieldName: "security",
    label: PackagedSolutionOperatingSystemFieldLabel.Security,
  },
];

interface PackagedSolutionReportCardProps {
  packagedSolutionId: number;
  reportCard: ReportCardPackagedSolution;
  equipments: PackagedSolutionEquipmentModel[];
  equipmentHealth: RecordDetailView[];
  userCanEdit: boolean;
}

export const PackagedSolutionReportCard = (props: PackagedSolutionReportCardProps) => {
  const { packagedSolutionId, reportCard, equipments, equipmentHealth, userCanEdit } = props;

  const latestRecordsMap: Map<string, RecordDetailView> = new Map();
  for (const equipment of equipments) {
    const record = equipmentHealth.find((r) => r.id === (equipment.latestRecord?.id ?? 0));
    if (record) {
      latestRecordsMap.set(equipment.name, record);
    }
  }

  const defaultValues = makeSafeForReactHookForm(reportCard);
  const methods = useForm({ defaultValues });
  const updateReportCard = useUpdateReportCardPackagedSolution();

  const asFoundWidth = "65px";
  const arrowWidth = "35px";

  const saveChanges = () => {
    methods.handleSubmit((values) => {
      updateReportCard.call({ ...values, packagedSolutionId: packagedSolutionId });
    })();
  };

  return (
    <PermissionAwareFormProvider {...methods} require={(permissions) => permissions.userCanEditPackagedSolution}>
      <AutoSave onSubmit={saveChanges} />
      <Paper sx={{ p: 2, minHeight: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
        <Stack justifyContent="space-between" direction="column" sx={{ width: "100%" }}>
          <Typography variant="h6">REPORT CARD</Typography>
          <Paper sx={{ my: 2 }}>
            <List sx={{ width: "100%" }}>
              <ListItem alignItems="flex-start">
                <Grid container justifyContent="space-between" columnSpacing={2} alignItems="flex-end">
                  <Grid item xs={5}>
                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 500, textTransform: "uppercase" }}>
                      Operating System
                    </Typography>
                  </Grid>
                  <Grid item xs={false} width={asFoundWidth}>
                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;found</Typography>
                  </Grid>
                  <Grid item xs={false} width={arrowWidth} />
                  <Grid item xs={true}>
                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;left</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider component="li" />
              {operatingSystemFields.map((cField, cIndex) => (
                <React.Fragment key={`condition-${cField.fieldName}`}>
                  <ListItem>
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container justifyContent="space-between" columnSpacing={2} alignItems="center">
                        <Grid item xs={5} sx={{ display: "flex" }}>
                          <Typography variant="caption" sx={{ fontWeight: 400, pr: 1 }}>
                            {cField.label}
                          </Typography>
                        </Grid>
                        <Grid item xs={false} width={asFoundWidth}>
                          <TrafficLightFormItem
                            fieldName={"asFoundCondition_" + cField.fieldName}
                            syncFieldName={"asLeftCondition_" + cField.fieldName}
                            readOnly={!userCanEdit}
                          />
                        </Grid>
                        <Grid item xs={false} width={arrowWidth}>
                          <ArrowForwardIcon sx={{ fontSize: "20px", color: IrisColors.gray500 }} />
                        </Grid>
                        <Grid item xs={true}>
                          <TrafficLightFormItem
                            fieldName={"asLeftCondition_" + cField.fieldName}
                            readOnly={!userCanEdit}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </ListItem>
                  {cIndex < operatingSystemFields.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
          <Paper>
            <List sx={{ width: "100%" }}>
              <ListItem alignItems="flex-start">
                <Grid container justifyContent="space-between" columnSpacing={2} alignItems="flex-end">
                  <Grid item xs={5}>
                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 500, textTransform: "uppercase" }}>
                      Equipment Health
                    </Typography>
                  </Grid>
                  <Grid item xs={false} width={asFoundWidth}>
                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;found</Typography>
                  </Grid>
                  <Grid item xs={false} width={arrowWidth} />
                  <Grid item xs={true}>
                    <Typography sx={{ fontSize: "0.75rem", fontWeight: 400 }}>As&nbsp;left</Typography>
                  </Grid>
                </Grid>
              </ListItem>
              <Divider component="li" />
              {latestRecordsMap.size > 0 ? (
                [...latestRecordsMap.entries()].map(([equipment, record], eIndex) => (
                  <React.Fragment key={record.id}>
                    <ListItem>
                      <Box sx={{ flexGrow: 1 }}>
                        <Grid container justifyContent="space-between" columnSpacing={2} alignItems="center">
                          <Grid item xs={5} sx={{ display: "flex" }}>
                            <Typography variant="caption" sx={{ fontWeight: 400, pr: 1 }}>
                              {equipment}
                            </Typography>
                          </Grid>
                          <Grid item xs={false} width={asFoundWidth}>
                            <TrafficLightCircle
                              value={Math.max(
                                record.reportCardEquipmentHealth?.asFound_accuracyCalibration ?? 0,
                                record.reportCardEquipmentHealth?.asFound_dataIntegrityMaintenance ?? 0,
                                record.reportCardEquipmentHealth?.asFound_measurementSystemIntegrity ?? 0,
                                record.reportCardEquipmentHealth?.asFound_reportingAuditing ?? 0
                              )}
                            />
                          </Grid>
                          <Grid item xs={false} width={arrowWidth}>
                            <ArrowForwardIcon sx={{ fontSize: "20px", color: IrisColors.gray500 }} />
                          </Grid>
                          <Grid item xs={true}>
                            <TrafficLightCircle
                              value={Math.max(
                                record.reportCardEquipmentHealth?.asLeft_accuracyCalibration ?? 0,
                                record.reportCardEquipmentHealth?.asLeft_dataIntegrityMaintenance ?? 0,
                                record.reportCardEquipmentHealth?.asLeft_measurementSystemIntegrity ?? 0,
                                record.reportCardEquipmentHealth?.asLeft_reportingAuditing ?? 0
                              )}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </ListItem>
                    {eIndex < equipmentHealth.length - 1 && <Divider />}
                  </React.Fragment>
                ))
              ) : (
                <ListItemText sx={{ textAlign: "center" }}>No equipment added</ListItemText>
              )}
            </List>
          </Paper>
        </Stack>
      </Paper>
    </PermissionAwareFormProvider>
  );
};
