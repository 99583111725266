import {
  AttachmentModel,
  ReleaseNotesDetailViewModel,
  ReleaseNotesEnhancementModel,
} from "@bluemarvel/iris-common/model";
import { Box, Paper, Stack } from "@mui/material";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { ReleaseNotesEnhancementItem } from "./components/ReleaseNotesEnhancementItem";
import { AttachmentParent } from "attachments/api";
import { ReleaseNotesEnhancementActions } from "../actions/ReleaseNotesEnhancementActions";

export interface ReleaseNotesEnhancementSectionProps {
  releaseNotesDetail: ReleaseNotesDetailViewModel;
  enhancementData: ReleaseNotesEnhancementModel[];
  enhancementType: AttachmentParent;
  attachments: AttachmentModel[];
  userCanEdit: boolean;
}

export const ReleaseNotesEnhancement = (props: ReleaseNotesEnhancementSectionProps) => {
  const { enhancementData, enhancementType, attachments, releaseNotesDetail, userCanEdit } = props;
  const label = enhancementType === "ReleaseNoteFeature" ? "FEATURES" : "BUGS";

  return (
    <>
      <Paper>
        <ExpansionPanel
          title={label}
          defaultExpanded={true}
          rightSideComponent={
            userCanEdit && (
              <ReleaseNotesEnhancementActions
                releaseNotesDetail={releaseNotesDetail}
                enhancementType={enhancementType}
              />
            )
          }
        >
          <Box sx={{ pl: 3, pr: 3, pb: 3 }}>
            <Box>
              <Stack spacing={0}>
                <>
                  {enhancementData.map((item, index) => (
                    <ReleaseNotesEnhancementItem
                      key={item.id}
                      enhancementType={enhancementType}
                      attachments={attachments}
                      enhancementData={item}
                      count={index + 1}
                      userCanEdit={userCanEdit}
                    />
                  ))}
                </>
              </Stack>
            </Box>
          </Box>
        </ExpansionPanel>
      </Paper>
    </>
  );
};
