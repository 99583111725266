import { PackagedSolutionDetailViewModel, UpdatePackagedSolutionModel } from "@bluemarvel/iris-common/model";
import { Avatar, Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { SiteAreasForCurrentSite } from "app/mui/AreasContext";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import AutoSave from "app/mui/forms/AutoSave";
import { FollowUpDateFormItem } from "app/mui/forms/Date/FollowUpDateFormItem";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { dateFormat } from "common";
import moment from "moment";
import { useUpdatePackageSolutionsRequest } from "packagedSolution/api";
import { useForm } from "react-hook-form";

interface PackagedSolutionDetailsProps {
  packagedSolutionDetail: PackagedSolutionDetailViewModel;
  userCanEdit: boolean;
}

export const PackagedSolutionDetails = (props: PackagedSolutionDetailsProps) => {
  const { packagedSolutionDetail, userCanEdit } = props;
  const packagedSolution = packagedSolutionDetail.model;

  const defaultValues = makeSafeForReactHookForm(packagedSolution);

  const methods = useForm<UpdatePackagedSolutionModel>({ defaultValues });
  const updatePackagedSolutionRequest = useUpdatePackageSolutionsRequest();

  const saveChanges = () => {
    methods.handleSubmit((values) => {
      updatePackagedSolutionRequest.call({
        ...values,
        name: packagedSolution.name,
      });
    })();
  };

  return (
    <>
      <PermissionAwareFormProvider {...methods} require={(permissions) => permissions.userCanEditPackagedSolution}>
        <AutoSave defaultValues={defaultValues} onSubmit={saveChanges} />
        <Paper>
          <ExpansionPanel title="Details" defaultExpanded={true}>
            <Box sx={{ p: 2 }}>
              <Grid container spacing={4}>
                {/* created date */}
                <Grid item xs={12} md={4}>
                  <Box>
                    <Typography variant="body2" color="textSecondary">
                      Create date
                    </Typography>
                    <Box display="flex" alignItems="center" sx={{ pt: 1 }}>
                      <Typography variant="body1">
                        {moment(packagedSolutionDetail.created).format(dateFormat)}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {/* created by */}
                <Grid item xs={12} md={4}>
                  <Stack spacing={2}>
                    <Box>
                      <Typography variant="body2" color="textSecondary">
                        Created by
                      </Typography>
                      <Box display="flex" alignItems="center" sx={{ pt: 1 }}>
                        <Avatar
                          sx={{ width: 30, height: 30, fontSize: 12, mr: 1 }}
                          src={packagedSolutionDetail.createdBy?.picture ?? ""}
                          alt={packagedSolutionDetail.createdBy?.fullName ?? ""}
                        />
                        <Typography variant="body1">{packagedSolutionDetail.createdBy?.fullName ?? "N/A"}</Typography>
                      </Box>
                    </Box>
                  </Stack>
                </Grid>
                {/* customer contact */}
                <Grid item xs={12} md={4}>
                  <TextFieldFormItem fieldName="customerContact" label="Customer Contact" readOnly={!userCanEdit} />
                </Grid>
                {/* Follow-up */}
                <Grid item xs={12} md={4}>
                  <FollowUpDateFormItem
                    label="Follow-up"
                    fieldName="followUp"
                    readOnly={!userCanEdit}
                    readOnlyValue={packagedSolution.followUp}
                  />
                </Grid>
                {/* Area */}
                <Grid item xs={12} md={4}>
                  <SiteAreasForCurrentSite>
                    {({ areas }) => (
                      <AutocompleteFormItem
                        fieldName="areaId"
                        label="Area"
                        options={areas}
                        getOptionLabel={(area) => area.name}
                        autocompleteProps={{
                          disabled: !userCanEdit,
                        }}
                      />
                    )}
                  </SiteAreasForCurrentSite>
                </Grid>
              </Grid>
            </Box>
          </ExpansionPanel>
        </Paper>
      </PermissionAwareFormProvider>
    </>
  );
};
