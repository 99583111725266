import * as React from "react";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { OperatingSystemModel } from "@bluemarvel/iris-common/model";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Collapse,
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { EditableContextProvider } from "app/mui/forms/EditableContext";
import { useState } from "react";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ExpandCircleDownRoundedIcon from "@mui/icons-material/ExpandCircleDownRounded";
import { useDeleteOperatingSystemRequest, useUpdateOperatingSystemRequest } from "packagedSolution/api";
import { EditableActiveStatusFormItem } from "app/mui/forms/ActiveStatusFormItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

interface PanelCardProps {
  model: OperatingSystemModel;
  userCanEdit: boolean;
  index: number;
}

export const PanelCard = (props: PanelCardProps) => {
  const { model, userCanEdit, index } = props;
  const defaultValues = makeSafeForReactHookForm(model);
  const methods = useForm({ defaultValues });
  const updateOperatingSystemRequest = useUpdateOperatingSystemRequest();
  const deletePanelRequest = useDeleteOperatingSystemRequest(model.packagedSolutionId);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const removeThisPanel = () => {
    deletePanelRequest.call(model.id);
  };

  const updatePanel = () => {
    methods.handleSubmit((values) => {
      updateOperatingSystemRequest.call(values);
    })();
  };

  return (
    <PermissionAwareFormProvider {...methods} require={(permission) => permission.userCanEditPackagedSolution}>
      <AutoSave onSubmit={updatePanel} />
      <Grid item xs={12} sm={6} lg={4} xl={3}>
        <Card
          sx={{
            width: "100%",
            minHeight: "300px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
          variant="outlined"
        >
          <CardContent>
            <EditableContextProvider editable={editMode}>
              <Stack spacing={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ position: "relative" }}>
                  <Typography variant="h6" fontWeight="600" lineHeight="1">
                    {`Panel ${index + 1}`}
                  </Typography>
                  {userCanEdit && (
                    <>
                      <IconButton
                        onClick={(event) => setAnchorEl(event.currentTarget)}
                        sx={{ position: "absolute", right: 0 }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        open={open}
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        anchorOrigin={{ vertical: "top", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                      >
                        <MenuItem key={"overflow-remove"} onClick={removeThisPanel}>
                          <ListItemIcon>
                            <DeleteOutlineOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>Delete panel</ListItemText>
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </Stack>
                <EditableActiveStatusFormItem label="Status" fieldName="status" />
                <EditableTextFieldFormItem label="Name" fieldName="name" />
                <EditableTextFieldFormItem label="Type" fieldName="type" editable={false} />
                <Collapse in={expanded}>
                  <Typography variant="subtitle2" sx={{ pt: "5px" }}>
                    PC
                  </Typography>
                  <EditableTextFieldFormItem label="Model" fieldName="pcModel" />
                  <EditableTextFieldFormItem label="SSF Installed date" fieldName="pcSsfInstalledDate" />
                  <EditableTextFieldFormItem label="SSF Health" fieldName="pcSsfHealth" />
                  <EditableTextFieldFormItem label="Ram Usage" fieldName="pcRamUsage" />
                  <EditableTextFieldFormItem label="CPU and SSD temp" fieldName="pcCpuSsdTemp" />
                  <Typography variant="subtitle2" sx={{ pt: "20px" }}>
                    Printer
                  </Typography>
                  <EditableTextFieldFormItem label="Model" fieldName="printerModel" />
                  <EditableTextFieldFormItem label="Paper" fieldName="printerPaper" />
                  <EditableTextFieldFormItem label="Driver" fieldName="printerDriver" />
                  <Typography variant="subtitle2" sx={{ pt: "20px" }}>
                    PLC
                  </Typography>
                  <EditableTextFieldFormItem label="Model (Meta Data)" fieldName="plcModel" />
                  <Typography variant="subtitle2" sx={{ pt: "20px" }}>
                    HMI
                  </Typography>
                  <EditableTextFieldFormItem label="Model (Meta Data)" fieldName="hmiModel" />
                  <EditableTextFieldFormItem label="Calibrate (T/F)" fieldName="hmiCalibrate" />
                </Collapse>
              </Stack>
            </EditableContextProvider>
          </CardContent>
          <CardActions>
            <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
              {userCanEdit ? (
                <IconButton
                  onClick={() => {
                    setEditMode(!editMode);
                  }}
                >
                  <EditRoundedIcon sx={{ color: "primary.dark" }} />
                </IconButton>
              ) : (
                <Box />
              )}
              <IconButton onClick={() => setExpanded(!expanded)}>
                <ExpandCircleDownRoundedIcon
                  sx={{
                    color: "primary.dark",
                    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                />
              </IconButton>
            </Stack>
          </CardActions>
        </Card>
      </Grid>
    </PermissionAwareFormProvider>
  );
};
