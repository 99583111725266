import { Box, Link, Paper, Stack, Typography } from "@mui/material";
import { ActionDispatcherProvider, useActionDispatcher } from "app/mui/ActionDispatcher";
import {
  createStaticColumns,
  createStaticRowActions,
  useColumns,
  useStaticRowActions,
} from "app/mui/tables/utils/createStaticColumns";
import { theme } from "app/mui/theme";
import { Routes, formatRoutePath, useSiteIdFromRoute } from "app/routes";
import { SummaryIcon } from "icons/SummaryIcon";
import { useGetSummaryDetailsBySiteRequest } from "summaries/api";
import { Link as RouterLink } from "react-router-dom";
import { SummaryDetail, SummaryStatus, SummaryType } from "summaries/models";
import moment from "moment";
import { dateFormat } from "common/formats";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { GridRenderCellParams, GridToolbarFilterButton, useGridApiRef } from "@mui/x-data-grid-pro";
import { SummaryTypeBadge } from "./SummaryTypeBadge";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import { SummaryListActions } from "./SummaryListActions";

export const SummaryListPage = () => {
  const actionDispatcher = useActionDispatcher();

  const siteId = useSiteIdFromRoute()!;
  const getSummaryDetailsBySiteRequest = useGetSummaryDetailsBySiteRequest(siteId);

  const summaries = getSummaryDetailsBySiteRequest.data || [];

  const rowActions = createStaticRowActions((actionDispatcher, permissions) => (params) => [
    {
      icon: <DeleteRoundedIcon />,
      onClick: () => {
        actionDispatcher.dispatch("deleteSummary", params.row);
      },
      label: "Delete",
      showInMenu: true,
      hidden: !permissions.userCanEditSummary,
    },
  ]);

  const getTableRowActions = useStaticRowActions(rowActions, actionDispatcher);

  const refetch = () => getSummaryDetailsBySiteRequest.call();

  const columnDef = createStaticColumns(() => [
    {
      field: "title",
      headerName: "Title",
      valueGetter: (params) => params.row.title,
      flex: 1,
      renderCell: (params) => {
        return (
          <Link
            color="secondary"
            component={RouterLink}
            to={formatRoutePath(Routes.Summary, params.row as SummaryDetail)}
            sx={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
            state={{ returnTo: window.location.pathname }}
          >
            {params.row.title}
          </Link>
        );
      },
    },
    {
      field: "summaryDate",
      headerName: "Summary Date",
      type: "date",
      width: 150,
      valueGetter: (params) => params.row.summaryDate,
      valueFormatter: (params) => (params.value ? moment(params.value).format(dateFormat) : ""),
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      valueGetter: (params) => params.row.status,
      type: "singleSelect",
      valueOptions: [SummaryStatus.Open, SummaryStatus.Closed],
    },
    {
      field: "summaryType",
      headerName: "Type",
      width: 180,
      valueGetter: (params) => params.row.summaryType,
      type: "singleSelect",
      valueOptions: [SummaryType.General, SummaryType.BulkValveHealthType, SummaryType.KPI, SummaryType.OutageScope],
      renderCell: (params: GridRenderCellParams<SummaryType>) => <SummaryTypeBadge summaryType={params.value} />,
    },
    {
      field: "createdBy",
      headerName: "Created by",
      width: 250,
      type: "string",
      valueGetter: (params) => params.row.createdBy?.fullName,
    },
    {
      field: "closedBy",
      headerName: "Closed by",
      width: 250,
      type: "string",
      valueGetter: (params) => params.row.closedBy?.fullName,
    },
    {
      field: "actions",
      type: "actions",
      width: 60,
      getActions: getTableRowActions,
      hideable: false,
    },
  ]);

  const columns = useColumns(columnDef, actionDispatcher);
  const apiRef = useGridApiRef();

  return (
    <ActionDispatcherProvider actionDispatcher={actionDispatcher}>
      <Paper sx={{ p: 2 }}>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <SummaryIcon
                sx={{
                  fill: theme.palette.primary.main,
                  fontSize: "28px",
                }}
              />
              <Typography variant="h5" fontWeight="bold">
                Summaries
              </Typography>
            </Stack>
            <SummaryListActions actionDispatcher={actionDispatcher} onRefetch={refetch} />
          </Stack>
          <Box sx={{ height: "700px", width: "100%" }}>
            <StripedDataGrid
              apiRef={apiRef}
              density="compact"
              loading={getSummaryDetailsBySiteRequest.loading}
              columns={columns}
              components={{
                Toolbar: GridToolbarFilterButton,
              }}
              rows={summaries}
            />
          </Box>
        </Stack>
      </Paper>
    </ActionDispatcherProvider>
  );
};
