import { useBaseAxiosGetRequest, useBaseAxiosGetRequestWithAutoRefresh, useBaseAxiosPostRequest } from "app";
import {
  OfflineDataConfirmViewModel,
  OfflineDataImportViewModel,
  OfflineImportRowResultModel,
} from "@bluemarvel/iris-common/model";

export const offlineAssetsMutationUriMatchPatterns = [/api\/offlineImport\/.*/gi];

export const useGetOfflineDataImportRequest = (siteId: number) =>
  useBaseAxiosGetRequest<OfflineDataImportViewModel[]>(`/api/offline/importData/${siteId}`, { useNode: true });

export const useGetOfflineAssetRequest = (importId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<OfflineImportRowResultModel>(
    `/api/offlineImport/${importId}/assets`,
    offlineAssetsMutationUriMatchPatterns,
    { useNode: true }
  );

export const useSyncOfflineCreateAssetRequest = (importId: number) =>
  useBaseAxiosPostRequest(`/api/offlineImport/${importId}/create`, {
    useNode: true,
  });
export const useSyncOfflineMergeAssetRequest = (importId: number) =>
  useBaseAxiosPostRequest(`/api/offlineImport/${importId}/merge`, {
    useNode: true,
  });

export const useGetOfflineDataConfirmRequest = (importId: number) =>
  useBaseAxiosGetRequest<OfflineDataConfirmViewModel[]>(`/api/offline/${importId}/offlineAssets/confirm`, {
    useNode: true,
  });
