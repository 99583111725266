import { Box, Link, Paper, Typography } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useActionDispatcher, ActionDispatcherProvider } from "app/mui/ActionDispatcher";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { createStaticColumns, useColumns } from "app/mui/tables/utils/createStaticColumns";
import moment from "moment";
import { formatRoutePath, Routes, useSiteIdFromRoute } from "app/routes";
import { useGetOfflineDataImportRequest } from "./api";
import { Link as RouterLink } from "react-router-dom";
import { OfflineDataImportStatusEnum, OfflineDataImportViewModel } from "@bluemarvel/iris-common/model";

export const ImportOfflinePage = () => {
  const actionDispatcher = useActionDispatcher();

  const siteId = useSiteIdFromRoute()!;
  const getOfflineDataImportRequest = useGetOfflineDataImportRequest(siteId);
  const offlineDataImports = getOfflineDataImportRequest.data || [];

  const columnDef = createStaticColumns(() => [
    {
      field: "date",
      headerName: "Date",
      valueGetter: (params) => params.row.id,
      flex: 2,
      renderCell: (params) => {
        return params.row.status === OfflineDataImportStatusEnum.Values["Sync Completed"] ? (
          <Typography variant="body2">{moment(params.row.created).format("MM/DD/YYYY")}</Typography>
        ) : (
          <Link
            color="secondary"
            component={RouterLink}
            to={formatRoutePath(Routes.ImportOfflineAssets, params.row as OfflineDataImportViewModel)}
            sx={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
            state={{ returnTo: window.location.pathname }}
          >
            {moment(params.row.created).format("MM/DD/YYYY")}
          </Link>
        );
      },
    },
    {
      field: "time",
      headerName: "Time",
      flex: 1,
      type: "string",
      valueGetter: (params) => moment(params.row.created).format("HH:mm:ss"),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      type: "string",
      valueGetter: (params) => params.row.status,
    },
    {
      field: "uploadedBy",
      headerName: "Uploaded by",
      flex: 1,
      type: "string",
      valueGetter: (params) => params.row.createdBy?.fullName,
    },
    {
      field: "assetSynced",
      headerName: "Asset Synced",
      flex: 1,
      type: "string",
      valueGetter: (params) => params.row.assetSynced,
    },
    {
      field: "totalAssets",
      headerName: "Total Assets",
      flex: 1,
      type: "string",
      valueGetter: (params) => params.row.totalAssets,
    },
    {
      field: "photos",
      headerName: "Photos",
      flex: 1,
      type: "string",
      valueGetter: (params) => params.row.photosCount,
    },
  ]);

  const columns = useColumns(columnDef, actionDispatcher);
  const apiRef = useGridApiRef();

  return (
    <ActionDispatcherProvider actionDispatcher={actionDispatcher}>
      <Paper sx={{ p: 2 }}>
        <Box sx={{ height: "700px", width: "100%" }}>
          <StripedDataGrid
            apiRef={apiRef}
            density="compact"
            loading={getOfflineDataImportRequest.loading}
            columns={columns}
            rows={offlineDataImports}
            initialState={{
              sorting: {
                sortModel: [{ field: "date", sort: "desc" }],
              },
            }}
          />
        </Box>
      </Paper>
    </ActionDispatcherProvider>
  );
};
