import { Stack } from "@mui/material";
import { useGetTutorialsRequest } from "help/api";
import { TutorialsListItem } from "./TutorialsListItem";

export const TutorialsList = () => {
  const tutorialsRequest = useGetTutorialsRequest();
  const tutorials = tutorialsRequest.data || [];

  return (
    <Stack spacing={2}>
      {tutorials.map((tutorial, index) => (
        <TutorialsListItem key={tutorial.id} tutorial={tutorial} isFirstItem={index === 0} />
      ))}
    </Stack>
  );
};
