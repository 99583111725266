import { PackagedSolutionDetailViewModel } from "@bluemarvel/iris-common/model";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import { TabPanel } from "app/mui/tabs/TabPanel";
import { AttachmentsArea } from "attachments/mui/AttachmentsArea";
import { useState } from "react";
import { PackagedSolutionRecordTab } from "./components/PackagedSolutionRecordTab";
import PackagedSolutionHistory from "./components/PackagedSolutionHistory";

interface PackagedSolutionTabSectionsProps {
  packagedSolutionDetail: PackagedSolutionDetailViewModel;
  userCanEdit: boolean;
}

export const PackagedSolutionTabSections = (props: PackagedSolutionTabSectionsProps) => {
  const { packagedSolutionDetail, userCanEdit } = props;
  const packagedSolution = packagedSolutionDetail.model;
  const [tabIndex, setTabIndex] = useState(0);

  const handleChangeTab = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2, px: 2 }}>
        <Tabs value={tabIndex} onChange={handleChangeTab}>
          <Tab label="Records" />
          <Tab label="Attachments" />
          <Tab label="History" />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0} sx={{ p: 2 }}>
        <PackagedSolutionRecordTab packagedSolutionId={packagedSolution.id} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1} sx={{ p: 2 }}>
        <AttachmentsArea
          parentType="PackagedSolution"
          parentId={packagedSolution.id}
          parentTag={packagedSolution.name}
          readOnly={!userCanEdit}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2} sx={{ p: 2 }}>
        <PackagedSolutionHistory packagedSolutionId={packagedSolution.id} />
      </TabPanel>
    </Paper>
  );
};
