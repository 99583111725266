import { UserModel } from "@bluemarvel/iris-common/model";
import { Box, Typography } from "@mui/material";
import { dateFormat } from "common";
import moment from "moment";

interface Props {
  lastModifiedBy?: UserModel;
  lastModified: Date;
}

export const PostedBy = (props: Props) => {
  const { lastModifiedBy, lastModified } = props;
  return (
    <Typography variant="caption">
      Posted
      {lastModifiedBy ? ` by ${lastModifiedBy.fullName} • ` : " on "}
      <Box
        sx={{
          whiteSpace: "nowrap",
          display: "inline",
        }}
      >
        {moment(lastModified).format(dateFormat)}
      </Box>
    </Typography>
  );
};
