import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { Record } from "records/models";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { useCallback, useEffect, useState } from "react";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";
import { Paper, Box } from "@mui/material";
import { AddCalibrationDialog } from "../dialogs/AddCalibrationDialog";
import { useGetEquipmentHealthCalibrationsRequest } from "records/api";
import { Calibration } from "./Calibration";

const sectionActions = createStaticActions((actionDispatcher) => [
  {
    icon: <AddBoxOutlinedIcon />,
    label: "Add Calibration",
    action: () => {
      actionDispatcher.dispatch(DialogAction.AddCalibration);
    },
  },
]);

interface EquipmentHealthCalibrationProps {
  record: Record;
}

export const EquipmentHealthCalibration = (props: EquipmentHealthCalibrationProps) => {
  const { record } = props;
  const { data: calibrations } = useGetEquipmentHealthCalibrationsRequest(record.id);
  const [addCalibrationDialog, setAddCalibrationDialog] = useState(false);
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(sectionActions, actionDispatcher);

  const handleAction = useCallback((action: string) => {
    if (action === DialogAction.AddCalibration) {
      setAddCalibrationDialog(true);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  return (
    <Paper>
      <ExpansionPanel title="CALIBRATION" rightSideComponent={<ActionsDropdownMenu actions={actions} />}>
        <Box sx={{ p: 2 }}>
          {calibrations?.map((spec) => (
            <Calibration key={spec.assetId} spec={spec} assets={record.assets} />
          ))}
        </Box>
      </ExpansionPanel>
      {addCalibrationDialog && (
        <AddCalibrationDialog record={props.record} onClose={() => setAddCalibrationDialog(false)} />
      )}
    </Paper>
  );
};
