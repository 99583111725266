import AutoSave from "app/mui/forms/AutoSave";
import { Box, Button, Grid, Paper, Stack, Typography } from "@mui/material";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { useForm } from "react-hook-form";
import { useUserContext } from "app";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { useUpdateTutorialsRequest } from "../../api";
import { RichTextEditor } from "app/mui/forms/RichTextEditor";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { AttachmentModel, TutorialsDetailViewModel, TutorialTypeValues } from "@bluemarvel/iris-common/model";
import { AttachmentUploading } from "attachments/mui/AttachmentUploading";
import { AttachmentsGrid } from "attachments/mui/AttachmentsGrid";
import { PaperClipIcon } from "icons/PaperClipIcon";
import { useNotifications } from "app";

interface TutorialsDetailProps {
  tutorialsDetail: TutorialsDetailViewModel;
  tutorialAttachments: AttachmentModel[];
}

const tutorialTypeOptions = TutorialTypeValues.map((id) => ({
  id,
  label: id,
}));

export const TutorialsDetail = (props: TutorialsDetailProps) => {
  const { tutorialsDetail, tutorialAttachments } = props;
  const { userPermissions } = useUserContext();
  const notifications = useNotifications();

  const userCanEdit = userPermissions.userCanEditReleaseNotes && !tutorialsDetail.model.isPublished;

  const updateTutorials = useUpdateTutorialsRequest();
  const defaultValues = makeSafeForReactHookForm({ ...tutorialsDetail.model });
  const methods = useForm({ defaultValues });

  const saveChanges = () => {
    return methods.handleSubmit((values) => {
      updateTutorials.call(values);
    })();
  };

  const handleDeleteAttachment = async () => {
    try {
      notifications.success("Attachment deleted successfully");
    } catch (error) {
      notifications.error("Failed to delete attachment");
    }
  };

  return (
    <PermissionAwareFormProvider {...methods} require={() => userCanEdit}>
      <AutoSave defaultValues={defaultValues} onSubmit={saveChanges} />
      <Paper>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={3}>
            <Grid item md={12} lg={3}>
              <DateInputFormItem label="Created" fieldName="created" readOnly readOnlyValue={tutorialsDetail.created} />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <AutocompleteFormItem
                label="Tutorial Type"
                fieldName="tutorialType"
                options={tutorialTypeOptions}
                autoDefault={false}
                autocompleteProps={{ disabled: !userCanEdit }}
              />
            </Grid>

            <Grid item xs={12}>
              {!userCanEdit && (
                <Typography variant="caption" color="textSecondary">
                  Description
                </Typography>
              )}
              <Box display="flex" alignItems="center">
                <RichTextEditor fieldName="description" label="Description" disabled={!userCanEdit} />
              </Box>
            </Grid>
            <Grid item md={6}>
              <TextFieldFormItem fieldName="videoURL" label="Video URL" readOnly={!userCanEdit} />
            </Grid>
            <Grid item xs={12}>
              <AttachmentUploading parentType={"Tutorial"} parentId={tutorialsDetail.id} disabled={true}>
                {({ openUploadDialog }) => (
                  <Stack spacing={3}>
                    {userCanEdit && (
                      <Button onClick={openUploadDialog} variant="outlined" sx={{ width: "49%" }}>
                        <PaperClipIcon />
                        Upload Attachment
                      </Button>
                    )}
                    <AttachmentsGrid
                      isSelectMode={false}
                      onMultiSelectToggle={() => {}}
                      selectedAttachmentIds={new Set<number>()}
                      parentType={"Tutorial"}
                      parentId={tutorialsDetail.id}
                      attachments={tutorialAttachments}
                      onDeleteAttachment={handleDeleteAttachment}
                      canCopyAssemblyImage={false}
                      readOnly={false}
                    />
                  </Stack>
                )}
              </AttachmentUploading>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </PermissionAwareFormProvider>
  );
};
