import { BadgeProps } from "app/mui/Badge";
import { Stack } from "@mui/material";

const color_border = "#E91E63";
const color_background = "#FBD2E0";
const color_text = "#B50541";

interface IReleaseNotesVersionBadgeProps extends Omit<BadgeProps, "text"> {
  versionNumber: string;
}

export const ReleaseNotesVersionBadge = (badgeProps: IReleaseNotesVersionBadgeProps) => {
  const { versionNumber } = badgeProps;

  if (!versionNumber) {
    return null;
  }
  return (
    <>
      <Stack direction="row">
        <Stack
          spacing={0.5}
          direction="column"
          sx={{
            border: `1px solid ${color_border}`,
            padding: "0.1rem 0.5rem",
            fontSize: ".8rem",
            borderRadius: "5px",
            background: color_background,
            color: color_text,
            alignItems: "left",
          }}
        >
          Version {versionNumber}
        </Stack>
      </Stack>
    </>
  );
};
