import { useAppSettingsContext } from "app/contexts/AppSettingsContext";
import { AppSettings } from "common";
import _ from "lodash";

type ReportType =
  | "application"
  | "asset"
  | "bulk-valve-health"
  | "general-summary"
  | "kpi-summary"
  | "outage-summary"
  | "record";

export interface ReportParamsBase {
  id: number;
  pdf: boolean;
  appendPdfAttachments: boolean;
}

export const generateReportUrl = (report: ReportType, params: ReportParamsBase, appSettings: AppSettings) => {
  const validatedParams: ReportParamsBase = { ...params, pdf: params.pdf && !appSettings?.disablePdfGeneration };

  const urlParams = _.toPairs(validatedParams)
    .filter((p) => p[1] !== null)
    .map((p) => `${p[0]}=${typeof p[1] === "string" ? p[1] : p[1].toString()}`)
    .join("&");
  return `${appSettings?.irisReportsUrl || ""}/reports/${report}?${urlParams}`;
};

const useBaseReportUrlBuilder = (report: ReportType, params: ReportParamsBase) => {
  const { appSettings } = useAppSettingsContext();
  if (!appSettings) return null;
  return generateReportUrl(report, params, appSettings!);
};

export interface ApplicationReportParams extends ReportParamsBase {
  includeAssets: boolean;
  includeFailureModes: boolean;
  includeMitigations: boolean;
  includeMitigationImplementations: boolean;
  includePictures: boolean;
}

export const useApplicationReportUrlBuilder = (params: ApplicationReportParams) =>
  useBaseReportUrlBuilder("application", params);

export interface AssetReportParams extends ReportParamsBase {
  includeAssetDetails: boolean;
  includeSpecs: boolean;
  includeRecordHistory: boolean;
  includeRecordDetails: boolean;
  includeRecordRecommendations: boolean;
  includePictures: boolean;
}

export interface FieldInspectionSummaryReportParams extends ReportParamsBase {
  includeBadActors: boolean;
  includeInspectionResults: boolean;
  includeNotes: boolean;
  includePhotos: boolean;
  includeVisualIntegrityLegend: boolean;
  appendPdfAttachments: boolean;
}

export const useAssetReportUrlBuilder = (params: AssetReportParams) => useBaseReportUrlBuilder("asset", params);

export interface BulkValveHealthSummaryReportParams extends ReportParamsBase {
  includeExecutiveSummary: boolean;
  includeHighlightsEtc: boolean;
  includeHealthLegend: boolean;
  includePictures: boolean;
  includeTestResults: boolean;
  overall: boolean;
  condition: boolean;
  performance: boolean;
  minScore: number;
  maxScore: number;
  worstN: number | null;
  onlyWithOpenRecommendations: boolean;
  areaIds: string | null;
  criticalityIds: string | null;
}
export interface BulkValveHealthSummaryNodeReportParams extends ReportParamsBase {
  includeAsFoundAsLeftBreakdown: boolean;
  includeBadActors: boolean;
  includeMovers: boolean;
  includeNotes: boolean;
  includeTestResults: boolean;
  includePhotos: boolean;
  includeValveHealthLegend: boolean;
  appendPdfAttachments: boolean;
  overall: boolean;
  condition: boolean;
  performance: boolean;
  minScore: number;
  maxScore: number;
  worstN: number | null;
  onlyWithOpenRecommendations: boolean;
  areaIds: number[];
  criticalityIds: number[];
}

export interface BulkVibrationHealthSummaryNodeReportParams extends ReportParamsBase {
  includeHealthBreakdown: boolean;
  includeBadActors: boolean;
  includeVibrationDashboard: boolean;
  includeNotes: boolean;
  includeTestResults: boolean;
  includeVibrationHealthLegend: boolean;
  includePhotos: boolean;
}

export interface KpiSummaryNodeReportParams extends ReportParamsBase {
  includeNotes: boolean;
  includeKpiHistory: boolean;
  includeKpiDetails: boolean;
  includePhotos: boolean;
  appendPdfAttachments: boolean;
}

export const useBulkValveHealthSummaryReportUrlBuilder = (params: BulkValveHealthSummaryReportParams) =>
  useBaseReportUrlBuilder("bulk-valve-health", params);

export interface GeneralSummaryReportParams extends ReportParamsBase {
  includeAssetsAndRecords: boolean;
  includeHighlightsEtc: boolean;
  includePictures: boolean;
  includeRecordDetails: boolean;
  includeRecordRecommendations: boolean;
}

export const useGeneralSummaryReportUrlBuilder = (params: GeneralSummaryReportParams) =>
  useBaseReportUrlBuilder("general-summary", params);

export interface KpiSummaryReportParams extends ReportParamsBase {
  includeKpiDetails: boolean;
  includeHighlightsEtc: boolean;
  includePictures: boolean;
}

export const useKpiSummaryReportUrlBuilder = (params: KpiSummaryReportParams) =>
  useBaseReportUrlBuilder("kpi-summary", params);

export interface OutageSummaryReportParams extends ReportParamsBase {
  includeOverallPhaseProgress: boolean;
  includePhaseProgressByArea: boolean;
  includeAssetsByVendor: boolean;
  includeScopeOptimizationSummary: boolean;
  includeScopeOptimizationByType: boolean;
  includeExecutionMilestoneSummary: boolean;
  includeLongestMilestoneDelays: boolean;
  includeEvaluationSummary: boolean;
  includeRecordDetails: boolean;
  includeAssetPictures: boolean;
}

export interface OutageSummaryReportParamsWithFilters extends OutageSummaryReportParams {
  areaIds: string | null;
  vendor: string | null;
  phase: string | null;
  milestone: string | null;
  scopeOfWork: string | null;
  scopeOutcome: string | null;
}

export const useOutageSummaryReportUrlBuilder = (
  params: OutageSummaryReportParams | OutageSummaryReportParamsWithFilters
) => useBaseReportUrlBuilder("outage-summary", params);

export interface RecordReportParams extends ReportParamsBase {
  includeHistory: boolean;
  includePictures: boolean;
  includeAssemblyPicture: boolean;
  includeKpis: boolean;
  includeCompletedRecommendations: boolean;
}

export interface RecordReportV2Params extends ReportParamsBase {
  includeAssetSpecifications: boolean;
  includeAssetTags: boolean;
  includeRecordDetails: boolean;
  includeKpis: boolean;
  includeHistory: boolean;
  includePhotos: boolean;
  includeLegend: boolean;
  includeCalibrationResults: boolean;
  appendPdfAttachments: boolean;
}

export const useRecordReportUrlBuilder = (params: RecordReportParams) => useBaseReportUrlBuilder("record", params);
