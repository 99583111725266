import { Box, Grid, Paper, Typography } from "@mui/material";
import { TutorialsDetailViewModel } from "@bluemarvel/iris-common/model";

interface TutorialsReadOnlyViewProps {
  tutorialsDetail: TutorialsDetailViewModel;
}

export const TutorialsReadOnlyView = ({ tutorialsDetail }: TutorialsReadOnlyViewProps) => {
  const {
    model: { videoURL, description },
  } = tutorialsDetail;

  const getEmbeddedVideoUrl = (url: string) =>
    url.replace(/(?:https?:\/\/)?(?:www\.)?vimeo\.com\/(\d+)/, "https://player.vimeo.com/video/$1");

  const embeddedVideoUrl = videoURL ? getEmbeddedVideoUrl(videoURL) : null;

  return (
    <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
      <Grid container spacing={3}>
        {description && (
          <Grid item xs={12}>
            <Typography dangerouslySetInnerHTML={{ __html: description }} />
          </Grid>
        )}
        {embeddedVideoUrl && (
          <Grid item xs={12}>
            <Box
              sx={{
                position: "relative",
                paddingBottom: "56.25%",
              }}
            >
              <iframe
                src={embeddedVideoUrl}
                allow="autoplay; fullscreen"
                allowFullScreen
                title="Tutorial Video"
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
