import {
   Box,
   Paper,
   Stack,
   Typography,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { useNavigate } from "react-router-dom";
import { useSiteContext, useMatchesRoutes, Routes } from "app";
import { ImportCsvPage } from "importCsv/ImportCsvPage";
import { ImportOfflinePage } from "importOffline/ImportOfflinePage";
import { StyledToggleButtonGroup, StyledToggleButton } from "app/mui/forms/Tabs/TabSelect";
import { OfflineAssetList } from "importOffline/mui/OfflineAssetList";

type ImportDataType = "ImportCsv" | "ImportOffline";


export const ImportDataPage = () => {

   const navigate = useNavigate();
   const { currentSiteWithNavigation } = useSiteContext();

   let isImportOffline = false;
   let child = <ImportCsvPage />;

   if (useMatchesRoutes(Routes.ImportOffline)) {
      isImportOffline = true;
      child = <ImportOfflinePage />;
   }
   if (useMatchesRoutes(Routes.ImportOfflineAssets)) {
      isImportOffline = true;
      child = <OfflineAssetList />;
   }

   const importDataType: ImportDataType = isImportOffline ? 'ImportOffline' : 'ImportCsv';

   const handleToggleChanged = (_event: any, value: ImportDataType) => {
      if (!currentSiteWithNavigation) {
         return;
      }
      switch (value) {
         case "ImportCsv":
            navigate(currentSiteWithNavigation.routeTo.ImportCsv());
            return;
         case "ImportOffline":
            navigate(currentSiteWithNavigation.routeTo.ImportOffline());
            return;
      };
   }

   return (
      <Box sx={{ width: "100%" }}>
         <Stack spacing={2}>
            <Paper sx={{ width: "100%", minWidth: 680, p: 2 }}>
               <Stack spacing={2}>
                  <Stack
                     direction="row"
                     alignItems="center"
                     spacing={1}
                     sx={{
                        svg: {
                           fill: (theme) => theme.palette.primary.main,
                           width: "28px",
                           height: "28px",
                        },
                     }}
                  >
                     <UploadFileIcon />
                     <Typography variant="h5" fontWeight="bold" fontFamily="Montserrat">
                        Import Data
                     </Typography>
                  </Stack>
                  <StyledToggleButtonGroup value={importDataType} exclusive onChange={handleToggleChanged} size="small">
                     <StyledToggleButton value={"ImportCsv"}>Import CSV</StyledToggleButton>
                     <StyledToggleButton value={"ImportOffline"}>Import Offline data</StyledToggleButton>
                  </StyledToggleButtonGroup>
               </Stack>
            </Paper>
            <Box>{child}</Box>

         </Stack >
      </Box >
   );
};
