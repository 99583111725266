import { useEffect, useState, useMemo } from "react";
import {
  AccessoryDataSchema,
  ActuatorDataSchema,
  ElementDataSchema,
  GeneralDataSchema,
  MeasurementDataSchema,
  OfflineAssetDetailSchema,
  OfflineAssetStatus,
  OfflineAssetStatusEnum,
  OfflineImportRowModel,
  PilotDataSchema,
  RegulatorDataSchema,
  SteamTrapDataSchema,
  TankDataSchema,
  ValveDataSchema,
} from "@bluemarvel/iris-common/model";
import Add from "@mui/icons-material/Add";
import {
  Box,
  Paper,
  Button,
  Stack,
  Typography,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tabs,
  Link,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { GridColDef, GridFooterContainer } from "@mui/x-data-grid-pro";
import { formatRoutePath, Routes, useSiteContext } from "app";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import AddIcon from "@mui/icons-material/Add";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useMatch, useNavigate } from "react-router-dom";
import { get, startCase } from "lodash";
import { IrisColors } from "app/mui/theme";
import {
  useGetOfflineAssetRequest,
  useSyncOfflineCreateAssetRequest,
  useSyncOfflineMergeAssetRequest,
} from "importOffline/api";
import { routeForIdColumnType } from "app/mui/tables/metadata/rendering/models";
import { Link as RouterLink } from "react-router-dom";
import { OfflineAssetTab } from "./OfflineAssetTab";

export const OfflineAssetList = () => {
  const routeMatch = useMatch(Routes.ImportOfflineAssets);
  const importId = Number(routeMatch?.params?.id);
  const site = useSiteContext().currentSite!;
  const importOfflinePage = formatRoutePath(Routes.ImportOffline, { siteId: site.id });
  const navigate = useNavigate();

  const [reivewed, setReviewed] = useState(false);
  const [syncTime, setSyncTime] = useState<string | null>(null);
  const [tabValue, setTabValue] = useState<OfflineAssetStatus>(OfflineAssetStatusEnum.Values.Create);
  const [rows, setRows] = useState<OfflineImportRowModel[]>([]);
  const [columns, setColumns] = useState<GridColDef[]>([]);

  const handleChangeTab = (_: unknown, newValue: OfflineAssetStatus) => {
    setTabValue(newValue);
    setReviewed(false);
  };

  const handleReviewedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReviewed(event.target.checked);
  };

  const getOfflineAssetRequest = useGetOfflineAssetRequest(importId);
  const syncOfflineCreateAssetRequest = useSyncOfflineCreateAssetRequest(importId);
  const syncOfflineMergeAssetRequest = useSyncOfflineMergeAssetRequest(importId);
  const { data: offlineAssets, loading } = getOfflineAssetRequest;

  useEffect(() => {
    const rowData: OfflineImportRowModel[] = [];
    if (!offlineAssets) {
      return;
    }
    switch (tabValue) {
      case "Merge":
        rowData.push(...(offlineAssets.merge ?? []));
        setSyncTime(offlineAssets.mergeSyncTime);
        break;
      default:
        rowData.push(...(offlineAssets.create ?? []));
        setSyncTime(offlineAssets.createSyncTime);
    }
    const deltaKeys = new Set(rowData.flatMap((r) => Object.keys(r.deltas)));
    setColumns(dataGridHeader(deltaKeys));
    setRows(rowData);
  }, [offlineAssets, tabValue]);

  const syncDataOnClick = () => {
    switch (tabValue) {
      case "Create":
        syncOfflineCreateAssetRequest.call({});
        break;
      case "Merge":
        syncOfflineMergeAssetRequest.call({});
        break;
    }
  };

  const renderFooter = () => (
    <GridFooterContainer>
      <Stack width="100%" direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
        {!syncTime ? (
          <>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={reivewed} onChange={handleReviewedChange} />}
                label="I have reviewed all assets"
              />
            </FormGroup>
            <Button variant="contained" color="secondary" disabled={!reivewed} onClick={syncDataOnClick}>
              Sync Data
            </Button>
          </>
        ) : (
          <Typography variant="subtitle2">Synced {new Date(syncTime).toISOString().slice(0, 10)}</Typography>
        )}
        <Button onClick={() => navigate(importOfflinePage)}>Cancel</Button>
      </Stack>
    </GridFooterContainer>
  );

  const syncedAssetCount = useMemo(() => {
    return (
      (offlineAssets?.createSyncTime ? offlineAssets.create.length : 0) +
      (offlineAssets?.mergeSyncTime ? offlineAssets.merge.length : 0)
    );
  }, [offlineAssets]);

  const totalAssetCount = useMemo(() => {
    return !offlineAssets ? 0 : offlineAssets.create.length + offlineAssets.merge.length;
  }, [offlineAssets]);

  return (
    <Box sx={{ width: "100%" }}>
      {!loading && (
        <Paper sx={{ p: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ m: "10px" }}>
            <Tabs value={tabValue} onChange={handleChangeTab}>
              <OfflineAssetTab
                label="Create"
                badgeCount={offlineAssets?.create.length ?? 0}
                value={OfflineAssetStatusEnum.Values.Create}
                synced={!!offlineAssets?.createSyncTime}
              />
              <OfflineAssetTab
                label="Merge"
                badgeCount={offlineAssets?.merge.length ?? 0}
                value={OfflineAssetStatusEnum.Values.Merge}
                synced={!!offlineAssets?.mergeSyncTime}
              />
            </Tabs>
            <Chip icon={<Add />} label={`${syncedAssetCount}/${totalAssetCount} assets Synced`} />
          </Stack>
          <Box sx={{ width: "100%", height: "700px" }}>
            <StripedDataGrid
              density="compact"
              components={{
                Footer: renderFooter,
              }}
              rows={rows}
              columns={columns}
              getRowHeight={() => "auto"}
            />
          </Box>
        </Paper>
      )}
    </Box>
  );
};

const dataGridHeader = (deltas: Set<string>) => {
  const shapes = [
    ...OfflineAssetDetailSchema.keyof().options,
    ...AccessoryDataSchema.keyof().options.map((opt) => `accessory.${opt}`),
    ...ActuatorDataSchema.keyof().options.map((opt) => `actuator.${opt}`),
    ...ValveDataSchema.keyof().options.map((opt) => `valve.${opt}`),
    ...MeasurementDataSchema.keyof().options.map((opt) => `measurement.${opt}`),
    ...ElementDataSchema.keyof().options.map((opt) => `element.${opt}`),
    ...RegulatorDataSchema.keyof().options.map((opt) => `regulator.${opt}`),
    ...PilotDataSchema.keyof().options.map((opt) => `pilot.${opt}`),
    ...SteamTrapDataSchema.keyof().options.map((opt) => `steamTrap.${opt}`),
    ...TankDataSchema.keyof().options.map((opt) => `tank.${opt}`),
    ...GeneralDataSchema.keyof().options.map((opt) => `general.${opt}`),
  ];
  const headers = shapes.filter((s) => deltas.has(s));

  return [
    { field: "row", headerName: "Row" },
    {
      field: "offlineAssetTag",
      headerName: "Offline Asset Tags",
      minWidth: 175,
    },
    {
      field: "importAction",
      headerName: "Action",
      type: "singleSelect",
      width: 150,
      renderCell: (params) => {
        const importAction = params.value;
        return (
          <List dense={true}>
            <ListItem>
              <ListItemIcon sx={{ minWidth: "24px" }}>
                {importAction === "Create" && <AddIcon fontSize="small" />}
                {importAction === "Merge" && <WarningAmberIcon fontSize="small" />}
              </ListItemIcon>
              <ListItemText primary={importAction} />
            </ListItem>
          </List>
        );
      },
    },
    {
      field: "onlineAssetTag",
      headerName: "Online Asset Tags",
      minWidth: 175,
      renderCell: (params) => {
        const linkColumn = params.value;
        if (!linkColumn) {
          return null;
        }
        const route = routeForIdColumnType(linkColumn.type);
        return linkColumn.id ? (
          <Link
            color="secondary"
            component={RouterLink}
            to={formatRoutePath(route, linkColumn)}
            sx={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
            state={{ returnTo: window.location.pathname }}
          >
            {linkColumn.name}
          </Link>
        ) : (
          <span>{linkColumn.name}</span>
        );
      },
    },
    ...headers.map((key) => {
      const fieldName = startCase(key);
      return {
        field: `deltas['${key}']`,
        headerName: fieldName,
        minWidth: 150,
        valueGetter: (params) => get(params.row, `deltas['${key}']`),
        renderCell: (params) => {
          if (!params.value) return <span>&nbsp;</span>;
          const deltas = params.value;
          if (!!deltas.old) {
            if (!!deltas.new) {
              return (
                <Typography variant="body2">
                  <Typography component="span" sx={{ textDecoration: "line-through", color: IrisColors.red }}>
                    {deltas.old}
                  </Typography>
                  <br />
                  <Typography component="span" sx={{ color: IrisColors.green }}>
                    {deltas.new}
                  </Typography>
                </Typography>
              );
            } else {
              return (
                <Typography variant="body2" sx={{ textDecoration: "line-through", color: IrisColors.red }}>
                  {deltas.old}
                </Typography>
              );
            }
          } else {
            return (
              <Typography variant="body2" sx={{ color: IrisColors.green }}>
                {deltas.new}
              </Typography>
            );
          }
        },
      };
    }),
  ];
};
