import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { Record } from "records/models";
import { zodResolver } from "@hookform/resolvers/zod";
import { useCreateEquipmentHealthCalibrationRequest } from "records/api";
import {
  AddEquipmentHealthCalibrationModel,
  AddEquipmentHealthCalibrationSchema,
  CalibrationTypeValues,
} from "@bluemarvel/iris-common/model";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";

interface AddCalibrationDialogProps extends BaseDialogProps {
  record: Record;
}

export const AddCalibrationDialog = (props: AddCalibrationDialogProps) => {
  const createEquipmentHealthCalibrationRequest = useCreateEquipmentHealthCalibrationRequest();
  const methods = useForm<AddEquipmentHealthCalibrationModel>({
    defaultValues: {
      recordId: props.record.id,
    },
    resolver: zodResolver(AddEquipmentHealthCalibrationSchema),
  });

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      createEquipmentHealthCalibrationRequest.call(values).then(() => {
        handleClose();
      });
    })();
  };

  const handleClose = () => {
    methods.reset();
    props.onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Add Calibration
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "220px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <AutocompleteFormItem
              fieldName="assetId"
              label="Asset"
              options={props.record.assets}
              autoDefault={false}
              getOptionLabel={(o) => o.tag}
              autocompleteProps={{
                isOptionEqualToValue: (option, value) => option.id === value.id,
              }}
            />
            <AutocompleteFormItem
              autocompleteProps={{ disablePortal: false }}
              fieldName="calibrationType"
              label="Calibration Template"
              autoDefault={false}
              options={CalibrationTypeValues.map((type) => ({ id: type, label: type }))}
            />
            <TextFieldFormItem
              fieldName="errorThreshold"
              label="Allowable Error Threshold"
              textFieldProps={{ placeholder: "Percentage" }}
            />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={onSubmit} variant="contained" color="secondary">
            Add
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
