import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { Box, Button, Stack } from "@mui/material";
import { useNotifications } from "app";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { DeleteButton } from "app/mui/common/buttons/DeleteButton";
import {
  AttachmentParent,
  useDeleteMultipleAttachmentsRequest,
  useExportAttachmentsRequest,
  useGetAttachmentsRequest,
} from "attachments/api";
import { pluralize } from "common";
import ConfirmationDialog from "common/mui/ConfirmationDialog";
import _ from "lodash";
import { useState } from "react";
import { useCanEditAttachment } from "users";
import { AttachmentsGrid } from "./AttachmentsGrid";
import { AttachmentUploading } from "./AttachmentUploading";
import { NoDataToDisplay } from "app/mui/common/placeholders/NoDataToDisplay";

interface AttachmentsAreaProps {
  parentType: AttachmentParent;
  parentId: number;
  parentTag: string;
  canCopyAssemblyImage?: boolean;
  readOnly?: boolean;
}

const makeLoadingPlaceholderArray = (count: number) =>
  Array.from(Array(count).keys()).map((_, index) => ({
    id: Number(`${index}${Date.now()}`),
    isLoading: true,
  }));

const loadingPlaceholderArray = makeLoadingPlaceholderArray(10);

export const AttachmentsArea = (props: AttachmentsAreaProps) => {
  const attachmentsRequest = useGetAttachmentsRequest(props.parentType, props.parentId);
  const deleteMultipleAttachmentsRequest = useDeleteMultipleAttachmentsRequest(props.parentType, props.parentId);
  const canEdit = useCanEditAttachment(props.parentType);
  const notifications = useNotifications();

  /*
   * adding / deleting attachments is slow, so we are modifying our attachemnts list here
   * to preemptively add or hide items or placeholder items so it appears more responsive.
   */
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [selectedAttachmentIds, setSelectedAttachmentIds] = useState(new Set<number>());
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // replace entire set to ensure state refresh
  const toggleAttachmentSelect = (id: number) => {
    const idSet = new Set(selectedAttachmentIds);

    if (idSet.has(id)) {
      idSet.delete(id);
    } else {
      idSet.add(id);
    }
    setSelectedAttachmentIds(idSet);
  };

  const [deletedAttachments, setDeletedAttachments] = useState<number[]>([]);
  const onDeleteAttachment = (id: number) => {
    setDeletedAttachments((current) => [...current, id]);
  };

  const onDeleteMultipleAttachments = async () => {
    setIsDeleting(true);
    try {
      await deleteMultipleAttachmentsRequest.call(Array.from(selectedAttachmentIds));
      notifications.success(`${selectedAttachmentIds.size} attachments deleted`);
      setDeletedAttachments((current) => [...current, ...selectedAttachmentIds]);
      setSelectedAttachmentIds(new Set());
      setShowDeleteConfirmation(false);
      setIsSelectMode(false);
    } catch {
      notifications.error("Error while deleting files");
    } finally {
      setIsDeleting(false);
    }
  };

  const attachments = _.sortBy(attachmentsRequest.data || [], (a) => a.created).filter(
    (a) => !deletedAttachments.includes(a.id)
  );

  const clearSelection = () => {
    setIsSelectMode(false);
    setSelectedAttachmentIds(new Set());
  };

  const showLoadingIndicator = attachmentsRequest.loading && attachments.length === 0;

  const exportAttachmentsRequest = useExportAttachmentsRequest(props.parentType, props.parentId, props.parentTag);
  const exportAttachments = () => {
    exportAttachmentsRequest.call(attachments.map((a) => a.id));
  };

  const isMultiSelectionActive = isSelectMode && selectedAttachmentIds.size > 0;

  return (
    <Box sx={{ width: "100%" }}>
      <AttachmentUploading parentType={props.parentType} parentId={props.parentId} disabled={props.readOnly}>
        {({ openUploadDialog, currentlyUploadingCount }) => {
          const newAttachmentPlaceholders = currentlyUploadingCount
            ? makeLoadingPlaceholderArray(currentlyUploadingCount)
            : [];

          return (
            <Stack spacing={2} height="100%">
              <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                {isSelectMode && (
                  <>
                    <Button onClick={clearSelection}>Cancel</Button>
                    <DeleteButton
                      onClick={() => setShowDeleteConfirmation(true)}
                      disabled={!isMultiSelectionActive}
                      label={
                        isMultiSelectionActive ? `(${selectedAttachmentIds.size}) Delete Selected` : `Select Files`
                      }
                    />
                    <ConfirmationDialog
                      title="Delete Attachments?"
                      open={showDeleteConfirmation}
                      cancel={() => setShowDeleteConfirmation(false)}
                      loading={isDeleting}
                      confirmButtonText="Delete"
                      confirm={onDeleteMultipleAttachments}
                    >
                      <p>
                        {`Are you sure you want to delete ${selectedAttachmentIds.size} ${pluralize(
                          selectedAttachmentIds.size,
                          "attachment"
                        )}?`}
                      </p>
                    </ConfirmationDialog>
                  </>
                )}
                <ActionsDropdownMenu
                  disabled={isSelectMode || props.readOnly}
                  actions={[
                    {
                      icon: <UploadFileIcon />,
                      label: "Add attachment",
                      action: () => openUploadDialog(),
                      hidden: !canEdit,
                    },
                    {
                      icon: <SystemUpdateAltIcon />,
                      label: "Export",
                      action: () => exportAttachments(),
                    },
                    {
                      icon: <DeleteRoundedIcon />,
                      label: "Delete Multiple Files",
                      action: () => setIsSelectMode(true),
                    },
                  ]}
                />
              </Stack>
              {attachments.length === 0 && !showLoadingIndicator ? (
                <NoDataToDisplay />
              ) : (
                <AttachmentsGrid
                  isSelectMode={isSelectMode}
                  onMultiSelectToggle={toggleAttachmentSelect}
                  selectedAttachmentIds={selectedAttachmentIds}
                  parentType={props.parentType}
                  parentId={props.parentId}
                  attachments={
                    showLoadingIndicator ? loadingPlaceholderArray : [...attachments, ...newAttachmentPlaceholders]
                  }
                  onDeleteAttachment={onDeleteAttachment}
                  canCopyAssemblyImage={props.canCopyAssemblyImage ?? false}
                  readOnly={props.readOnly}
                />
              )}
            </Stack>
          );
        }}
      </AttachmentUploading>
    </Box>
  );
};
