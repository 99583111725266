import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import ConfirmationDialog from "common/mui/ConfirmationDialog";
import { EditIcon } from "icons/EditIcon";
import { Typography } from "@mui/material";
import { useCallback } from "react";
import { Routes, useNotifications } from "app";
import { useNavigate } from "react-router-dom";
import { UseFormReturn } from "react-hook-form";
import { useDeleteReleaseNotesRequest } from "../../api";
import { useUserContext } from "app/contexts/UserContext";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { EditFieldDialog } from "app/mui/common/dialogs/EditFieldDialog";
import { ReleaseNotesDetailViewModel, ReleaseNotesModel } from "@bluemarvel/iris-common/model";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";

interface IReleaseNotesActionsProps {
  releaseNotesDetail: ReleaseNotesDetailViewModel;
  methods: UseFormReturn<ReleaseNotesModel, any>;
  updateTitle: (onValid?: () => void) => void;
  togglePublish: (closeDialog: () => void) => void;
}

const pageActions = (releaseNotesDetail: ReleaseNotesModel) =>
  createStaticActions((actionDispatcher, userPermissions) => [
    {
      icon: <EditNoteIcon />,
      label: "Toggle Publish",
      action: () => actionDispatcher.dispatch(DialogAction.TogglePublishReleaseNotes),
      hidden: !userPermissions.userCanEditReleaseNotes,
    },
    {
      icon: <EditIcon />,
      label: "Rename",
      action: () => actionDispatcher.dispatch(DialogAction.RenameReleaseNotes),
      hidden: !userPermissions.userCanEditReleaseNotes,
    },
    {
      icon: <DeleteIcon />,
      label: "Delete",
      action: () => actionDispatcher.dispatch(DialogAction.DeleteReleaseNotes),
      hidden: !userPermissions.userCanEditReleaseNotes,
    },
  ]);

export const ReleaseNotesHeaderActions = (props: IReleaseNotesActionsProps) => {
  const { userPermissions } = useUserContext();
  const { methods } = props;
  const releaseNotesModel = props.releaseNotesDetail.model;
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(releaseNotesModel), actionDispatcher);
  const showActions = userPermissions.userCanEditReleaseNotes;
  const deleteReleaseNote = useDeleteReleaseNotesRequest();
  const notifications = useNotifications();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState<DialogAction | null>(null);

  React.useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const handleAction = useCallback((action: string) => {
    switch (action) {
      case DialogAction.DeleteReleaseNotes:
        setOpenDialog(DialogAction.DeleteReleaseNotes);
        break;
      case DialogAction.TogglePublishReleaseNotes:
        setOpenDialog(DialogAction.TogglePublishReleaseNotes);
        break;
      case DialogAction.RenameReleaseNotes:
        setOpenDialog(DialogAction.RenameReleaseNotes);
        break;
    }
  }, []);

  const togglePublish = () => {
    props.togglePublish(closeDialog);
  };

  const updateTitle = () => {
    props.updateTitle(closeDialog);
  };

  const handleDeleteReleaseNote = async () => {
    try {
      await deleteReleaseNote.call(releaseNotesModel.id);
      navigate(Routes.Help);
      closeDialog();
    } catch {
      notifications.error("Error while deleting release note");
    }
  };

  return (
    <>
      {showActions && <ActionsDropdownMenu primary actions={actions} />}

      {openDialog === DialogAction.DeleteReleaseNotes && (
        <ConfirmationDialog
          title="Delete release note?"
          open={openDialog === DialogAction.DeleteReleaseNotes}
          cancel={() => closeDialog()}
          confirmButtonText="Delete"
          confirm={handleDeleteReleaseNote}
        >
          <Typography variant="body2">Are you sure you want to delete this release note?</Typography>
        </ConfirmationDialog>
      )}

      {openDialog === DialogAction.TogglePublishReleaseNotes && (
        <ConfirmationDialog
          title={releaseNotesModel.isPublished ? "Set to Draft?" : "Publish?"}
          open={openDialog === DialogAction.TogglePublishReleaseNotes}
          cancel={() => closeDialog()}
          confirmButtonText={releaseNotesModel.isPublished ? "Set to Draft" : "Publish"}
          confirm={togglePublish}
        >
          <Typography variant="body2">
            Are you sure you want to
            {releaseNotesModel.isPublished
              ? " revert this release note back to draft mode"
              : " publish this release note"}
            ?
          </Typography>
        </ConfirmationDialog>
      )}

      {openDialog === DialogAction.RenameReleaseNotes && (
        <EditFieldDialog
          methods={methods}
          saveChanges={updateTitle}
          fieldName="title"
          dialogTitle="Change Release Note Title"
          label="Release Note Title"
          confirmButtonText="Change Title"
          onClose={closeDialog}
        />
      )}
    </>
  );
};
