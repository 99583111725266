import { useState, useCallback, useEffect } from "react";
import { Summary } from "summaries";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { PackagedSolutionReportModel } from "@bluemarvel/iris-common/model";
import { useForm, FormProvider } from "react-hook-form";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { CheckboxFormItem } from "app/mui/forms/CheckboxFormItem";
import { useAxiosGenerateReport } from "app";

interface Props extends BaseDialogProps {
  summary: Summary;
}

export const ConfigurePackagedSolutionSummaryReportModal: React.FunctionComponent<Props> = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<PackagedSolutionReportModel>({
    defaultValues: {
      id: props.summary.id,
      includeOperatingSystemSpecifications: true,
      includePackagedSolutionDetails: true,
      includeOperatingSystemRecommendations: true,
      includeEquipmentRecommendations: true,
      includeNotes: true,
      includePhotos: true,
      appendPdfAttachments: true,
    },
  });

  const includePackagedSolutionDetails = methods.watch("includePackagedSolutionDetails");

  const generateRecordReportNode = useAxiosGenerateReport<PackagedSolutionReportModel>(
    "api/reports/summary/packagedSolution",
    {
      onStartCallback: () => setIsLoading(true),
      onSuccessCallback: () => {
        setIsLoading(false);
        props.onClose();
      },
      onFailCallback: () => setIsLoading(false),
    }
  );

  const handleSubmit = useCallback(() => {
    generateRecordReportNode.call(methods.getValues());
  }, []);

  useEffect(() => {
    methods.setValue("includeOperatingSystemRecommendations", includePackagedSolutionDetails);
    methods.setValue("includeEquipmentRecommendations", includePackagedSolutionDetails);
  }, [includePackagedSolutionDetails]);

  return (
    <BaseDialog onClose={props.onClose} maxWidth="md">
      <DialogTitle variant="h6" sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis", overflow: "hidden" }}>
        Report options
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <CheckboxFormItem
              fieldName="includeOperatingSystemSpecifications"
              label="Operating system specifications"
            />
            <CheckboxFormItem fieldName="includePackagedSolutionDetails" label="Details" />
            <Stack sx={{ paddingLeft: 6 }}>
              <CheckboxFormItem
                fieldName="includeOperatingSystemRecommendations"
                label="Operating System recommendations"
                disabled={!includePackagedSolutionDetails}
              />
              <CheckboxFormItem
                fieldName="includeEquipmentRecommendations"
                label="Equipment Recommendations"
                disabled={!includePackagedSolutionDetails}
              />
            </Stack>
            <CheckboxFormItem fieldName="includeNotes" label="Notes" />
            <CheckboxFormItem fieldName="includePhotos" label="Photos" />
            <CheckboxFormItem fieldName="appendPdfAttachments" label="Append PDF attachments" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <LoadingButton loading={isLoading} variant="contained" color="secondary" onClick={handleSubmit}>
            Generate
          </LoadingButton>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
