import { Grid, Stack } from "@mui/material";
import { Routes, useUserContext } from "app";
import { useGetPackagedSolutionByIdRequest, useGetPackagedSolutionEquipmentsRequest } from "packagedSolution/api";
import { useMatch } from "react-router-dom";
import { PackagedSolutionDetails } from "./PackagedSolutionDetails";
import { EntityDetailsContainerSkeleton } from "app/mui/common/skeletons/EntityDetailsContainerSkeleton";
import { PackagedSolutionReportCard } from "./sections/PackagedSolutionReportCard";
import { PackagedSolutionTabSections } from "./PackagedSolutionTabSections";
import { OperatingSystems } from "./sections/OperatingSystems";
import { PackagedSolutionHeader } from "./PackagedSolutionDetailsHeader";
import { PackagedSolutionEquipments } from "./sections/PackagedSolutionEquipment";
import { PackagedSolutionOverallHealth } from "./sections/PackagedSolutionOverallHealth";
import { useGetRecordsByIdQuery } from "records/api";

export const PackagedSolutionPage = () => {
  const routeMatch = useMatch(Routes.PackagedSolution);

  const packagedSolutionId = Number(routeMatch?.params?.id);
  const { data: packagedSolution } = useGetPackagedSolutionByIdRequest(packagedSolutionId);
  const { data: equipments } = useGetPackagedSolutionEquipmentsRequest(packagedSolutionId);

  const latestRecords = equipments?.flatMap((e) => (e.latestRecord ? [e.latestRecord.id] : []));
  const { data: equipmentHealth, loading } = useGetRecordsByIdQuery(latestRecords ?? []);

  const { userPermissions } = useUserContext();
  const userCanEdit =
    userPermissions.userCanEditPackagedSolution && packagedSolution?.model.packagedSolutionStatus === "In progress";

  return (
    <Grid container spacing={2}>
      {loading || !packagedSolution ? (
        <EntityDetailsContainerSkeleton />
      ) : (
        <>
          <Grid item xs={12} lg={8} xl={9}>
            <Stack spacing={2}>
              <PackagedSolutionHeader packagedSolutionDetail={packagedSolution} equipments={equipments ?? []} />
              <PackagedSolutionDetails packagedSolutionDetail={packagedSolution} userCanEdit={userCanEdit} />
              <OperatingSystems packagedSolutionId={packagedSolution.id} userCanEdit={userCanEdit} />
              <PackagedSolutionEquipments packagedSolutionId={packagedSolution.id} userCanEdit={userCanEdit} />
              <PackagedSolutionTabSections packagedSolutionDetail={packagedSolution} userCanEdit={userCanEdit} />
            </Stack>
          </Grid>
          <Grid item xs={12} lg={4} xl={3}>
            <Stack spacing={2} direction={{ xs: "column", md: "row", lg: "column" }}>
              {packagedSolution?.reportCardPackagedSolution && (
                <>
                  <PackagedSolutionOverallHealth
                    reportCard={packagedSolution.reportCardPackagedSolution}
                    equipmentHealth={equipmentHealth ?? []}
                  />
                  <PackagedSolutionReportCard
                    packagedSolutionId={packagedSolution.id}
                    reportCard={packagedSolution.reportCardPackagedSolution}
                    equipments={equipments ?? []}
                    equipmentHealth={equipmentHealth ?? []}
                    userCanEdit={userCanEdit}
                  />
                </>
              )}
            </Stack>
          </Grid>
        </>
      )}
    </Grid>
  );
};
