import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Grid, IconButton, Stack } from "@mui/material";
import { Routes, useUserContext } from "app";
import { useMatch, useNavigate } from "react-router-dom";
import { useGetReleaseNotesDetailRequest } from "../api";
import { ReleaseNotesDetail } from "./sections/ReleaseNotesDetail";
import { ReleaseNotesHeader } from "./sections/ReleaseNotesHeader";
import { ReleaseNotesAttachments } from "./sections/ReleaseNotesAttachments";
import { ReleaseNotesEnhancement } from "./sections/ReleaseNotesEnhancement";
import { useGetAttachmentsRequest } from "attachments/api";
import { AttachmentModel, ReleaseNotesDetailViewModel } from "@bluemarvel/iris-common/model";
import { EntityDetailsContainerSkeleton } from "app/mui/common/skeletons/EntityDetailsContainerSkeleton";

export const ReleaseNotesDetailsPage = () => {
  const routeMatch = useMatch(Routes.ReleaseNote);
  const releaseNoteId = Number(routeMatch?.params?.id);
  const { data: releaseNotesDetail, loading: loadingReleaseNotes } = useGetReleaseNotesDetailRequest(releaseNoteId);

  const { data: bugAttachments } = useGetAttachmentsRequest("ReleaseNoteBug", releaseNoteId);

  const { data: featureAttachments } = useGetAttachmentsRequest("ReleaseNoteFeature", releaseNoteId);

  if (loadingReleaseNotes && !releaseNotesDetail) {
    return <EntityDetailsContainerSkeleton />;
  } else if (!releaseNotesDetail) {
    return null;
  }

  return (
    <ReleaseNotesDetailsPageWithForm
      releaseNotesDetail={releaseNotesDetail}
      featureAttachments={featureAttachments || []}
      bugAttachments={bugAttachments || []}
    />
  );
};

interface ReleaseNotesPageWithFormProps {
  releaseNotesDetail: ReleaseNotesDetailViewModel;
  bugAttachments: AttachmentModel[];
  featureAttachments: AttachmentModel[];
}

export const ReleaseNotesDetailsPageWithForm = (props: ReleaseNotesPageWithFormProps) => {
  const { releaseNotesDetail, bugAttachments, featureAttachments } = props;
  const { userPermissions } = useUserContext();
  const navigate = useNavigate();
  const userCanEdit = userPermissions.userCanEditReleaseNotes && !releaseNotesDetail.isPublished;

  const handleNavigation = () => {
    navigate(Routes.Help);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={1} lg={1} xl={1} alignContent={"right"}>
        <div style={{ width: "100%", textAlign: "right", paddingTop: "0px", position: "relative", right: "-10px" }}>
          <IconButton size="small" onClick={() => handleNavigation()} color="info">
            <ArrowBackIosNewIcon></ArrowBackIosNewIcon>&nbsp;Help
          </IconButton>
        </div>
      </Grid>

      <Grid item xs={7} lg={7} xl={7}>
        <Stack direction="column" spacing={2}>
          <ReleaseNotesHeader releaseNotesDetail={releaseNotesDetail} />

          <ReleaseNotesDetail releaseNotesDetail={releaseNotesDetail} />

          <ReleaseNotesEnhancement
            releaseNotesDetail={releaseNotesDetail}
            enhancementData={releaseNotesDetail.features}
            attachments={featureAttachments}
            userCanEdit={userCanEdit}
            enhancementType="ReleaseNoteFeature"
          />
          <ReleaseNotesEnhancement
            releaseNotesDetail={releaseNotesDetail}
            enhancementData={releaseNotesDetail.bugs}
            attachments={bugAttachments}
            userCanEdit={userCanEdit}
            enhancementType="ReleaseNoteBug"
          />
        </Stack>
      </Grid>
      <Grid item xs={3} lg={3} xl={3}>
        <ReleaseNotesAttachments
          releaseNotesDetail={releaseNotesDetail}
          featureAttachments={featureAttachments}
          bugAttachments={bugAttachments}
        />
      </Grid>
    </Grid>
  );
};
