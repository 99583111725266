import { Box, Grid, Paper } from "@mui/material";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { useGetOperatingSystemsRequest } from "packagedSolution/api";
import { PanelCard } from "../components/PanelCard";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { useCallback, useEffect, useState } from "react";
import { AddPanelDialog } from "../dialogs/AddPanelDialog";
import { OperatingSystemRecommendations } from "./OperatingSystemRecommendations";

interface OperatingSystemsProps {
  packagedSolutionId: number;
  userCanEdit: boolean;
}

const sectionActions = createStaticActions((actionDispatcher) => [
  {
    icon: <AddBoxOutlinedIcon />,
    label: "Add Panel",
    action: () => {
      actionDispatcher.dispatch("AddPanel");
    },
  },
]);

export const OperatingSystems = (props: OperatingSystemsProps) => {
  const { packagedSolutionId, userCanEdit } = props;
  const operatingSystemsRequest = useGetOperatingSystemsRequest(packagedSolutionId);
  const operatingSystems = operatingSystemsRequest.data || [];
  const [addPanelDialog, setAddPanelDialog] = useState(false);

  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(sectionActions, actionDispatcher);

  const handleAction = useCallback((action: string) => {
    if (action === "AddPanel") {
      setAddPanelDialog(true);
    }
  }, []);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  return (
    <Paper>
      <ExpansionPanel
        title="Operating System"
        rightSideComponent={userCanEdit && <ActionsDropdownMenu actions={actions} />}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {operatingSystems.map((os, idx) => (
              <PanelCard key={os.id} model={os} index={idx} userCanEdit={userCanEdit} />
            ))}
          </Grid>
          <OperatingSystemRecommendations packagedSolutionId={packagedSolutionId} userCanEdit={userCanEdit} />
        </Box>
      </ExpansionPanel>
      {addPanelDialog && (
        <AddPanelDialog packagedSolutionId={packagedSolutionId} onClose={() => setAddPanelDialog(false)} />
      )}
    </Paper>
  );
};
