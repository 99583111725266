import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmationDialog from "common/mui/ConfirmationDialog";
import { EditIcon } from "icons/EditIcon";
import { Typography } from "@mui/material";
import { useCallback } from "react";
import { Routes, useNotifications } from "app";
import { useNavigate } from "react-router-dom";
import { useDeleteTutorialsRequest, useUpdateTutorialsRequest } from "../../api";
import { useUserContext } from "app/contexts/UserContext";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { EditFieldDialog } from "app/mui/common/dialogs/EditFieldDialog";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";
import PublishIcon from "@mui/icons-material/Publish";
import { useForm } from "react-hook-form";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { TutorialsDetailViewModel } from "@bluemarvel/iris-common/model";

interface TutorialsActionsProps {
  tutorialsDetail: TutorialsDetailViewModel;
}

export const TutorialsHeaderActions = (props: TutorialsActionsProps) => {
  const { userPermissions } = useUserContext();
  const { tutorialsDetail } = props;
  const updateTutorial = useUpdateTutorialsRequest();
  const tutorialsModel = tutorialsDetail.model;
  const actionDispatcher = useActionDispatcher();
  const deleteTutorial = useDeleteTutorialsRequest();
  const notifications = useNotifications();
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = React.useState<DialogAction | null>(null);

  const defaultValues = makeSafeForReactHookForm(tutorialsModel);
  const methods = useForm({ defaultValues });

  React.useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const handleAction = useCallback((action: string) => {
    switch (action) {
      case DialogAction.DeleteTutorials:
        setOpenDialog(DialogAction.DeleteTutorials);
        break;
      case DialogAction.TogglePublishTutorials:
        setOpenDialog(DialogAction.TogglePublishTutorials);
        break;
      case DialogAction.RenameTutorials:
        setOpenDialog(DialogAction.RenameTutorials);
        break;
    }
  }, []);

  const updateName = (onValid?: () => void) => {
    methods.handleSubmit((values) => {
      updateTutorial.call({ ...tutorialsModel, name: values.name }).then(() => onValid?.());
    })();
  };

  const togglePublish = () => {
    updateTutorial.call({ ...tutorialsModel, isPublished: !tutorialsModel.isPublished }).finally(() => closeDialog());
  };

  const handleDeleteTutorial = async () => {
    try {
      await deleteTutorial.call(tutorialsModel.id);
      navigate(Routes.Help);
      closeDialog();
    } catch {
      notifications.error("Error while deleting tutorials");
    }
  };

  const actions = useStaticActions(
    createStaticActions((actionDispatcher, userPermissions) => [
      {
        icon: <PublishIcon />,
        label: "Toggle Publish",
        action: () => actionDispatcher.dispatch(DialogAction.TogglePublishTutorials),
        hidden: !userPermissions.userCanEditReleaseNotes,
      },
      {
        icon: <EditIcon />,
        label: "Rename",
        action: () => actionDispatcher.dispatch(DialogAction.RenameTutorials),
        hidden: !userPermissions.userCanEditReleaseNotes,
      },
      {
        icon: <DeleteIcon />,
        label: "Delete Tutorial",
        action: () => actionDispatcher.dispatch(DialogAction.DeleteTutorials),
        hidden: !userPermissions.userCanEditReleaseNotes,
      },
    ]),
    actionDispatcher
  );

  return (
    <>
      {userPermissions.userCanEditReleaseNotes && <ActionsDropdownMenu primary actions={actions} />}

      {openDialog === DialogAction.DeleteTutorials && (
        <ConfirmationDialog
          title="Delete tutorial?"
          open={openDialog === DialogAction.DeleteTutorials}
          cancel={() => closeDialog()}
          confirmButtonText="Delete"
          confirm={handleDeleteTutorial}
        >
          <Typography variant="body2">Are you sure you want to delete this tutorial?</Typography>
        </ConfirmationDialog>
      )}

      {openDialog === DialogAction.TogglePublishTutorials && (
        <ConfirmationDialog
          title={tutorialsModel.isPublished ? "Set to Draft" : "Publish Tutorial"}
          open={openDialog === DialogAction.TogglePublishTutorials}
          cancel={() => closeDialog()}
          confirmButtonText={tutorialsModel.isPublished ? "Set to Draft" : "Publish"}
          confirm={togglePublish}
        >
          <Typography variant="body2">
            Are you sure you want to
            {tutorialsModel.isPublished ? " revert this tutorial back to draft mode" : " publish this tutorial note"}?
          </Typography>
        </ConfirmationDialog>
      )}

      {openDialog === DialogAction.RenameTutorials && (
        <EditFieldDialog
          methods={methods}
          saveChanges={updateName}
          fieldName="name"
          dialogTitle="Change Tutorial Name"
          label="Tutorial Name"
          confirmButtonText="Change Name"
          onClose={closeDialog}
        />
      )}
    </>
  );
};
