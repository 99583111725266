import AddIcon from "@mui/icons-material/Add";
import ErrorIcon from "@mui/icons-material/Error";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SyncIcon from "@mui/icons-material/Sync";
import { Chip, Link, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import {
  GridCellParams,
  GridColDef,
  GridFilterItem,
  gridStringOrNumberComparator,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { formatRoutePath, Routes } from "app";
import { AssetTypeBadge } from "app/mui/AssetTypeBadge";
import { RecordStatusBadge } from "app/mui/RecordStatusBadge";
import { RecordTypeBadge } from "app/mui/RecordTypeBadge";
import { IrisColors } from "app/mui/theme";
import { HealthBadge } from "assets/mui/health/HealthBadge";
import { VisualIntegrityBadge } from "assets/mui/health/VisualIntegrityBadge";
import { dateFormat, LinkColumn, MetaFieldDefinition } from "common";
import { EventIcon } from "icons/EventIcon";
import { getImportModeSortableOrder, ImportCsvRowResult, ImportFieldValueDelta, ImportMode } from "importCsv/models";
import { flatMapKpiValueMonetaryTotals, KpiValueTotal, KpiValueTotals, KpiValueTotalSavingsType } from "kpis";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { RecordOutageEvaluationConsideration } from "records/bs/outage/models";
import { Site } from "sites";
import MultiFilterInputSelect from "../../filtering/MultiFilterInputSelect";
import { withSelectOptions } from "../../filtering/MultiSelectHoc";
import { KpiValueTotalCellValue } from "./KpiValueTotalCellValue";
import { routeForIdColumnType } from "./models";
import { DiagnosticTypeBadge } from "app/mui/DiagnosticTypeBadge";
import { OfflineImportAction } from "@bluemarvel/iris-common/model";

/**
 * Allows us to override a sort comparator for a column when the 'value' is an object
 * and we want to sort on a specific property of that object.
 */
const sortUsingSelector = (selector: (value: any) => any) => (v1: any, v2: any, param1: any, param2: any) =>
  gridStringOrNumberComparator(selector(v1), selector(v2), param1, param2);

type CustomCells = {
  [key: string]: (columnDef: MetaFieldDefinition) => Partial<GridColDef>;
};

type AssetWithCriticality = {
  criticality?: string | null;
};

export const defaultValueFormatter =
  (metaFieldDefinition: MetaFieldDefinition) => (params: GridValueFormatterParams<any>) => {
    if (!!metaFieldDefinition.muiGridColDef!.valueOptions) {
      return valueOptionsValueFormatter(metaFieldDefinition)(params);
    }
    if (metaFieldDefinition.muiGridColDef?.type === "date") {
      return dateValueFormatter(params);
    }

    if (params.value) {
      var value = new String(params.value).trim();
      if (value.startsWith("-")) {
        value = value.substring(1, value.length).trim();
      }

      return `${value}`;
    }
  };

export const valueOptionsValueFormatter =
  (metaFieldDefinition: MetaFieldDefinition) => (params: GridValueFormatterParams<any>) =>
    metaFieldDefinition.muiGridColDef!.valueOptions?.find((option) => option.value === params.value)?.label ??
    params.value ??
    "";

export const dateValueFormatter = ({ value }: GridValueFormatterParams<any>) => {
  if (value instanceof Date) {
    return moment(value).format(dateFormat);
  }
  return value?.toString();
};

export const percentageValueFormatter = ({ value }: GridValueFormatterParams<any>) => {
  return value ? `${Math.round(value)}%` : "";
};

const formatCriticalities = (assets: AssetWithCriticality[]): string => {
  return (
    assets
      .map((asset) => asset.criticality || "")
      .filter((c) => typeof c === "string" && c.trim() !== "")
      .join(", ") || " "
  );
};

const extractNumericCriticality = (value: string): number => {
  if (!value || typeof value !== "string" || value.trim() === "") return 99999;

  const criticalities = value
    .split(",")
    .map((c) => c.trim().match(/\d+/)?.[0])
    .filter((c): c is string => !!c)
    .map(Number);

  return criticalities.length > 0 ? Math.min(...criticalities) : 99999;
};

const getAssets = (params: GridCellParams): AssetWithCriticality[] => _.get(params.row, "record.model.assets") || [];

export const getCustomDataTypeCells = (sites: Site[]): CustomCells & Object => ({
  "custom.linkColumn": (columnDef) => ({
    type: "string",
    minWidth: 175,
    renderCell: (params) => {
      const linkColumn = _.get(params.row, columnDef.field) as LinkColumn;
      if (!linkColumn) {
        return null;
      }

      const route = routeForIdColumnType(linkColumn.type);
      if (!linkColumn.id) return <span>{linkColumn.name}</span>;
      return (
        <Link
          color="secondary"
          component={RouterLink}
          to={formatRoutePath(route, linkColumn)}
          sx={{ fontWeight: "600", overflow: "hidden", textOverflow: "ellipsis" }}
          state={{ returnTo: window.location.pathname }}
        >
          {params.value}
        </Link>
      );
    },
    valueGetter: (params) => {
      const linkColumn = _.get(params.row, columnDef.field);
      if (!linkColumn) {
        return "";
      }

      return linkColumn.name;
    },
  }),
  "custom.assetType": () => ({
    type: "singleSelect",
    renderCell: (params) => {
      return <AssetTypeBadge assetType={params.value} />;
    },
  }),
  "custom.criticality": (columnDef) => ({
    type: "singleSelect",
    valueFormatter: valueOptionsValueFormatter(columnDef),
    sortComparator: sortUsingSelector((criticalityId: number | null) => {
      return criticalityId === null
        ? 99999
        : sites[0].customer.criticalities.find((c) => c.id === criticalityId)?.calculationValue;
    }),
  }),
  "custom.assetCriticality": (columnDef) => ({
    type: "singleSelect",
    valueFormatter: valueOptionsValueFormatter(columnDef),
    renderCell: (params) => {
      const assets = getAssets(params);
      return formatCriticalities(assets);
    },
    valueGetter: (params) => {
      const assets = getAssets(params);
      return formatCriticalities(assets);
    },
    sortComparator: (v1, v2) => extractNumericCriticality(v1) - extractNumericCriticality(v2),
  }),

  "custom.machineryComponents": (columnDef) => ({
    type: "string",
    renderCell: (params) => {
      const assets = _.get(params.row, "record.model.assets", []);
      if (assets.length === 0) return " ";

      const machineryComponents = assets.flatMap((asset) => asset.machineryComponents || []).filter(Boolean);

      return machineryComponents.length > 0 ? machineryComponents.join(", ") : " ";
    },
    valueGetter: (params) => {
      const assets = _.get(params.row, "record.model.assets", []);
      if (assets.length === 0) return " ";

      const machineryComponents = assets.flatMap((asset) => asset.machineryComponents || []).filter(Boolean);

      return machineryComponents.length > 0 ? machineryComponents.join(", ") : " ";
    },
  }),
  "custom.kpiValueTotal": (columnDef) => ({
    type: "string",
    renderCell: (params) => {
      const kpiValueTotal = _.get(params.row, columnDef.field) as KpiValueTotal;
      if (!kpiValueTotal) return null;
      return <KpiValueTotalCellValue kpiValueTotal={kpiValueTotal} />;
    },
    valueGetter: (params) => _.get(params.row, columnDef.field)?.numericValue,
  }),
  "custom.kpiValueTotals": (columnDef) => ({
    type: "string",
    renderCell: (params) => {
      const kpiValueTotals = _.get(params.row, columnDef.field) as KpiValueTotal[];
      if (!kpiValueTotals || kpiValueTotals.length === 0) return null;
      return (
        <Stack spacing={0}>
          {kpiValueTotals.map((kpiValueTotal, i) => (
            <KpiValueTotalCellValue kpiValueTotal={kpiValueTotal} key={i} />
          ))}
        </Stack>
      );
    },
    valueGetter: (params) =>
      _.sum(((_.get(params.row, columnDef.field) as KpiValueTotal[] | null) ?? []).map((t) => t.numericValue)),
  }),
  "custom.kpiValueTotalSavingsType": (columnDef) => ({
    type: "singleSelect",
    renderCell: (params) => {
      const savingsFieldName = columnDef.field as string;
      const modelFieldName = savingsFieldName.substring(0, savingsFieldName.lastIndexOf("."));
      const kpiValueTotals = _.get(params.row, modelFieldName) as KpiValueTotals;
      if (!kpiValueTotals) return null;

      const kpi = _.first(flatMapKpiValueMonetaryTotals(kpiValueTotals));
      if (!kpi) return null;

      return <KpiValueTotalCellValue kpiValueTotal={kpi} />;
    },
    filterable: false,
    valueGetter: (params) => {
      const savingsTypes = _.get(params.row, columnDef.field) as KpiValueTotalSavingsType[];
      return savingsTypes.join();
    },
  }),
  "custom.recordOutageEvaluationConsiderationModels": () => ({
    type: "string",
    renderCell: (params) => {
      const evaluationConsiderations = params.value as RecordOutageEvaluationConsideration[];
      if (!evaluationConsiderations || evaluationConsiderations.length === 0) return null;
      return (
        <List dense>
          {evaluationConsiderations.map((evaluationConsideration, i) => (
            <ListItem key={i}>
              <ListItemText>
                {evaluationConsideration.category}: {evaluationConsideration.details}
              </ListItemText>
            </ListItem>
          ))}
        </List>
      );
    },
    sortComparator: sortUsingSelector((value) => value?.length),
    // TODO:  add a value getter here...
  }),
  "custom.recordType": () => ({
    type: "singleSelect",
    renderCell: (params) => {
      return <RecordTypeBadge type={params.value} />;
    },
  }),
  "custom.diagnosticType": () => ({
    type: "singleSelect",
    renderCell: (params) => {
      return <DiagnosticTypeBadge diagnosticType={params.value} />;
    },
  }),
  "custom.status": () => ({
    type: "singleSelect",
    renderCell: (params) => {
      return <RecordStatusBadge status={params.value} />;
    },
  }),
  "custom.int32s": () => ({
    type: "string",
  }),
  "custom.userModel": (columnDef) => ({
    type: "string",
    valueGetter: (params) => _.get(params.row, columnDef.field)?.fullName,
  }),
  "custom.occurrenceDate": () => ({
    type: "date",
    renderCell: (params) => {
      if (params.row.recordsEvent) {
        return (
          <Link
            component={RouterLink}
            to={formatRoutePath(Routes.RecordsEvent, {
              siteId: params.row.siteId,
              id: params.row.recordsEvent?.id,
            })}
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              {params.formattedValue}
            </Typography>
            <EventIcon sx={{ pl: 0.5 }} />
          </Link>
        );
      } else {
        return (
          <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
            {params.formattedValue}
          </Typography>
        );
      }
    },
    valueFormatter: dateValueFormatter,
  }),
  "custom.visualIntegrity": (columnDef) => ({
    type: "singleSelect",
    renderCell: (params) => {
      if (params.value === null) {
        return "";
      }

      return <VisualIntegrityBadge score={params.value} />;
    },
  }),
  "custom.healthCategory": () => ({
    type: "singleSelect",
    renderCell: (params) => {
      return <HealthBadge health={params.value} />;
    },
  }),
  "custom.assetDigestModels": (columnDef) => ({
    type: "string",
    renderCell: (params) => {
      const assets = _.get(params.row, columnDef.field);
      return (
        <>
          {assets.map((asset, index) => {
            return (
              <React.Fragment key={asset.id}>
                <Link
                  color="secondary"
                  component={RouterLink}
                  to={formatRoutePath(Routes.Asset, { siteId: asset.siteId, id: asset.id })}
                  sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  <Typography variant="body2" sx={{ overflow: "hidden", textOverflow: "ellipsis" }} component="span">
                    {asset.tag}
                  </Typography>
                </Link>
                {index < assets.length - 1 ? ", " : ""}
              </React.Fragment>
            );
          })}
        </>
      );
    },
    sortComparator: sortUsingSelector((value) => value?.length),

    valueGetter: (params) => {
      var assets = _.get(params.row, columnDef.field) || [];
      return assets.map((asset) => asset.tag).join(", ") ?? "";
    },
  }),
  "custom.recordsEventDigestModel": (columnDef) => ({
    type: "string",
    renderCell: (params) => {
      const event = _.get(params.row, columnDef.field);
      return (
        <Link
          color="secondary"
          component={RouterLink}
          to={formatRoutePath(Routes.RecordsEvent, { siteId: event?.siteId, id: event?.id })}
        >
          {event?.name}
        </Link>
      );
    },
    valueGetter: (params) => params.value?.name ?? "",
  }),
  "custom.keywordModels": (columnDef) => ({
    type: "string",
    valueGetter: (params) => {
      var keywords = _.get(params.row, columnDef.field) || [];
      return keywords.map((item: any) => item.value).join(", ");
    },
  }),
  "custom.enums": (columnDef) => ({
    type: "string",
    valueGetter: (params) => {
      var items = _.get(params.row, columnDef.field) || [];
      // build value from list of valueOptions to work with select components.
      return (
        columnDef.muiGridColDef?.valueOptions?.filter((valueOption) =>
          items.some((item) => item === valueOption.value)
        ) || []
      );
    },
    renderCell: (params) => {
      return (
        <>
          {params.value.map((val) => {
            return <Chip size={"small"} label={val.label} variant="filled" color={"primary"} />;
          })}
        </>
      );
    },
    filterOperators: [
      {
        label: "Contains All",
        value: "containsAll",
        getApplyFilterFn: (filterItem: GridFilterItem) => {
          if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
            return null;
          }
          return (params: GridCellParams): boolean => {
            return filterItem.value.every((item) => params.value.some((x) => x === item));
          };
        },
        InputComponent: withSelectOptions(MultiFilterInputSelect, columnDef.muiGridColDef?.valueOptions ?? []),
      },
    ],
  }),
  "custom.strings": (columnDef) => ({
    type: "string",
    valueGetter: (params) => {
      var items = _.get(params.row, columnDef.field) || [];
      return items.join(", ");
    },
  }),
  "custom.html": (columnDef) => ({
    type: "string",
    valueGetter: (params) => {
      const value = _.get(params.row, columnDef.field);
      if (!value) {
        return null;
      }

      const tempElement = document.createElement("div");
      tempElement.innerHTML = value;
      return tempElement.textContent || "";
    },
  }),
  "custom.csvImportMode": () => ({
    type: "singleSelect",
    width: 175,
    renderCell: (params) => {
      const row = params.row as ImportCsvRowResult;
      const importMode = params.value as ImportMode;
      return (
        <List dense={true}>
          {importMode !== "Error" && (
            <ListItem>
              <ListItemIcon sx={{ minWidth: "24px" }}>
                {importMode === "NoChanges" && <InfoOutlinedIcon fontSize="small" />}
                {importMode === "Create" && <AddIcon fontSize="small" />}
                {importMode === "Update" && <SyncIcon fontSize="small" />}
              </ListItemIcon>
              <ListItemText primary={importMode === "NoChanges" ? "No changes" : importMode} />
            </ListItem>
          )}
          {row.errors?.map((error, i) => (
            <ListItem key={i}>
              <ListItemIcon sx={{ minWidth: "24px" }}>
                <ErrorIcon color="error" fontSize="small" />
              </ListItemIcon>
              <ListItemText primary={error} />
            </ListItem>
          ))}
        </List>
      );
    },
    sortComparator: sortUsingSelector(getImportModeSortableOrder),
  }),
  "custom.csvDelta": () => ({
    type: "string",
    minWidth: 150,
    renderCell: (params) => {
      if (!params.value) return <span>&nbsp;</span>;
      const deltas = params.value as ImportFieldValueDelta;
      if (!!deltas.old) {
        if (!!deltas.new) {
          return (
            <Typography variant="body2">
              <Typography component="span" sx={{ textDecoration: "line-through", color: IrisColors.red }}>
                {deltas.old}
              </Typography>
              <br />
              <Typography component="span" sx={{ color: IrisColors.green }}>
                {deltas.new}
              </Typography>
            </Typography>
          );
        } else {
          return (
            <Typography variant="body2" sx={{ textDecoration: "line-through", color: IrisColors.red }}>
              {deltas.old}
            </Typography>
          );
        }
      } else {
        return (
          <Typography variant="body2" sx={{ color: IrisColors.green }}>
            {deltas.new}
          </Typography>
        );
      }
    },
    sortComparator: sortUsingSelector((value) => {
      const deltas = value as ImportFieldValueDelta;
      return deltas?.new;
    }),
  }),
  "custom.offlineAssetTag": () => ({
    type: "string",
    minWidth: 175,
  }),
  "custom.offlineImportAction": () => ({
    type: "singleSelect",
    width: 150,
    renderCell: (params) => {
      const importAction = params.value as OfflineImportAction;
      return (
        <List dense={true}>
          <ListItem>
            <ListItemIcon sx={{ minWidth: "24px" }}>
              {importAction === "Create" && <AddIcon fontSize="small" />}
              {importAction === "Merge" && <AddIcon fontSize="small" />}
            </ListItemIcon>
            <ListItemText primary={importAction} />
          </ListItem>
        </List>
      );
    },
    sortComparator: sortUsingSelector(getImportModeSortableOrder),
  }),
  "custom.percentage": () => ({
    type: "number",
    valueFormatter: percentageValueFormatter,
  }),
});
