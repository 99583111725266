import React from "react";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { useForm } from "react-hook-form";
import { useUserContext } from "app/contexts/UserContext";
import { CreateReleaseNotesEnhancementModel, ReleaseNotesDetailViewModel } from "@bluemarvel/iris-common/model";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useCallback } from "react";
import { useCreateReleaseNotesBugRequest, useCreateReleaseNotesFeatureRequest } from "../../api";
import { EditFieldDialog } from "app/mui/common/dialogs/EditFieldDialog";
import { AttachmentParent } from "attachments/api";
import { AddIcon } from "icons/AddIcon";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";

interface IReleaseNotesEnhancementActionsProps {
  releaseNotesDetail: ReleaseNotesDetailViewModel;
  enhancementType: AttachmentParent;
}

const pageActions = (enhancementType: AttachmentParent) =>
  createStaticActions((actionDispatcher, userPermissions) => [
    {
      icon: <AddIcon />,
      label: "Create Feature",
      action: () => actionDispatcher.dispatch(DialogAction.CreateReleaseNotesFeature),
      hidden: enhancementType !== "ReleaseNoteFeature" || !userPermissions.userCanEditReleaseNotes,
    },
    {
      icon: <AddIcon />,
      label: "Create Bug",
      action: () => actionDispatcher.dispatch(DialogAction.CreateReleaseNotesBug),
      hidden: enhancementType !== "ReleaseNoteBug" || !userPermissions.userCanEditReleaseNotes,
    },
  ]);

export const ReleaseNotesEnhancementActions = (props: IReleaseNotesEnhancementActionsProps) => {
  const { releaseNotesDetail, enhancementType } = props;
  const { userPermissions } = useUserContext();
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(enhancementType), actionDispatcher);
  const showActions = userPermissions.userCanEditReleaseNotes;
  const createReleaseNoteFeature = useCreateReleaseNotesFeatureRequest();
  const createReleaseNoteBug = useCreateReleaseNotesBugRequest();
  const newEnhancement: CreateReleaseNotesEnhancementModel = { releaseNotesId: releaseNotesDetail.id, title: "" };
  const defaultValues = makeSafeForReactHookForm(newEnhancement);
  const methods = useForm({ defaultValues });
  const [openDialog, setOpenDialog] = React.useState<DialogAction | null>(null);

  React.useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, [openDialog]);

  const closeDialog = () => {
    methods.reset();
    setOpenDialog(null);
  };

  const handleAction = useCallback((action: string) => {
    switch (action) {
      case DialogAction.CreateReleaseNotesFeature:
        setOpenDialog(DialogAction.CreateReleaseNotesFeature);
        break;
      case DialogAction.CreateReleaseNotesBug:
        setOpenDialog(DialogAction.CreateReleaseNotesBug);
        break;
    }
  }, []);

  const createFeature = () => {
    methods.handleSubmit((values) => {
      createReleaseNoteFeature.call({ ...newEnhancement, title: values.title }).finally(() => closeDialog());
    })();
  };

  const createBug = () => {
    methods.handleSubmit((values) => {
      createReleaseNoteBug.call({ ...newEnhancement, title: values.title }).finally(() => closeDialog());
    })();
  };

  return (
    <>
      {showActions && <ActionsDropdownMenu actions={actions} />}

      {openDialog === DialogAction.CreateReleaseNotesFeature && (
        <EditFieldDialog
          methods={methods}
          saveChanges={createFeature}
          fieldName="title"
          dialogTitle="Create a Feature Enhancement"
          label="Specify a Title"
          confirmButtonText="Create"
          onClose={closeDialog}
        />
      )}
      {openDialog === DialogAction.CreateReleaseNotesBug && (
        <EditFieldDialog
          methods={methods}
          saveChanges={createBug}
          fieldName="title"
          dialogTitle="Create a Bug Enhancement"
          label="Specify a Title"
          confirmButtonText="Create"
          onClose={closeDialog}
        />
      )}
    </>
  );
};
