import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import { NumberFormItem } from "app/mui/forms/Input/NumberFormItem";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { IrisColors } from "app/mui/theme";
import { useFormContext } from "react-hook-form";

interface TestDataProps {
  testType: "asFoundTest" | "asLeftTest";
  threshold: number;
}

const sampleFields = {
  1: ["pdiOffset", "tmsOffset"],
  2: ["tempOffset", "referenceDevice"],
  3: ["range"],
};

export const TestDataSection = ({ testType, threshold }: TestDataProps) => {
  const { watch } = useFormContext();

  return (
    <Grid item xs={12} md={6}>
      <Paper elevation={2} sx={{ p: 3 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 3 }}>
          <Typography variant="subtitle1" fontWeight="bold">
            {testType === "asFoundTest" ? "As-found Test" : "As-left Test"}
          </Typography>
          <Typography variant="body2" fontWeight="bold">
            Allowable Error Threshold: {threshold}%
          </Typography>
        </Box>

        <Grid container spacing={2}>
          {Object.entries(sampleFields).map(([num, fields]) => {
            const analyzer = watch(`${testType}.analyzerReadingSample${num}`);
            const reference = watch(`${testType}.referenceReadingSample${num}`);
            const error = analyzer && reference ? Number(Math.abs(analyzer - reference).toFixed(3)) : null;

            return (
              <Grid item xs={12} md={4} key={num}>
                <Typography variant="body2" fontWeight="bold">
                  Sample {num}
                </Typography>
                <Stack spacing={2}>
                  <NumberFormItem fieldName={`${testType}.analyzerReadingSample${num}`} label="Analyzer reading" />
                  <NumberFormItem fieldName={`${testType}.referenceReadingSample${num}`} label="Reference reading" />
                  <TextFieldFormItem
                    fieldName={`${testType}.errorSample${num}`}
                    label="Error"
                    backgroundColor={
                      error ? (error > threshold ? IrisColors.lightyellow : IrisColors.lightgreen) : undefined
                    }
                    textFieldProps={{
                      value: error?.toString() ?? "",
                      disabled: true,
                    }}
                  />
                  {fields.map((field) => (
                    <TextFieldFormItem
                      key={field}
                      fieldName={`${testType}.${field}`}
                      label={field.replace(/([A-Z])/g, " $1")}
                    />
                  ))}
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Paper>
    </Grid>
  );
};
